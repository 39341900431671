import React from "react";

export default class PageNotExist extends React.Component {
    render() {
        return (<div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{
                backgroundColor: 'powderblue',
                width: '50%',
                marginTop: 25,
                padding: 50,
                textAlign: 'center',
                fontWeight: 'bold',
                border: '3px solid white',
                borderRadius: 25,
            }}>
                <h1 style={{ fontWeight: 900 }}>Page not exist.</h1>
                <a href='/'>Back to Login</a>
            </div>
        </div>);
    }
}