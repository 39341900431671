const english = {
    'overview': 'Overview',
    'create': 'Create',
    'and': ' and ',
    'co-op-ikey': ' in co-op with iKEY.',
    'optional': 'Optional',

    //===== Common =====//
    'yes': 'Yes',
    'no': 'No',
    'not-specify': 'Not Specify',
    'not-specify-yes-no': '(Select Yes or No)',

    'signout-logout': 'Logout',
    'back': 'Back',
    'close': 'Close',
    'reset': 'Reset',
    'save': 'Save',
    'clear': 'Clear',
    'enter': 'Enter',
    'back-to-home': 'Back to Home',

    'profile': 'Profile',
    'your-email': 'Email',
    'email-address': 'Email Address',
    'your-password': 'Password',
    'password-min-req': 'minimum 6 charactors & above',     //2021.08.25

    'grade': '(Select Grade)',
    'label-grade': 'Grade',
    'subject': 'Subject',
    'not-specify-subject': '(Select Subject)',

    'pre-school': 'Pre-School',
    'standard': 'Standard',
    'form': 'Form',
    'form-123': 'Form',
    'form-45': 'Form',
    'form-6': 'Form',
    'other': 'Other',

    'time': 'Time',
    'time-hour': 'hr',
    'time-min': 'min',
    'time-sec': 'sec',

    'time-limit': 'Time Limit',
    'time-hour-full': 'hour',
    'time-hour-full-s': 'hours',
    'time-min-full': 'minute',
    'time-min-full-s': 'minutes',
    'time-to': 'to',
    'event-period': 'Event Period',

    'correct': 'Correct',
    'wrong': 'Wrong',
    'confirm': 'Confirm',
    'confirm-type-2': 'Got it',

    'label-existing-user': 'Existing User',
    'label-new-user': 'New User',

    //Alert
    'error': 'Error',
    'success': 'Success',
    'failed': 'Failed',
    'invalid-info': 'Invalid Information',
    'invalid-ops': 'Invalid Operation',
    'invalid-ops-text': 'Please upate your Profile information before proceed.',

    'search': 'Search',
    'search-result': 'Search Result',
    'processing': 'Processing...',

    //2023.11.15
    'welcome-title': 'Live Quiz Portal',
    'welcome-note': 'Welcome to Live Quiz Portal',

    'invalid-login-info': 'Invalid Login Information',
    'email-not-empty': 'Please do not leave your <b>Email</b> empty.',
    'password-not-empty': 'Please do not leave your <b>Password</b> empty.',
    'name-not-empty': 'Please do not leave your <b>Name</b> empty.',
    'center-not-empty': 'Please do not leave your <b>Center</b> empty.',
    'grade-not-selected': 'Please do not leave your <b>Grade</b> unselected.',

    //Internet Not Available.
    'label-internet-not-avai': 'Internet Connection not available',
    'text-internet-not-avai': 'There is no Internet connection available.',
    'label-internet-down': 'Internet is Down',
    'text-internet-down': 'Current Internet connection either is lost or unstable. Unable to proceed.',
    'label-internet-recovering': 'Internet is Recovering',
    'text-internet-recovering': 'Trying very hard to reconnect again...',
    'label-internet-reconnected': 'Internet is Healthy',
    'text-internet-reconnected': 'Internet connection has been recovered.',

    //Error - update failed.
    'update-failed': 'Online update is failed',
    'update-failed-msg': 'There is a connection error occurred, recent update is unable to process, please refresh and try again.', //<br />Please be assure that all previously saved records are still well intact.',

    //subject.
    'english': 'English',
    'chinese': 'Chinese',
    'malay': 'Malay',
    'science': 'Science',
    'mathematics': 'Mathematics',

    //quiz
    'quiz-ended': 'Ended',
    'category': 'Category',

    //2021.08.26
    //country
    'label-country-my': '+60 (Malaysia)',
    'label-country-sg': '+65 (Singapore)',
    'label-country-cn': '+86 (China)',
    'label-country-tw': '+886 (Taiwan)',
    'label-country-jp': '+81 (Japan)',

    //2022.08.08
    'label-change-password': 'Change Password',
    'label-password-retype': 'Password (Confirm)',
    'reset-password-warning-text': 'Please make sure your email address is valid<br />and able to receive email before proceed to request for a password reset link.<br />Password can only be reset via this method.<br />If you only want to change password, you may do it in profile section after login.',
    'change-password-warning-invalid-pass': 'Please enter your <b>New Password</b> correctly.',
    'change-password-warning-invalid-pass-confirm': 'Please enter your <b>Password (Confirm)</b> correctly.',
    'change-password-warning-empty-pass': 'Please enter your <b>New Password</b>.',
    'change-password-warning-empty-pass-confirm': 'Please enter your <b>Password (Confirm)</b>.',
    'change-password-warning-not-match': 'Both passwords <b>not match</b> with each other.',
    'change-password-result-success': 'Your <b>New Password</b> has been updated.',

    //2022.11.01
    'label-password-new': 'New Password',
    'change-password-warning-too-short-pass': '<b>New Password</b> is too short, please enter password with <b>at least 6 letters</b>, or above.',
    'change-password-warning-too-short-pass-confirm': '<b>Password (Confirm)</b> is too short, please enter password with <b>at least 6 letters</b>, or above.',

    //2023.12.01
    'label-password-current': 'Current Password',
    'label-password-new-confirm': 'New Password (Confirm)',
    'author-change-password-warning-text': 'Looks like there is a need to update your password for security measures. Please change your password in order to proceed.',
    'change-password-warning-current-and-new-are-same': 'Both <b>Current Password</b> and <b>New Password</b> are the same. Please update to another different password.',

    //2023.11.20
    'label-change-email': 'Change Email',
    'label-email-new': 'New Email',
    'label-email-retype': 'Email (Confirm)',
    // 'reset-email-warning-text': 'Please make sure your email address is valid<br />and able to receive email before proceed to request for a password reset link.<br />Email can only be update via this method.<br />If you only want to change email again, you may do it again in profile section after login.',
    'change-email-warning-invalid-format': 'Please enter your <b>New Email</b> correctly.',
    'change-email-warning-invalid-format-confirm': 'Please enter your <b>Email (Confirm)</b> correctly.',
    'change-email-warning-empty': 'Please enter your <b>New Email</b>.',
    'change-email-warning-empty-confirm': 'Please enter your <b>Email (Confirm)</b>.',
    'change-email-warning-not-match': 'Emails not match, please check again.',
    'change-email-result-success': 'Your <b>New Email</b> has been updated.',

    //2023.11.29
    'label-file-download-success': 'File successfully downloaded!',
    'notice-file-download-success': "Your file has been successfully downloaded. Please check it in your device's download folder.",

    //===== Login Screen =====//
    'event': 'Event',
    'organise-by': 'Organise by',
    'partnered-with': 'Partnered with', //2022.05.13
    'click-here': 'Click<br />Here',
    'click-here-link': 'click here',
    'coming-soon': 'Coming Soon',
    'private-distribution': 'To Be Announced', //2021.07.14
    'details': 'Details',
    'more-info': 'More Info',
    'label-already-have-account-notice': 'Already have an account ? Sign in below.',

    'signup': 'Sign Up',
    'signup-register': 'Sign up / Register',
    'signin-login': 'Sign In / Login',
    'login': 'Login',
    'forgot-password': 'Forgot?',
    'keep-email-password': 'Remember email & password.',
    'logging-in': 'Logging in',
    'please-wait': 'Please wait for awhile.',
    'shared-login-notice': 'Shared same login with iKey Kidz App.',

    //2021.04.14
    // 'label-login-error': 'Error signing in with password and email !',
    'notice-login-wrong-password': 'Opps! You entered the wrong password.<br /><br />Please try again or click "Forgot?" to receive the reset password link in the registered email.',
    'notice-login-user-not-found': 'This email is not registered yet.<br /><br />Please register an account to log in.',


    //===== SignUp Screen =====//
    'your-name': "Participant's Name",
    'your-center': 'Education Center',
    'your-grade': 'Current Grade',
    'your-classroom': 'Classroom',
    'full-name': 'Full Name',
    'center-name': 'Center Name',
    'classroom-name': 'Classroom Name',
    'user-creation-in-progress': 'User account creation in progress',
    'wait-patiently': 'Please wait patiently, and stay in this page until the process is finish.',
    'invalid-signup': 'Invalid SignUp',
    'unable-to-create-user': 'Unable to create user.',
    'creating-user-profile': 'Creating user profile',
    'user-profile-created': 'User profile has been created',
    'redirect-to-login': 'Redirecting to Login page...',

    'skip-this-if-registered-in-app': 'Skip this page if you have signed up with an iKEY Kidz app before or already have an account on this website.',
    'fill-up-required-fields': 'All fields marked with an asterisk (*) are required.',

    'email-confirm-not-empty': 'Please do not leave your <b>Email (Confirm)</b> empty.',
    'password-confirm-not-empty': 'Please do not leave your <b>Password (Confirm)</b> empty.',
    'email-bad-format': 'Please enter your <b>Email</b> with the correct format.<br />(E.g. user@company.com)',
    'email-confirm-bad-format': 'Please enter your <b>Email (Confirm)</b> with the correct format.<br />(E.g. user@company.com)',
    'email-not-matched': 'Both <b>Email</b> not match, Please check the emails again.',
    'password-not-matched': 'Both <b>Password</b> not match, Please check the passwords again.',

    'contact-number': 'Contact Number',
    'contact-number-sample': '(E.g. +60167552525)',
    'contact-number-not-empty': 'Please do not leave your <b>Contact Number</b> empty.',
    'contact-number-bad-format': 'Please enter your <b>Contact Number</b> with the correct format.<br />(E.g. +60167552525)',

    //2021.08.23
    'password-too-short': '<b>Password</b> is too short, please enter password with <b>at least 6 letters</b>, or above.',
    'password-confirm-too-short': '<b>Password (Confirm)</b> is too short, please enter password with <b>at least 6 letters</b>, or above.',

    //2021.08.26
    'phone-number-sample': 'e.g. 127552525',
    'contact-number-too-short': '<b>Contact Number</b> is too short, please enter number with <b>at least 8 digits</b>, and total length not more than 15.',


    //===== SignUp Screen (National Quiz Event) =====//
    'label-state': 'State',
    'label-national-state': '(E.g. Pulau Pinang)',
    'label-school': 'School',
    'text-school-name': '(Fill in School Name in Malay)',
    'text-school-sample': '(E.g. Chung Hwa or <b><i>Other</i></b> for none related.)',
    'label-agree-policy-tnc': 'Privacy Policy And Terms & Condition',
    'text-accept': 'I accept the ',
    'text-accept-1': '.',
    'text-privacy-policy': 'Privacy Policy',
    'text-tnc': 'Terms & Conditions',
    'national-state-not-selected': 'Please do not leave <b>National State</b> unselected.',
    'school-name-not-empty': 'Please do not leave <b>School Name</b> empty.',
    'policy-tnc-not-checked': 'You must agree on the <b><u>Privacy Policy</u></b> and <b><u>Terms & Conditions</u></b> in order to proceed your registration.',
    'label-guardian-name': 'Guardian Name',
    'label-12-and-below': '12 years old and below.',
    'guardian-name-not-empty': 'Upon checked on the <b><u>12 years old and below</u></b> option, please do not leave your <b><u>Guardian Name</u></b> empty.',
    'label-center-student-1': 'Are you a student from ',
    'label-center-student-2': ' ?',
    'center-student-not-selected-1': 'Please do not leave <b>Are you a student from ',
    'center-student-not-selected-2': ' ?</b> unselected, please select <b>Yes</b> or <b>No</b>.',

    //2020.12.14
    'label-select-student-group': 'Select your group for ',
    'not-specify-group': '(Select Group)',
    'student-group-not-selected': 'Please select your <b>group</b>.',
    'group-a-abacus': 'Group A (6 years old & below)',
    'group-b-abacus': 'Group B (7 - 9 years old)',
    'group-c-abacus': 'Group C (10 - 12 years old)',
    'group-d-abacus': 'Group D (13 years old & above)',

    //2021.07.30
    'label-gender': 'Gender',
    'not-specify-gender': '(Select Gender)',
    'label-gender-male': 'Male',
    'label-gender-female': 'Female',
    'label-gender-other': 'Other',
    'label-race': 'Race',
    'not-specify-race': '(Select Race)',
    'label-race-0': 'Other',
    'label-race-1': 'Chinese',
    'label-race-2': 'Malay',
    'label-race-3': 'Indian',
    // 'label-religion': 'Religion',
    // 'not-specify-religion': '(Select Religion)',
    // 'label-religion-0': 'Other',
    // 'label-religion-1': 'Buddha',
    // 'label-religion-2': 'Christian',
    // 'label-religion-3': 'Catholic',
    // 'label-religion-4': 'Islam',
    // 'label-religion-5': 'Hindu',

    //2021.08.19
    'auto-reg-success-message': 'Account Successfully Created! You can also use this email and password to login iKEY to access the completed live quiz results and certificates.',
    // 'auto-reg-try-again-message': 'For user with difficulty in registering for an account at the moment after multiple tries, please try again at a later time, as we are now surveying on this matter and will solve this as soon as possible.',
    'auto-reg-try-again-message': 'We have noticed that there are some users not being able to register for an account at the moment after multiple tries, please try again at a later time, as we are now surveying on this matter and will solve this as soon as possible.',

    //2021.10.12
    'label-district': 'District Area',
    'label-district-area': '(E.g. PPD TIMUR LAUT)',
    'district-area-not-selected': 'Please select your <b>district</b>.',

    //2022.05.12
    'years-old': ' years old',
    'kindergarden': 'Kindergarden',

    //===== Password Reset Screen =====//
    'password-reset': 'Password Reset',
    'confirm-your-email': 'Confirm Your Email',
    'get-password-reset-link': 'Get Password Reset Link',
    'confirm-email-not-empty': 'Please do not leave your confirmation email empty.',
    'enter-email-correctly': 'Please enter your email correctly.',
    'enter-confirm-email-correctly': 'Please enter your confirmation email correctly.',
    'reset-password-sent': 'Reset password link has been sent to your registered email address.',
    'reset-password-failed': 'Failed to send Reset password link to the provided Email. <br />Please check & try again.',
    'email-not-match': 'Both of the entered emails are not match. <br />Please check & try again.',
    // 'reset-pass-failed-label-possible-reason': 'Possible reason:',
    'reset-pass-failed-user-not-found': 'Oops! this email address is <u>not registered</u> with us.<br />You may want to register for a new account.',
    'reset-pass-failed-invalid-email': 'This email address is invalid or not in a proper format.<br />Please provide a proper email address and try again.',


    //===== Home Screen =====//
    'today-last-quiz': 'Last Attended Quiz',
    'click-to-enter-room': 'Click to enter Room',
    'room-code-1': 'Room Code',
    'room-code': 'Room',
    'room-title': 'Title',
    'room-date': 'Date',
    'room-date-of-enter': 'Date of Enter',   //2023.11.16
    'room-submitted-date': 'Date of Submit',      //2023.11.16
    'room-score': 'Score',
    'room-code-ph': 'Please enter room code here.',
    'entering-room': 'Entering Room #',
    'room-code-required': 'Room code is required.',
    'invalid-room-code-entered': 'Invalid Room Code',
    'loading-room-info': 'Loading Room Info...',
    'room-not-found': 'Room not found.<br /><br />Possibilities：<br />(1) The room can only be enter on the day it is held.<br />(2) The date & time on this device is not up to date.',
    'profile-update-in-progress': 'Profile update in progress',
    'click-to-edit-profile': 'Click here to edit profile.',
    'show-quiz-history': 'Show Quiz History',
    'title-upcoming-quiz': 'Upcoming Quiz',
    'policy-tnc-agree-datetime': 'Date agreed',
    'policy-tnc-published': 'Published Date',
    'edit-profile': 'Update Profile',
    'title-attention-needed': 'Attention Needed',
    'text-policy-tnc-agree-needed':
        'To our honored registered user, the latest version of <b><u>Privacy Policy</u></b> and <b><u>Terms & Conditions</u></b> has been updated lately.'
        + '<br /><br />'
        + "It seems that you haven't agree to the latest version of <b><u>Privacy Policy</u></b> and <b><u>Terms & Conditions</u></b>. "
        + '<br /><br />'
        + 'We kindly advice our user to agree to the latest version of <b><u>Privacy Policy</u></b> and <b><u>Terms & Conditions</u></b> in order to continue using the service.',
    'text-history-list-is-empty': 'History list is empty.',
    'download-ikey-parent-app': "Download the <b><u>iKEY</u></b> App to know more about your children's exam results.",
    'text-profile-update-needed': 'There are some information missing regarding to your profile, please proceed to update your profile as soon as possible.',

    //2020.12.15
    'i-want-to-join': 'I want to join !',
    'quiz-event-join-facebook': 'Click here to Join our Facebook group to know more.',

    //2021.01.19
    'cannot-enter-room-today-title': 'This room is not available to enter at the moment.',
    // 'cannot-enter-room-today-content-1': '',
    // 'cannot-enter-room-today-content': 'Possibilities：<br />(1) The room can only be enter on the day it is held.<br />(2) The date & time on this device is not up to date.',
    'cannot-enter-room-today-content': 'Reminder: <ul style="list-style-type:decimal;padding-left:20px;"><li>Please make sure you are connecting to stronger wifi for better experience.</li><li>You are advised to use Google Chrome browser to join the quiz.</li><li>Please join the correct group only.</li><li>Please join at the correct starting time and date.</li></ul>',

    //2022.10.15
    'event-reminders': "Reminder: <ul style='list-style-type:decimal;padding-left:20px;'><li>Please make sure you are connected to a strong wireless network (Wifi) for a better experience.</li><li>Strongly recommended to use Google Chrome browser to join the quiz.</li><li>Please make sure you are in the correct standard on the day of the quiz.</li><li>Please join the quiz at correct date and time.</li><li>Before entering quiz room, please <b>make sure device's date & time are correct</b>.</li>",
    'event-reminders-telegram-info': '<li>Join our Telegram group now to get latest update about quiz.<br />SJKC: <a href="https://t.me/SPOT_SJKC" target="_blank">https://t.me/SPOT_SJKC</a><br />SK: <a href="https://t.me/SPOT_SK" target="_blank">https://t.me/SPOT_SK</a><br />Kindergarten: <a href="https://t.me/SPOT_PreSchool" target="_blank">https://t.me/SPOT_PreSchool</a></li>',

    //2021.02.01
    // 'title-attention': 'Attention',
    'event-registration-end-datetime': '<span class="blink" style="color:red;"><i class="fa fa-exclamation-triangle"></i> <b>Attention</b> <i class="fa fa-exclamation-triangle"></i></span><br /><br />Please take note that the registration deadline is ',
    'event-registration-ended': 'Registration for this event has been ended at ',

    //2021.11.10
    'event-registration-overloaded': 'Sorry, the registration is now closed.<br />We have reached full capacity for the event.',

    //2021.02.17
    'register-now': 'Register Now',

    //2023.12.06
    'title-invalid-operation': 'Invalid Operation',
    'warning-profile-under-maintain-by-organizer': 'Profile is currently maintain by Organizer, please contact Organizer for any changes needed.',
    'organizers': 'Organizers',

    //===== Quiz Live Screen =====//
    'no-of-qs': 'No. of Questions',
    'total-duration': 'Total Duration',
    'begin': 'Begin',
    // 'waiting-to-start': 'waiting to start',
    'waiting-to-start': "waiting to start<br /><br />if the Begin button still haven't show after start time, please refresh this page or go back to Home page & enter the room again.",     //2021.08.28
    'loading-qs': 'Loading Questions...',
    'submit-and-show-result': 'Submit & Show Result',
    'quiz-result': 'Quiz Result',
    'quiz-score': 'Score',
    'quiz-total-time-consumed': 'Total Time Consumed',
    // 'quiz-total-correct': 'Total Correct',
    // 'quiz-total-wrong': 'Total Wrong',
    'quiz-total-correct': 'Total Correct Answers',
    'quiz-total-wrong': 'Total Wrong Answers',
    'question': 'Question',
    'question-to': 'to',
    'result': 'Result',
    'goto': 'Shortcut',
    'goto-qs': 'Go to Question',
    'processing-result': 'Processing Result...',
    'time-left': 'Time Left',
    'quiz-time-left': 'Quiz Active Time Left',
    'created-date': 'Quiz Created Date',
    'held-date': 'Quiz held Date',
    'start-time': 'Time Start',
    'end-time': 'Time End',
    'participated-date': 'Participated Date',       //2021.04.05

    //2023.10.18
    'quiz-room-access-restricted-label': 'Current Room has been Restricted Access',
    'quiz-room-access-restricted-notice': 'Access of this Room has been temporarily restricted at the moment.<br />Please try enter again at later time.',

    //===== Quiz Question component =====//
    'quiz-times-up': "Time's Up, No Answer was Given",
    // 'quiz-qs-comprehension': 'Read the following article carefully, and answer questions start from No.',
    'quiz-qs-comprehension': 'Based on the passage below, please select the most appropriate answer.<br />(No.',
    'quiz-qs-comp-to': ' to ',
    // 'quiz-qs-comp-end': ' ',
    'quiz-qs-comp-end': ')',
    'hide-article': 'Hide Passage',
    'show-article': 'Show Passage',
    'next': 'Next',
    'goto-result': 'Result',
    'fill-in-the-blanks': 'Fill in the Blanks',
    'comprehension': 'Comprehension',
    'your-answer': 'Answered',
    'correct-answer': 'Correct Answer',
    'times-up': "Time's Up",
    'options-for-qs': 'Answer Options for Question ',
    'FITB-instruction-1': 'Click on the ',
    'FITB-instruction-2': ' button to select answer.',
    'subjective': 'Subjective',
    'enter-answer-here': 'Enter answer here',
    'subjective-instruction': 'Calculate the total and fill in the answer<br />Enter or click confirm to next',

    //2021.04.30
    'quiz-ans-empty': "No Answer Given",
    'label-quiz-sub-possible-answers': 'Possible Answers',
    'label-quiz-sub-possible-answers-combination': 'Possible Answers Combination',


    //===== ParticipatedEventList =====//
    'no-event-found': "There isn't any participated event found at the moment.",
    'label-download-certificate': 'Download e-Certificate (PDF)',
    'label-download-certificate-delayed': 'the download feature of e-Cert is not yet available.',
    'label-download-certificate-delayed-deadline': 'The e-cert will be available for download after <>.',      //2021.11.16
    'label-download-certificate-delayed-deadline-1': '(or available for download sooner)',            //2022.06.11
    'label-view-history': 'View<br />Quiz',
    'label-participated-on-date': '* Participated on ',
    'label-participated-on-date-1': '* 於 ',
    'label-participated-on-date-2': ' 参与了这项活动',
    // 'label-notice-participated-event': 'Collect your e-Certifcate PDF via Participated Event page.',
    // 'label-notice-participated-event': 'Do you want to collect your e-certificate ?<br />Click the "Participated Event" banner below to collect now !',
    //2021.08.25
    'label-notice-participated-event': 'Do you want to collect your e-certificate ?<br />Click the banner below to know how to download e-certificate.',
    'label-notice-participated-event-innerpagebtn': 'Do you want to collect your e-certificate ?<br />Click here to know how to download e-certificate.',

    'label-pe-feature': 'This feature require access via &#60;iKEY&#62; app',
    // 'notice-pe-download-pdf': 'Download feature is only accessible through <b>iKEY</b> app.<br /><br />Please download & install <b>iKEY</b> app & <u>access this page again via app</u> in order to download certificate.',
    'notice-pe-download-pdf': '<b>You need to install iKEY APP to get the e-cert!</b><br /><br />Click <a>here</a> for the tutorial.',
    'notice-pe-load-more': 'This feature is only accessible through <b>iKEY</b> app.<br /><br />Please download & install <b>iKEY</b> app & <u>access this page again via app</u> in order to load more <u>participated events</u>.',
    'notice-pe-list-limit-5': '* Download <b><u>iKEY</u></b> app to view the complete participated events list.',
    'label-load-more': 'load more',
    // 'label-pe-feature-load-more': '',
    // 'notice-pe-feature-load-more-nomore': '',
    'pdf-download-success': 'e-Cert has been downloaded successfully!',
    'pdf-download-notice': "Your e-Cert PDF has been generated successfully. Please patiently wait for the download to complete. After download completion, it will automatically triggers your device's default PDF reader app to view on the file. You can save the PDF file in device via the PDF reader. If nothing had happened, your iKEY app may not be using the latest available version, please update your iKEY app. If issue still persist after app update, then there may be a compatibility issue on your device, please switch to another device to try again.",
    'label-pdf-download-reminder': 'Reminder',
    'notice-pdf-download-reminder': 'If your e-Cert download has not started, please update your iKEY to the latest version and try again.',
    'label-pdf-download-manual-btn': 'Manual Download e-Cert',

    //2021.08.30
    'label-pdf-download-success': 'Successfully downloaded!',
    'notice-pdf-download-success': "Your e-cert has been successfully downloaded. Please check it in your device's download folder.",

    //2021.09.10
    'label-pdf-download-failed': 'Download failed.',
    'notice-pdf-download-failed': 'Download has failed at the moment. Please try again at later time.',

    //2021.12.09
    'click-to-view-participant-works': 'Click to view Participant submitted entries',
    'participant-submitted-entries': 'View All Participant Submitted Entries',

    //===== Others =====//
    'quiz-history': 'iKEY Quiz History',
    'last-update': 'Last Update',
    'download-ikey-parent-for-complete-history': '* Download <a href="#download-app-pos"><b><u>iKEY</u></b></a> app to view the complete quiz history list.',

    //===== QuizRoomTypeFileUpload =====//
    'room-essay-title': 'Essay Title',
    'room-essay-report-label': 'Official Report',
    'room-essay-report-url-pending': 'official report link is not yet reveal.',
    'file-uploaded': 'File',
    'file-upload-date': 'File Upload Date',
    'file-upload-success': 'File has successfully uploaded.',
    'score-issue-date': 'Score Issued Date',
    'not-yet-specify-result': 'pending for result',
    'room-essay-upload-ended': 'quiz is ended, upload feature has been turned off.',      //2021.11.15
    'room-essay-cert-sn-label': 'e-Cert Serial Number',     //2021.12.07
    'pending': 'pending',   //2021.12.10

    //===== ManageStudentProfile =====//
    'label-custom-group': 'Custom Group',
    // 'label-csr': 'CSR',
    // 'label-tuition': 'Tuition',
    // 'label-pemulihan': 'Pemulihan',
    'placeholder-custom-group-name': 'Name',
    'placeholder-custom-group-alwaysOnTop': '(Always On Top)',
    'placeholder-custom-group-displayOrder': '(Display Order)',
    'placeholder-custom-group-active': '(Active)',
};
export default english;