import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Col, Dropdown, DropdownButton, Modal, OverlayTrigger, ProgressBar, Row, Tooltip } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";

import LoadingIndicator from "./LoadingIndicator";
import { Locale } from "../utilities/localization/CustomLocalization";
import { CapitalizeJsonKeys, CheckBoolean, CheckNullValue, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, ConsoleLog, Delay, DelayUntil, FormatList_QuestionSet, GetPropIds, ScrollToElement } from "../utilities/GlobalFunctions";
import { AtoZ, GlobalSetting, LayoutScreen, Menu, getMenuLink } from "../utilities/GlobalSetting";
import { useGlobal } from "../utilities/GlobalVariables";
import { useAppService } from "../services/AppService";
import { AlertMode } from "./AlertComponent";
import EditQuestionObjective from "./EditQuestionObjective";

import { dbQuizBank, firestore } from "../utilities/Firebase";

import * as alasql from 'alasql';
import * as XLSX from 'xlsx';

alasql.setXLSX(XLSX);

const DataInput = {
    None: 0,
    Name: 1,
    Remark: 2,
    TotalQuestion: 3,
    IsPublic: 4,
    IsPrivateGroup: 5,
    Published: 6,
    DisplayOrder: 7,
    Group: 8,
    Subject: 9,
};

export default class EditQuestionSetScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.

        this.EditQSet_Name = React.createRef();
        this.EditQSet_Remark = React.createRef();
        this.EditQSet_Group = React.createRef();
        this.EditQSet_Subject = React.createRef();      //2022.02.10
        this.EditQSet_IsPublic = React.createRef();
        this.EditQSet_IsPrivateGroup = React.createRef();
        this.EditQSet_Published = React.createRef();
        this.EditQSet_DisplayOrder = React.createRef();
        this.EditQSet_TotalQuestion = React.createRef();

        this.EditQuestionObjective_Ref = React.createRef();
    }

    // firestore = getFirestore(app);
    // dbQuizBank = getDatabase(app_rtdb_dbQuizBank);

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,

        User: null,
        QueryParams: null,

        QuestionSetUniqueId: '',
        QuestionSetDetail: [],
        CachedQuestionSetDetail: [],        //as default state for reset purpose.
        IsDetailLoadingDone: false,
        IsSaveSettingsConditionFullfilled: false,
        IsEdited: false,

        ToggleQuestionListUi: false,
        IsQuestionsLoadingDone: false,
        QuestionList: [],
        CachedQuestionList: null,             //as default state for reset purpose.
        SelectedQuestion: null,
        ToggleEditQuestionUi: false,
        AvailableTags: [],

        IsSavingSettingsViaAPI: false,
        IsUpdateSuccess: null,

        ComprehensionPassage: [],
        FillInTheBlanks: [],

        //2021.09.13
        Toggle_AddOrRemoveQuestionUi: false,
        SelectedQuestionIndex: -1,
        AddOrRemoveQuestion_State: 0,
        SelectedQsIndexForRemoval: -1,
        Toggle_ConfirmRemoveQuestionUi: false,

        //2021.09.23
        PreviousSelectedQuestionIndex: -1,
        PreviousSelectedQuestionUniqueId: '',

        //2021.10.04
        Toggle_ConfirmSwitchQuestion: false,
        ConfirmSwitchQuestion: false,
        TempCachedSelectedQuestion: null,

        //2021.12.20
        hiddenQuestionSetTableForXLS: '' || null,
        hiddenQuestionSetTableForXLS_comp: '' || null,
        hiddenQuestionSetTableForXLS_fitb: '' || null,
        hiddenQuestionSetTableForXLS_fileName: '',
        IsQuestionSetTablePopulationDone: false,

        //2022.06.10
        Show_Delete_QuestionSetModal: false,

        //2024.03.26
        ToggleQuestionList: true,
    });

    componentWillUnmount = () => { }

    componentDidMount = async () => {
        useGlobal.getState().setScreen(LayoutScreen.EditQuestionSet);
        await useAppService.getState().getGroups();
        await useAppService.getState().getSubjects();
        await Delay(0);
        const questionSetUniqueId = useAppService.getState().questionSetUniqueId;
        const user = useGlobal.getState().user;
        const params = GetPropIds(user);
        if (CheckNullValue(questionSetUniqueId) === null || user === null)
            return null;
        this.setState({
            QuestionSetUniqueId: questionSetUniqueId,
            User: user,
            QueryParams: params,
        });
        await Delay(0);
        this.LoadQuestionSetDetail_ViaApi();
    }

    //#region === Question Set - Settings ===
    LoadQuestionSetDetail_ViaApi = async () => {

        if (this.state.User === null)
            return null;

        if (this.state.IsDetailLoadingDone === true)
            return null;

        let questionSet = null;
        let success = false;
        let errorMessage = '';

        const { authorId, organizerId } = this.state.QueryParams;
        // if (this.state.isDevMode)
        //     console.log('LoadQuestionSetDetail_ViaApi', authorId, organizerId);

        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/QuestionSet/Get/'
            + organizerId + '/'
            + authorId + '/'
            + String(this.state.QuestionSetUniqueId),
            // Api/LearningCentre/Quiz/QuestionSet/Get/{organizerId}/{authorId}/{uniqueId}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                ConsoleLog('LoadQuestionSetDetail_ViaApi (source)\n' + JSON.stringify(data));
                success = data.success;
                if (success)
                    questionSet = data.data;
                else
                    errorMessage = 'api-get / qSet (failed)\n' + JSON.stringify(data);
            })
            .catch(error => {
                errorMessage = 'api-get / qSet (failed)\n' + error.message;
            });

        if (questionSet !== null) {

            questionSet = CapitalizeJsonKeys(questionSet);

            //2024.07.23 - avoid qSet is load by another person (Teacher).
            const t_authorId = CheckObjectNumber(questionSet, 'AuthorId');
            if (t_authorId > 0) {
                const t_user_isTeacher = CheckObjectBoolean(useGlobal.getState().user, 'IsTeacher');
                const t_user_authorId = CheckObjectNumber(useGlobal.getState().user, 'AuthorId');
                // ConsoleLog(`t_user_isTeacher = ${String(t_user_isTeacher)}`);
                // ConsoleLog(`t_user_authorId = ${String(t_user_authorId)}`);
                // ConsoleLog(`t_user_authorId > 0 = ${String(t_user_authorId > 0)}`);
                // ConsoleLog(`t_user_authorId === t_authorId = ${String(t_user_authorId === t_authorId)}`);
                // ConsoleLog(`t_user_isTeacher && t_user_authorId > 0 && t_user_authorId === t_authorId = ${String(t_user_isTeacher && t_user_authorId > 0 && t_user_authorId === t_authorId)}`);
                if (t_user_isTeacher) {
                    if (t_user_authorId > 0 && t_user_authorId === t_authorId) { }
                    else {
                        this.setState({ redirectLink: Menu['questionSet'].Link, redirect: true, });     //back to list.
                        return null;
                    }
                }
            }

            //Finalize list.
            let _List = [];
            _List.push(questionSet);
            const groupOptions = useAppService.getState().groupOptions;
            const subjectOptions = useAppService.getState().subjectOptions;
            _List = FormatList_QuestionSet(_List, groupOptions, subjectOptions);
            questionSet = _List[0];
            ConsoleLog('LoadQuestionSetDetail_ViaApi (final)\n' + JSON.stringify(questionSet));

            this.setState({
                QuestionSetDetail: questionSet,
                CachedQuestionSetDetail: JSON.parse(JSON.stringify(questionSet)),   //to avoid sharing same memory location.
                IsDetailLoadingDone: true,
            }, () => {
                this.CheckOnSaveSettingsConditions();
                this.PopulateFormUiData();
            });
        }
        else {
            useAppService.getState().setModal('Failed', 'Unable to fetch Question Set.<br /><br />Error:<br />' + errorMessage);
            ConsoleLog('Error\n' + errorMessage);
        }
    }
    // LoadQuestionSetDetail = async () => {
    //     if (this.props.user === null)
    //         return null;

    //     if (this.state.IsDetailLoadingDone === true)
    //         return null;

    //     let _detail = null;
    //     await this.props.firestore
    //         .collection("QuizBank")
    //         .doc('QuizQuestionSet')
    //         .collection('QuizQuestionSets')
    //         .where('UniqueId', '==', String(this.state.QuestionSetUniqueId))
    //         .get()
    //         .then(querySnapshot => {
    //             let dataArray = [];
    //             if (querySnapshot !== null) {
    //                 querySnapshot.forEach((doc) => {
    //                     dataArray.push(doc.data());
    //                     // dataArrayCached.push(doc.data());
    //                 });
    //                 if (dataArray.length > 0) {
    //                     _detail = dataArray[0];
    //                 }
    //             }
    //             // if (this.state.isDevMode)
    //             //     console.log(JSON.stringify(_detail));
    //         })
    //         .catch(error => {
    //             // alert(JSON.stringify(error));
    //             if (this.state.isDevMode)
    //                 console.log(error.message);
    //         });

    //     // //2022.02.10
    //     // if (_detail !== null)
    //     //     if (_detail.hasOwnProperty('Subject') === false)
    //     //         _detail.Subject = this.props.SubjectOptions[0];     //default

    //     // //2022.02.11
    //     // if (_detail !== null) {
    //     //     if (_detail.hasOwnProperty('Subject') === false)
    //     //         _detail['Subject'] = { Id: 0, Name: '-' };     //default
    //     //     else
    //     //         if (_detail.Subject['Id'] === 0 || _detail.Subject['Id'] === undefined)
    //     //             _detail['Subject'] = { Id: 0, Name: '-' };     //default
    //     // }
    //     //2022.02.14
    //     _detail = this.FormatList_Subject(_detail);
    //     if (this.state.isDevMode)
    //         console.log('qSet result =\n' + JSON.stringify(_detail));

    //     this.setState({
    //         QuestionSetDetail: _detail === null ? [] : _detail,
    //         CachedQuestionSetDetail: JSON.parse(JSON.stringify(_detail)),   //to avoid sharing same memory location.
    //         IsDetailLoadingDone: true,
    //     }, () => {
    //         this.CheckOnSaveSettingsConditions();
    //         this.PopulateFormUiData();
    //     });
    // }
    SaveDataInput = async (_value, _inputType) => {
        if (this.state.IsDetailLoadingDone) {
            let _selected = null;
            let _detail = this.state.QuestionSetDetail;
            // await Delay(200);
            switch (_inputType) {
                default: break;
                case DataInput.Name: _detail.Name = _value; break;
                case DataInput.Remark:
                    if (String(_value).length <= 250)
                        _detail.Remark = _value;
                    else
                        this.EditQSet_Remark.current.value = _detail.Remark;
                    break;
                case DataInput.TotalQuestion: _detail.TotalQuestion = _value; break;
                case DataInput.IsPublic: _detail.IsPublic = _value; break;
                case DataInput.IsPrivateGroup: _detail.IsPrivateGroup = _value; break;
                case DataInput.Published: _detail.Published = _value; break;
                case DataInput.DisplayOrder: _detail.DisplayOrder = _value; break;
                case DataInput.Group:
                    // _detail.Group = _value; 
                    _selected = null;
                    useAppService.getState().groupOptions.map((data, key) => {
                        if (_value.value === data.value && _value.id === data.id)
                            _selected = { Id: Number(data.id), Name: String(data.value) };
                        return null;
                    });
                    _detail.Group = _selected;
                    break;
                // case DataInput.Subject: return this.state.QuestionSetDetail.Subject;
                case DataInput.Subject:     //2022.02.10
                    _selected = null;
                    useAppService.getState().subjectOptions.map((data, key) => {
                        if (_value.value === data.value && _value.id === data.id) {
                            _selected = { Id: Number(data.id), Name: String(data.value), Label: String(data.label) };
                        }
                        return null;
                    });
                    _detail.Subject = _selected;
                    break;
            }
            // await Delay(500);
            this.setState({
                IsEdited: true,
                QuestionSetDetail: _detail,
            }, () => {
                this.CheckOnSaveSettingsConditions();
                ConsoleLog(JSON.stringify(this.state.QuestionSetDetail));
            });
        }
    }
    GetSubjectLabel = (_subject) => {
        //2022.08.17
        if (_subject === null || _subject === undefined)
            return '';
        return _subject['Name'] + ' (' + _subject['BC'] + ') (' + _subject['BM'] + ')' + (_subject['TagName'] !== '' ? ' (' + _subject['TagName'] + ')' : '');
    }
    GetDataValue = (_inputType) => {
        if (this.state.IsDetailLoadingDone) {
            switch (_inputType) {
                default: return '';
                case DataInput.Name: return CheckObjectStringEmpty(this.state.QuestionSetDetail, 'Name');
                case DataInput.Remark: return CheckObjectStringEmpty(this.state.QuestionSetDetail, 'Remark');
                case DataInput.TotalQuestion: return CheckObjectNumber(this.state.QuestionSetDetail, 'TotalQuestion');
                case DataInput.IsPublic: return CheckObjectBoolean(this.state.QuestionSetDetail, 'IsPublic');
                case DataInput.IsPrivateGroup: return CheckObjectBoolean(this.state.QuestionSetDetail, 'IsPrivateGroup');
                case DataInput.Published: return CheckObjectBoolean(this.state.QuestionSetDetail, 'Published');
                case DataInput.DisplayOrder: return CheckObjectNumber(this.state.QuestionSetDetail, 'DisplayOrder');
                case DataInput.Group: return this.state.QuestionSetDetail.Group;
                case DataInput.Subject: return this.state.QuestionSetDetail.Subject;    //2022.02.10
            }
        }
        return '';
    }
    PopulateFormUiData = () => {
        if (this.state.QuestionSetDetail !== null) {
            this.EditQSet_Name.current.value = this.state.QuestionSetDetail.Name;
            this.EditQSet_Remark.current.value = this.state.QuestionSetDetail.Remark;
            this.EditQSet_Group.current.value = this.state.QuestionSetDetail['Group'] !== undefined ? this.state.QuestionSetDetail.Group.value : null;
            this.EditQSet_Subject.current.value = this.state.QuestionSetDetail['Subject'] !== undefined ? this.state.QuestionSetDetail.Subject.value : null;   //2022.02.10
            this.EditQSet_IsPublic.current.value = this.state.QuestionSetDetail.IsPublic;
            this.EditQSet_IsPrivateGroup.current.value = this.state.QuestionSetDetail.IsPrivateGroup;
            this.EditQSet_Published.current.value = this.state.QuestionSetDetail.Published;
            this.EditQSet_DisplayOrder.current.value = this.state.QuestionSetDetail.DisplayOrder;
            this.EditQSet_TotalQuestion.current.value = this.state.QuestionSetDetail.TotalQuestion;
        }
    }
    ResetForm = () => {
        this.setState({
            IsEdited: false,
            QuestionSetDetail: JSON.parse(JSON.stringify(this.state.CachedQuestionSetDetail)),  //to avoid sharing same memory location.
        }, () => {
            this.CheckOnSaveSettingsConditions();

            //reset values.
            this.PopulateFormUiData();

            // console.log('SET(reset):\n' + JSON.stringify(this.state.QuestionSetDetail));
            // console.log('CACHED(reset):\n' + JSON.stringify(this.state.CachedQuestionSetDetail));
        });
    }
    CheckOnSaveSettingsConditions = () => {
        let _fullfilled = false;
        if (this.state.IsDetailLoadingDone) {
            if (
                Number(this.state.QuestionSetDetail.TotalQuestion) > 0
                && this.state.QuestionSetDetail.Name !== ''
                && this.state.QuestionSetDetail.Group !== null
                && this.state.QuestionSetDetail.Subject !== null    //2022.02.10
            ) {
                _fullfilled = true;
            }
        }
        this.setState({ IsSaveSettingsConditionFullfilled: _fullfilled });
        // console.log(
        //     'TotalQuestion set = ' + String(Number(this.state.QuestionSetDetail.TotalQuestion) > 0) +
        //     '\nName set = ' + String(this.state.QuestionSetDetail.Name !== '') +
        //     '\nGroup set = ' + String(this.state.QuestionSetDetail.Group !== null) +
        //     '\nFullfilled = ' + String(_fullfilled)
        // );
        // console.log('SET:\n' + JSON.stringify(this.state.QuestionSetDetail));
        // console.log('CACHED:\n' + JSON.stringify(this.state.CachedQuestionSetDetail));
    }
    //2021.08.05
    SaveQuestionSetSettings = async () => {
        const user = useGlobal.getState().user;
        if (user === null)
            return null;

        this.setState({
            IsSavingSettingsViaAPI: true,
            IsUpdateSuccess: null,
        });
        useAppService.getState().setModal('Update', 'Applying changes...', null, AlertMode.Loading);

        //2023.09.09
        const { uid, centerUserId, authorId, authorRoleId, organizerId } = GetPropIds(user);
        ConsoleLog(`LoadMoreQuestionSetList = ${centerUserId}, ${authorId}, ${authorRoleId}, ${organizerId}`);

        //Call CMS APi for update actione.
        let isUpdateSuccess = false;
        let message = '';
        let jsonModel = {
            OrganizerId: organizerId,       //added 2023.09.09
            CenterUserId: centerUserId,     //Number(this.props.user.CenterUserId),
            AuthorId: authorId,             //Number(this.props.user.AuthorId),
            // AuthorRoleId: Number(this.props.user.AuthorRoleId),    //1 = admin, 4 = center, 11 = Author
            FirebaseUserId: uid,            //String(this.props.user.uid),

            UniqueId: String(this.state.QuestionSetDetail.UniqueId),
            Name: String(this.state.QuestionSetDetail.Name),
            Remark: String(this.state.QuestionSetDetail.Remark),
            TotalQuestion: Number(this.state.QuestionSetDetail.TotalQuestion),
            GroupId: Number(this.state.QuestionSetDetail.Group.Id),         //small letter id. key from GroupOptions
            GroupName: String(this.state.QuestionSetDetail.Group.Name),    //small letter value.

            //2022.02.10
            SubjectId: Number(this.state.QuestionSetDetail.Subject.Id),         //small letter id. key from SubjectOptions
            SubjectName: String(this.state.QuestionSetDetail.Subject.Name),    //small letter value.

            DisplayOrder: Number(this.state.QuestionSetDetail.DisplayOrder),
            IsPublic: CheckBoolean(this.state.QuestionSetDetail.IsPublic),
            IsPrivateGroup: CheckBoolean(this.state.QuestionSetDetail.IsPrivateGroup),
            Published: CheckBoolean(this.state.QuestionSetDetail.Published),

            //2021.09.14
            IsRemoveQuestion: this.state.SelectedQsIndexForRemoval < 0 ? false : true,
            // QuestionNoForRemoval: this.state.SelectedQsIndexForRemoval < 0 ?
            //     -1
            //     : this.state.QuestionList[this.state.SelectedQsIndexForRemoval] === undefined ?
            //         -1
            //         : this.state.QuestionList[this.state.SelectedQsIndexForRemoval].No,

            //2021.10.25
            QuestionUniqueId: this.state.SelectedQsIndexForRemoval < 0 ?
                ''
                : CheckNullValue(this.state.QuestionList[this.state.SelectedQsIndexForRemoval]) === null ?
                    ''
                    : this.state.QuestionList[this.state.SelectedQsIndexForRemoval].UniqueId,
        };
        ConsoleLog('question set setting modal =\n' + JSON.stringify(jsonModel));

        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/QuizBank/QuestionSet/Settings/Update',
            {
                method: 'POST',                             // *GET, POST, PUT, DELETE, etc.
                // mode: 'cors',                            // no-cors, *cors, same-origin
                // cache: 'no-cache',                          // *default, no-cache, reload, force-cache, only-if-cached
                // credentials: 'same-origin',                 // include, *same-origin, omit
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // redirect: 'follow',                         // manual, *follow, error
                // referrerPolicy: 'no-referrer',              // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(jsonModel), // body data type must match "Content-Type" header
            })
            .then(res => res.json())
            .then(data => {
                ConsoleLog('qset update = ' + JSON.stringify(data));

                isUpdateSuccess = data.success;
                message = 'api - update question set settings ('
                    + (data.success ? 'success' : 'failed')
                    + ').\n' + JSON.stringify(data);

                //2023.11.27
                if (isUpdateSuccess) {
                    if (jsonModel['IsRemoveQuestion']) {
                        let qSet = this.state.QuestionSetDetail;
                        qSet.TotalQuestion -= 1;
                        this.setState({ QuestionSetDetail: qSet });
                    }
                }
            })
            .catch(error => {
                message = 'Error : ' + error.message + ' | \n' + JSON.stringify(jsonModel);
            });

        ConsoleLog('api (response) =\n' + message);

        if (isUpdateSuccess === false) {
            //Failed.
            this.setState({
                IsSavingSettingsViaAPI: false,
                IsUpdateSuccess: false,
            }, () => {
                useAppService.getState().setModal('', message);
                ConsoleLog(message);
            });
        }
        else {
            //Success.

            //2021.10.26
            let _qsList = this.state.QuestionList;
            let remove_idx = -1;
            if (jsonModel.IsRemoveQuestion) {
                remove_idx = _qsList.findIndex(x => x.No === jsonModel.QuestionNoForRemoval && x.UniqueId === jsonModel.QuestionUniqueId);
                if (remove_idx > -1) {
                    _qsList.splice(remove_idx, 1);
                    _qsList.sort((x, y) => x.No - y.No);
                    _qsList.map((data, key) => { return data.No = (key + 1) });
                    this.setState({
                        QuestionList: _qsList,
                    }, () => {
                        ConsoleLog('_qsList =\n' + JSON.stringify(_qsList));
                    });
                }
            }
            // await Delay(500);

            // //2021.11.03
            // if (_qsList.TotalQuestion > this.state.CachedQuestionSetDetail.TotalQuestion) {
            //     let counter = _qsList.TotalQuestion - this.state.CachedQuestionSetDetail.TotalQuestion;
            //     for (var i = 0; i < counter; i++) {
            //         this.AddSingleQuestion(false);
            //     }
            // }

            this.setState({
                CachedQuestionSetDetail: JSON.parse(JSON.stringify(this.state.QuestionSetDetail)),
                IsSavingSettingsViaAPI: false,
                IsUpdateSuccess: true,
            }, async () => {
                this.CheckOnSaveSettingsConditions();
                this.PopulateFormUiData();

                await Delay(500);

                this.Callback_ReloadQuestionList(
                    this.state.PreviousSelectedQuestionIndex < 0 ? this.state.QuestionList.length
                        : CheckNullValue(this.state.QuestionList[this.state.PreviousSelectedQuestionIndex]) === null ? this.state.QuestionList.length
                            : Number(this.state.QuestionList[this.state.PreviousSelectedQuestionIndex].No) === this.state.QuestionList.length ?
                                this.state.QuestionList.length
                                : this.state.QuestionList[this.state.PreviousSelectedQuestionIndex].No
                );

                useAppService.getState().setModal('', 'Changes has been updated.');
            });
        }
    }
    ReSelectQuestion = (QsNo = '') => {
        this.setState({ ToggleEditQuestionUi: false, }, async () => {
            await Delay(200);
            this.setState({ ToggleEditQuestionUi: true, },
                () => {
                    let _finalSelectedQuestionIndex = this.state.SelectedQuestionIndex;

                    //2021.10.25
                    let _QsNo = QsNo;
                    if (_QsNo !== '' && _QsNo !== undefined) {
                        let continueLoop = false;
                        let index = -1;
                        //2021.11.23 - revamped.
                        let loopAction = () => {
                            if (this.state.QuestionList.length > 0) {
                                index = this.state.QuestionList.findIndex(x => x.No === _QsNo);
                                if (index > -1) {
                                    _finalSelectedQuestionIndex = index;
                                    this.SelectQuestion(this.state.QuestionList[index]);
                                }
                                else {
                                    if (_QsNo > this.state.QuestionList.length) {
                                        _QsNo = this.state.QuestionList.length;
                                        continueLoop = true;
                                    }
                                }
                            }
                            else {
                                _finalSelectedQuestionIndex = -1;
                                this.SelectQuestion(null);
                            }
                        };
                        do {
                            continueLoop = false;
                            loopAction();
                        } while (continueLoop);
                    }

                    if (this.state.isDevMode) {
                        if (this.state.SelectedQuestion !== null)
                            setTimeout(() => {
                                console.log('Question selected : ' + this.state.SelectedQuestion.No);
                            }, 0);
                    }
                    useAppService.getState().updateQuestionNoList(this.state.QuestionList, _finalSelectedQuestionIndex);
                });
        });
    }
    //#endregion


    //#region === Question List ===
    LoadQuestionList = async (QsNo = '', reload = false) => {
        let _selectedQuestion = null;
        if (this.state.SelectedQuestion !== null) {
            if (this.state.SelectedQuestion.No > Number(this.state.QuestionSetDetail.TotalQuestion)) {
                _selectedQuestion = null;
            }
            else {
                _selectedQuestion = this.state.SelectedQuestion;
            }
        }
        this.setState({
            IsQuestionsLoadingDone: false,
            ToggleQuestionListUi: true,
            SelectedQuestion: _selectedQuestion,
            ToggleEditQuestionUi: false,
        });

        let _questions = [];

        //2024.02.26
        if (QsNo === '' || reload === true) {
            const questionSetUniqueId = useAppService.getState().questionSetUniqueId;
            if (CheckNullValue(questionSetUniqueId) !== null) {
                let questionSetRoot = null;
                let success = false;
                let errorMessage = '';
                let done = false;
                await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/QuestionSet/Root/Get/'
                    + 0 + '/'
                    + 0 + '/'
                    + questionSetUniqueId,
                    // Api/LearningCentre/Quiz/QuestionSet/Root/Get/{organizerId}/{authorId}/{uniqueId}
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                    })
                    .then(res => res.json())
                    .then(data => {
                        ConsoleLog('LoadQuestionList (api)\n' + JSON.stringify(data));
                        success = data.success;
                        if (success)
                            questionSetRoot = data.data;
                        else
                            errorMessage = 'api-get / qSet (failed)\n' + JSON.stringify(data);
                        done = true;
                    })
                    .catch(error => {
                        errorMessage = 'api-get / qSet (failed)\n' + error.message;
                        done = true;
                    });
                await DelayUntil(() => done === true);
                if (questionSetRoot !== null) {
                    questionSetRoot = CapitalizeJsonKeys(questionSetRoot);
                    _questions = CheckObjectNullValue(questionSetRoot, 'Questions', []);
                    //remove null or empty item.
                    if (Array.isArray(_questions) && _questions.length > 0) {
                        _questions = _questions.filter((el) => { return (el !== null); });
                        _questions.sort((a, b) => a.No - b.No);     //2024.05.25 - forced sort coz db returned qs ordering not reliable.
                    }
                    else {
                        _questions = [];
                    }
                    ConsoleLog(JSON.stringify(_questions));
                }
                if (errorMessage !== '')
                    ConsoleLog('LoadQuestionList (error) = ' + errorMessage);
            }
        }
        else {
            _questions = this.state.QuestionList;
            if (this.state.SelectedQsIndexForRemoval < 0) {
                let singleQuestion = null;
                //Load single question only.
                if (Array.isArray(_questions) && _questions.length > 0) {
                    const singleQuestion_Index = _questions.findIndex(x => x.No === QsNo);
                    if (singleQuestion_Index > -1)
                        singleQuestion = _questions[singleQuestion_Index];
                }
                if (singleQuestion !== null) {
                    const qsIndex = Number(QsNo) - 1;
                    if (qsIndex > -1 && _questions.length - 1 >= qsIndex)
                        _questions[qsIndex] = singleQuestion;
                }
            }
        }

        //#region old code before 2024.02.26
        // if (QsNo === '' || reload === true) {
        //     //Get Questions from RTDB.
        //     await this.props.dbQuizBank
        //         .ref(this.state.QuestionSetUniqueId + '/Questions')
        //         .once('value', snapshot => {
        //             // handle read data.
        //             if (snapshot.exists()) {
        //                 _questions = snapshot.val();

        //                 //remove null or empty item.
        //                 _questions = _questions.filter((el) => { return (el !== null); });

        //                 if (this.state.isDevMode)
        //                     console.log(JSON.stringify(_questions));
        //             }
        //         });
        // }
        // else {
        //     // if (this.state.SelectedQsIndexForRemoval < 0)
        //     // let singleQuestion = null;
        //     // //Load single question only.
        //     // await this.props.dbQuizBank
        //     //     .ref(this.state.QuestionSetUniqueId + '/Questions/' + QsNo)
        //     //     .once('value', snapshot => {
        //     //         // handle read data.
        //     //         if (snapshot.exists()) {
        //     //             singleQuestion = snapshot.val();
        //     //             if (this.state.isDevMode)
        //     //                 console.log(JSON.stringify(singleQuestion));
        //     //         }
        //     //     });
        //     // if (singleQuestion !== null) {
        //     //     if (this.state.QuestionList !== null) {
        //     //         _questions = this.state.QuestionList;
        //     //         _questions[Number(QsNo - 1)] = singleQuestion;
        //     //     }
        //     // }

        //     //2021.10.26 - revamped.
        //     if (this.state.QuestionList !== null) {
        //         _questions = this.state.QuestionList;

        //         if (this.state.SelectedQsIndexForRemoval < 0) {
        //             let singleQuestion = null;
        //             //Load single question only.
        //             await this.props.dbQuizBank
        //                 .ref(this.state.QuestionSetUniqueId + '/Questions/' + QsNo)
        //                 .once('value', snapshot => {
        //                     // handle read data.
        //                     if (snapshot.exists()) {
        //                         singleQuestion = snapshot.val();
        //                         if (this.state.isDevMode)
        //                             console.log(JSON.stringify(singleQuestion));
        //                     }
        //                 });
        //             if (singleQuestion !== null) {
        //                 _questions[Number(QsNo - 1)] = singleQuestion;
        //             }
        //         }
        //     }
        //     //done.
        // }
        //#endregion

        this.setState({
            QuestionList: _questions,
            CachedQuestionList: JSON.parse(JSON.stringify(_questions)),
            IsQuestionsLoadingDone: true,
            SelectedQsIndexForRemoval: -1,
        }, async () => {
            ScrollToElement('QsList');
            // await this.LoadAvailableTags();
            // console.log(JSON.stringify(this.state.QuestionList));
            // console.log(JSON.stringify(this.state.CachedQuestionList));

            //2021.10.04
            if (this.state.PreviousSelectedQuestionIndex < 0)
                useAppService.getState().updateQuestionNoList(this.state.QuestionList, -1);
        });
    }
    LoadAvailableTags = async () => {
        if (this.state.AvailableTags.length <= 0) {
            const { organizerId } = this.state.QueryParams;
            let _tags = [];
            //Default Tags.
            await firestore
                .collection("QuizBank")
                .doc('QuizTag')
                .collection('QuizTags')
                .where('AuthorId', '==', Number(1))
                .get()
                .then(querySnapshot => {
                    let dataArray = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            dataArray.push(doc.data());
                        });
                        if (dataArray.length > 0) {
                            dataArray.map((data, key) => {
                                return _tags.push(data);
                            })
                        }
                    }
                    // if (this.state.isDevMode)
                    //     console.log(JSON.stringify(_tags));
                })
                .catch(error => {
                    ConsoleLog(error.message);
                });
            //Get Author's Tags from FS.
            await firestore
                .collection("QuizBank")
                .doc('QuizTag')
                .collection('QuizTags')
                .where('OrganizerId', '==', organizerId)
                .get()
                .then(querySnapshot => {
                    let dataArray = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            dataArray.push(doc.data());
                        });
                        if (dataArray.length > 0) {
                            dataArray.map((data, key) => {
                                return _tags.push(data);
                            })
                        }
                    }
                    // if (this.state.isDevMode)
                    //     console.log(JSON.stringify(_tags));
                })
                .catch(error => {
                    ConsoleLog(error.message);
                });
            //Sort.
            // _tags.sort((a, b) => a.Id - b.Id);
            // _tags.sort((a, b) => moment(a.DisplayOrder) - moment(b.DisplayOrder));
            if (_tags.length > 0)
                _tags.sort((a, b) => a.CenterUserId - b.CenterUserId || a.DisplayOrder - b.DisplayOrder || a.Id - b.Id);
            // if (this.state.isDevMode)
            //     console.log(JSON.stringify(_tags));
            this.setState({
                AvailableTags: _tags,
            }, () => {
                ConsoleLog('Tags =\n' + JSON.stringify(this.state.AvailableTags));
            });
        }
    }
    QuestionListUiComponent = (maxColPerRow = 20) => {
        let _components = [];
        let _columns = [];
        let _maxColPerRow = maxColPerRow;
        // let _itemCounts = this.state.QuestionList.length;
        if (this.state.QuestionList.length > 0) {

            if (maxColPerRow === 20)
                if (this.state.QuestionList.length <= 50)
                    _maxColPerRow = 10;

            this.state.QuestionList.map((data, key) => {

                //#region disable old experimental codes
                // _columns.push(<td
                //     key={'qsList' + key}
                //     align='center'
                //     valign='middle'
                //     onClick={() => this.SelectQuestion(data)}
                //     style={this.QsNumStyling(data)}
                // // disabled={this.QsNoDisabledByQuizType(data)}
                // >{data.No}</td>);
                // if (key > 0) {
                //     if (data.No % 10 === 0) {
                //         _components.push(<tr key={'qsList' + key}>{_columns}</tr>);
                //         _columns = [];
                //     }
                // }

                // console.log((key + 1) + ' = ' + String(data.No === this.state.QuestionList[this.state.QuestionList.length - 1].No));

                // //2021.09.01
                // if (data.No % 10 === 0) {
                //     //items per row.
                //     _components.push(<tr key={'qsList' + key}>{_columns}</tr>);
                //     _columns = [];
                // }
                // else if (data.No === this.state.QuestionList[this.state.QuestionList.length - 1].No) {
                //     //last item.
                //     _columns.push(<td
                //         key={'qsList' + key}
                //         align='center'
                //         valign='middle'
                //         onClick={() => this.SelectQuestion(data)}
                //         style={this.QsNumStyling(data)}
                //     // disabled={this.QsNoDisabledByQuizType(data)}
                //     >{data.No}</td>);
                //     _components.push(<tr key={'qsList' + key}>{_columns}</tr>);
                //     _columns = [];
                // }
                // else {
                //     //each item.
                //     _columns.push(<td
                //         key={'qsList' + key}
                //         align='center'
                //         valign='middle'
                //         onClick={() => this.SelectQuestion(data)}
                //         style={this.QsNumStyling(data)}
                //     // disabled={this.QsNoDisabledByQuizType(data)}
                //     >{data.No}</td>);
                // }
                //#endregion

                //2021.09.08
                //each item.
                _columns.push(<td
                    key={'qsList' + key}
                    className={this.QsNumStyling(data)}
                    align='center'
                    valign='middle'
                    onClick={() => this.SelectQuestion(data)}
                // style={this.QsNumStyling(data)}
                // disabled={this.QsNoDisabledByQuizType(data)}
                >{data.No}</td>);

                if (key > 0) {
                    if (data.No % _maxColPerRow === 0) {
                        //items per row.
                        _components.push(<tr key={'qsList' + key}>{_columns}</tr>);
                        _columns = [];
                    }
                    else if (key === this.state.QuestionList.length - 1 && _columns.length < _maxColPerRow) {
                        //put remaining items in a row if not enough _maxColPerRow.
                        _components.push(<tr key={'qsList' + key}>{_columns}</tr>);
                        _columns = [];
                    }
                }
                else {
                    if (key === this.state.QuestionList.length - 1 && _columns.length < _maxColPerRow) {
                        //put remaining items in a row if not enough _maxColPerRow.
                        _components.push(<tr key={'qsList' + key}>{_columns}</tr>);
                        _columns = [];
                    }
                }
                return null;
            });
        }
        // console.log(JSON.stringify(_components));
        return (_components);
    }
    QsNumStyling = (data) => {
        if (data !== null) {
            let disabled = this.QsNoDisabledByQuizType(data);
            if (disabled) {
                //disabled.
                return 'qListItem-disabled';
            }
            else {
                let selectedQuestion = this.state.QuestionList[this.state.SelectedQuestionIndex];
                if (selectedQuestion === undefined)
                    selectedQuestion = this.state.SelectedQuestion;

                if (selectedQuestion !== null) {
                    if (selectedQuestion.No === data.No) {
                        //selected.
                        return 'qListItem-selected';
                    }
                    else {
                        //not selected.
                        return 'qListItem-normal';
                    }
                }
                else {
                    //null.
                    return 'qListItem-normal';
                }
            }
        }
    }
    QsNoDisabledByQuizType = (data) => {
        if (data.hasOwnProperty('SpecialMode')) {
            if (data.QuizType === 'FillInTheBlanks') {
                let _setting = String(data.SpecialMode).split(';');
                if (_setting[0] === data.QuizType) {
                    let _range = _setting[1].split(',');    //range 0 == start No.
                    if (_range[0] !== String(data.No))
                        return true;
                }
            }
        }
        return false;
    }
    SelectQuestion = (data = null) => {
        if (data === null) {
            //2021.10.25
            this.setState({
                ToggleEditQuestionUi: false,
                TempCachedSelectedQuestion: null,
                Toggle_ConfirmSwitchQuestion: false,
                ConfirmSwitchQuestion: false,
            });
        }
        else {
            let disabled = this.QsNoDisabledByQuizType(data);
            if (disabled === false) {
                // if (this.state.ToggleEditQuestionUi)
                //     this.EditQuestionObjective_Ref.current.ResetForm();

                //2021.10.04
                //Prompt to alert Author if question is edited & needs to be save or not.
                // if (this.state.PreviousSelectedQuestionIndex > -1) {
                if (this.EditQuestionObjective_Ref.current !== null) {
                    ConsoleLog('AllowToSaveChanges = ' + String(this.EditQuestionObjective_Ref.current.state.AllowToSaveChanges));
                    if (this.EditQuestionObjective_Ref.current.state.AllowToSaveChanges) {
                        if (this.state.ConfirmSwitchQuestion === false) {
                            this.setState({
                                TempCachedSelectedQuestion: data,
                                Toggle_ConfirmSwitchQuestion: true,
                            });
                            return null;
                        }
                    }
                }

                this.setState({
                    ToggleEditQuestionUi: false,

                    //2021.10.04
                    TempCachedSelectedQuestion: null,
                    Toggle_ConfirmSwitchQuestion: false,
                    ConfirmSwitchQuestion: false,
                }, () => {
                    // let index = this.state.SelectedQsIndexForRemoval > -1 ? Number(data.No) : (Number(data.No) - 1);
                    let index = CheckObjectNumber(data, 'No') - 1;
                    let uniqueId = String(data.UniqueId);

                    ConsoleLog('curr idx = ' + index);
                    ConsoleLog('prev idx = ' + this.state.PreviousSelectedQuestionIndex);

                    let _questionList = this.state.QuestionList;
                    let _cachedQuestionList = this.state.CachedQuestionList;
                    if (JSON.stringify(_questionList) !== JSON.stringify(_cachedQuestionList)) {
                        _questionList = JSON.parse(JSON.stringify(_cachedQuestionList));
                    }
                    if (this.state.PreviousSelectedQuestionIndex !== index) {
                        this.setState({
                            PreviousSelectedQuestionUniqueId: uniqueId,
                            PreviousSelectedQuestionIndex: index,
                            QuestionList: _questionList,
                            CachedQuestionList: _cachedQuestionList,

                            SelectedQuestion: data,
                            SelectedQuestionIndex: index,
                            ToggleEditQuestionUi: true,
                        }, () => {
                            ScrollToElement('EditQs');
                            // ScrollToElement('QsList');
                            ConsoleLog('Question selected (1) : ' + data.No);
                            useAppService.getState().updateQuestionNoList(this.state.QuestionList, index);
                        });
                    }
                    else {
                        this.setState({
                            SelectedQuestion: data,
                            SelectedQuestionIndex: -1,
                            ToggleEditQuestionUi: true,
                        }, () => {
                            ScrollToElement('EditQs');
                            // ScrollToElement('QsList');
                            ConsoleLog('Question selected (2) : ' + data.No);
                            useAppService.getState().updateQuestionNoList(this.state.QuestionList, index);
                        });
                    }
                });
            }
        }
    }
    //2021.10.04
    ConfirmSwitchQuestion_ModalComponent = () => {
        return (
            <Modal show={this.state.Toggle_ConfirmSwitchQuestion}
                onHide={() => this.setState({ Toggle_ConfirmSwitchQuestion: !this.state.Toggle_ConfirmSwitchQuestion, ConfirmSwitchQuestion: false, })}
                centered>
                <Modal.Header>
                    <Modal.Title>Switch Question ?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Your question is not yet save, any changes will not be updated if switch to view next question, confirm & proceed to view next question ?</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => this.setState({ Toggle_ConfirmSwitchQuestion: !this.state.Toggle_ConfirmSwitchQuestion, ConfirmSwitchQuestion: false, })}
                    >Close</Button>
                    &nbsp;<Button
                        variant="primary"
                        onClick={() => this.setState({ ConfirmSwitchQuestion: true, Toggle_ConfirmSwitchQuestion: false, }, () => this.SelectQuestion(this.state.TempCachedSelectedQuestion))}
                    >Confirm</Button>
                </Modal.Footer>
            </Modal >
        );
    }
    UpdateQuestionListArrayItem = async (_question) => {
        if (this.state.QuestionList.length > 0) {
            let index = this.state.QuestionList.findIndex(x => x.UniqueId === this.state.SelectedQuestion.UniqueId);
            if (index > -1) {
                let qsList = this.state.QuestionList;
                qsList[index] = _question;
                this.setState({
                    QuestionList: JSON.parse(JSON.stringify(qsList)),
                    SelectedQuestion: JSON.parse(JSON.stringify(_question)),
                }, () => {
                    // console.log('============================= seperator =============================');
                    // console.log(JSON.stringify(_question));
                    // console.log(JSON.stringify(this.state.SelectedQuestion));
                    // console.log(JSON.stringify(this.state.QuestionList));
                });
            }
        }
    }
    UpdateComprehensionPassageArray = (_passages) => {
        let _array = this.state.ComprehensionPassage;
        if (_array !== _passages) {
            this.setState({
                ComprehensionPassage: _passages,
            });
        }
    }
    EditQuestionUiComponent = () => {
        if (this.state.QuestionList.length <= 0)
            return null;

        let selectedQuestion = this.state.QuestionList[this.state.SelectedQuestionIndex];
        // console.log(this.state.SelectedQuestionIndex + '\n\n' + JSON.stringify(selectedQuestion));

        if (selectedQuestion === undefined || selectedQuestion === null)
            selectedQuestion = this.state.SelectedQuestion;

        if (selectedQuestion !== undefined && selectedQuestion !== null) {
            switch (selectedQuestion.QuizType) {
                // case 'Comprehension' || 'Objective':
                default:
                    return (<EditQuestionObjective
                        // key={'Obj_' + this.state.SelectedQuestion.No}

                        ref={this.EditQuestionObjective_Ref}

                        user={this.state.User}
                        // isSuperAdmin={this.props.isSuperAdmin}
                        // isAuthor={this.props.isAuthor}
                        isDevMode={this.state.isDevMode}

                        // Locale={this.props.Locale}
                        // SetLocaleSetting={this.props.SetLocaleSetting}

                        firestore={firestore}     //Everything
                        // firebase={this.props.firebase}       //Login, path, etc.
                        dbQuizBank={dbQuizBank}   //Quiz Bank

                        // Feedback={this.props.Feedback}
                        // SetLoading={useAppService.getState().setLoading}
                        // SetAlert={useAppService.getState().setModal}
                        // SetAlertConfirm={useAppService.getState().setModalConfirm}
                        // SetAlertWithProgressBar={useAppService.getState().setModalWithProgressBar}
                        // CloseAlert={this.props.CloseAlert}

                        QuestionSetUniqueId={this.state.QuestionSetUniqueId}
                        QuestionModal={selectedQuestion}
                        QuestionList={this.state.QuestionList}  //2021.12.17
                        // QsDataInput={QsDataInput}   //shared among question components.
                        AvailableTags={this.state.AvailableTags}

                        ComprehensionPassage={this.state.ComprehensionPassage}
                        UpdateComprehensionPassageArray={this.UpdateComprehensionPassageArray}
                        UpdateQuestionListArrayItem={this.UpdateQuestionListArrayItem}

                        Callback_ReloadQuestionList={this.Callback_ReloadQuestionList}  //2021.09.23

                    // credential={this.props.credential}  //aka Firebase Login Credential     //2021.10.23

                    // OrganizerInfo={this.props.OrganizerInfo}
                    // OrganizerInfoIsLoaded={this.props.OrganizerInfoIsLoaded}
                    // Organizers={this.props.Organizers}
                    />);
                // case 'FillInTheBlanks':
                //     return (<>
                //         <span>{JSON.stringify(this.state.SelectedQuestion)}</span>
                //     </>);
                case 'Subjective':
                    return (<span>{JSON.stringify(selectedQuestion)}</span>);
            }
        }
        else {
            return (<></>);
        }
    }
    SlideInOut_QuestionList = (slideOut = false) => {
        if (slideOut) {
            const ele_hover = document.getElementById('floating-div-qs-list-hover');
            const ele_show = document.getElementById('floating-div-qs-list-show');
            if (ele_hover !== null)
                ele_hover.translate(ele_hover.style.right - 500, ele_hover.style.y);
            if (ele_show !== null)
                ele_show.translate(ele_show.style.right + 10, ele_show.style.y);
        }
        else {
            const ele_hover = document.getElementById('floating-div-qs-list-hover');
            const ele_show = document.getElementById('floating-div-qs-list-show');
            if (ele_hover !== null)
                ele_hover.translate(ele_hover.style.right + 10, ele_hover.style.y);
            if (ele_show !== null)
                ele_show.translate(ele_show.style.right - 500, ele_show.style.y);
        }
    }
    QuestionListUiRedirect_Component = () => {
        return (<>
            <div className="floating-div-qs-list-hover" onMouseOver={() => this.SlideInOut_QuestionList()}>
                <div className="hover-title">Question List ({this.state.QuestionList.length})</div>
            </div>
            <div className="floating-div-qs-list-show" onMouseLeave={() => this.SlideInOut_QuestionList(true)}>
                <div className="content">
                    <table id='LP_QsList'>
                        <thead>
                            <tr>
                                <th colSpan='20' style={{ height: 30, }}>
                                    <span style={{ fontSize: 14, color: 'blue', }}>Question List</span>
                                    <button
                                        className='btn-link'
                                        type='button'
                                        style={{ width: 40 }}
                                        onClick={() => this.setState({ ToggleQuestionList: !this.state.ToggleQuestionList })}
                                    >{this.state.ToggleQuestionList ? 'hide' : 'show'}</button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.ToggleQuestionList ? this.QuestionListUiComponent(5) : null}
                        </tbody>
                    </table>
                </div>
            </div >
        </>);
    }
    //#endregion


    //2021.09.13
    //#region === Add or Remove Question ===
    AddOrRemoveQuestion_ModalComponent = () => {
        if (this.state.Toggle_AddOrRemoveQuestionUi) {
            const questionList = CheckNullValue(this.state.QuestionList, []);
            let modalBodyComponent = () => {
                switch (this.state.AddOrRemoveQuestion_State) {
                    default:
                        return <>
                            <DropdownButton
                                id='question-selection-dropdown-button'
                                title={
                                    this.state.SelectedQsIndexForRemoval > -1 &&
                                        Array.isArray(questionList) &&
                                        questionList.length > 0 &&
                                        questionList.length - 1 >= this.state.SelectedQsIndexForRemoval &&
                                        CheckNullValue(questionList[this.state.SelectedQsIndexForRemoval]) !== null ?
                                        'Question #' + CheckObjectStringEmpty(questionList[this.state.SelectedQsIndexForRemoval], 'No', '- Select Question -')
                                        : '- Select Question -'
                                }
                                drop='down'
                                onSelect={(option) => this.setState({ SelectedQsIndexForRemoval: Number(option) - 1 }, () => {
                                    ConsoleLog(JSON.stringify(option));
                                })}
                            >
                                <Dropdown.Item disabled={true}>- Select a Question number -</Dropdown.Item>
                                {
                                    questionList.map((data, key) => {
                                        return <Dropdown.Item as="button" eventKey={CheckObjectStringEmpty(data, 'No')} value={key + 1}
                                            hidden={CheckObjectStringEmpty(data, 'QuizType') !== 'Objective'}
                                        >{CheckObjectStringEmpty(data, 'No', '-')}</Dropdown.Item>;
                                    })
                                }
                            </DropdownButton>
                        </>;
                    case 1:
                        return <ProgressBar animated now={100} className='progressbar1' />;
                    case 2:
                        return <>success</>;
                    case 3:
                        return <>failed</>;
                    case 4:
                        return <>Proceed to add a question ?</>;
                }
            }
            let getTitle = () => {
                switch (this.state.AddOrRemoveQuestion_State) {
                    default: return 'Select a question for removal';
                    case 1: return 'Processing...';
                    case 2: return 'Update is done';
                    case 3: return 'Failed';
                    case 4: return 'Add a question ?';
                }
            }
            return (
                <Modal show={this.state.Toggle_AddOrRemoveQuestionUi}
                    onHide={
                        this.state.AddOrRemoveQuestion_State !== 1
                            ? this.ToggleAddOrRemoveQuestionUi : this.DoNothing
                    }
                // centered
                >
                    <Modal.Header
                        closeButton={
                            this.state.AddOrRemoveQuestion_State === 0
                            || this.state.AddOrRemoveQuestion_State === 3
                        }
                    >
                        <Modal.Title>{getTitle()}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center' }}>
                        {modalBodyComponent()}
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Button
                        variant="secondary"
                        onClick={this.ToggleAddOrRemoveQuestionUi}
                        hidden={this.state.AddOrRemoveQuestion_State === 0
                            || this.state.AddOrRemoveQuestion_State === 3
                            || this.state.AddOrRemoveQuestion_State === 4
                            ? false : true}
                    >Close</Button> */}
                        <Button
                            variant="secondary"
                            onClick={() => this.ToggleAddOrRemoveQuestionUi()}
                        >Close</Button>
                        {
                            this.state.AddOrRemoveQuestion_State === 4 ?
                                <>
                                    &nbsp;<Button
                                        variant="primary"
                                        onClick={() => this.AddSingleQuestion()}
                                    >Confirm</Button>
                                </>
                                : null
                        }
                        {
                            this.state.AddOrRemoveQuestion_State === 0 ?
                                <>
                                    &nbsp;<Button
                                        variant="primary"
                                        // onClick={this.RemoveQuestionAndSaveSettings}
                                        onClick={() => this.ToggleConfirmRemoveQuestionUi()}    //2021.09.29
                                    >Remove</Button>
                                </>
                                : null
                        }
                    </Modal.Footer>
                </Modal>
            );
        }
        return null;
    }
    ToggleAddQuestion = () => {
        this.setState({
            AddOrRemoveQuestion_State: 4,
        }, () => {
            this.ToggleAddOrRemoveQuestionUi();
        });
    }
    AddSingleQuestion = (sync = true) => {
        let qSet = this.state.QuestionSetDetail;
        if (qSet !== undefined) {
            qSet.TotalQuestion += 1;
            this.setState({
                QuestionSetDetail: qSet,
                // SelectedQuestion: null,
                SelectedQuestionIndex: qSet.TotalQuestion - 1,
            }, () => {
                if (sync) {
                    this.ToggleAddOrRemoveQuestionUi();
                    this.SaveQuestionSetSettings();
                }
            });
        }
    }
    ToggleAddOrRemoveQuestionUi = () => {
        this.setState({
            Toggle_AddOrRemoveQuestionUi: !this.state.Toggle_AddOrRemoveQuestionUi,
        }, () => {
            if (this.state.Toggle_AddOrRemoveQuestionUi) {
                this.setState({
                    SelectedQuestionIndex: -1,
                    SelectedQsIndexForRemoval: -1,
                });
            }
        });
    }
    // ClearQuestionContent = async () => {
    //     //
    // }
    ToggleRemoveQuestion = () => {
        this.setState({
            AddOrRemoveQuestion_State: 0,   //aka default.
            SelectedQsIndexForRemoval: -1,
        }, () => {
            this.ToggleAddOrRemoveQuestionUi();
        });
    }
    //2021.09.29
    ToggleConfirmRemoveQuestionUi = () => {
        this.setState({ Toggle_ConfirmRemoveQuestionUi: !this.state.Toggle_ConfirmRemoveQuestionUi });
    }
    //2021.09.29
    ConfirmRemoveQuestion_ModalComponent = () => {
        return (
            <Modal show={this.state.Toggle_ConfirmRemoveQuestionUi}
                onHide={this.ToggleConfirmRemoveQuestionUi}
                centered>
                <Modal.Header>
                    <Modal.Title>Removing Question<b><i>{
                        this.state.QuestionList === undefined ?
                            '' : this.state.SelectedQsIndexForRemoval === -1 ?
                                '' : this.state.QuestionList[this.state.SelectedQsIndexForRemoval] === undefined ?
                                    '' : ' #' + this.state.QuestionList[this.state.SelectedQsIndexForRemoval].No
                    }</i></b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Are you sure you want to remove this question ?<br /><i><b>Question removal is not reversible.</b><br /><br />User is adviced to edit this question if necessary rather than removing it completely from the question set.</i></span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => this.ToggleConfirmRemoveQuestionUi()}
                    >Close</Button>
                    &nbsp;<Button
                        variant="primary"
                        onClick={() => this.RemoveQuestionAndSaveSettings()}
                    // onClick={this.DoNothing}
                    >Confirm</Button>
                </Modal.Footer>
            </Modal>
        );
    }
    RemoveQuestionAndSaveSettings = () => {
        if (this.state.SelectedQsIndexForRemoval > -1) {
            let qSet = this.state.QuestionSetDetail;
            if (CheckNullValue(qSet) !== null) {
                qSet.TotalQuestion -= 1;
                this.setState({
                    QuestionSetDetail: qSet,
                }, () => {
                    this.ToggleAddOrRemoveQuestionUi();
                    this.ToggleConfirmRemoveQuestionUi();
                    // this.SaveQuestionSetSettings();
                    this.RemoveQuestion_ViaApi();   //2023.11.27
                });
            }
        }
        // if (_index > -1) {
        //     let qSet = this.state.QuestionSetDetail;
        //     if (qSet !== undefined) {
        //         qSet.TotalQuestion -= 1;
        //         this.setState({
        //             SelectedQsIndexForRemoval: _index,
        //             QuestionSetDetail: qSet,
        //         }, () => {
        //             this.SaveQuestionSetSettings();
        //         });
        //     }
        // }
    }
    //2021.09.23
    Callback_ReloadQuestionList = async (qsNo = '') => {
        ConsoleLog('Callback_ReloadQuestionList = ' + qsNo);

        await this.LoadQuestionList(qsNo, true);
        // await Delay(0);
        this.ReSelectQuestion(qsNo);
        // setTimeout(() => {
        //     this.ReSelectQuestion();
        // }, 200);
        useAppService.getState().setModal();
    }
    //2023.11.27
    RemoveQuestion_ViaApi = async () => {

        const user = useGlobal.getState().user;
        if (user === null)
            return null;

        if (this.state.SelectedQsIndexForRemoval < 0)
            return null;

        this.setState({
            IsSavingSettingsViaAPI: true,
            IsUpdateSuccess: null,
        });
        useAppService.getState().setModal('', 'removing question...', null, AlertMode.Loading);

        const question = this.state.QuestionList[this.state.SelectedQsIndexForRemoval];
        let qSet = this.state.QuestionSetDetail;

        let success = false;
        let errorMessage = '';

        const { authorId, organizerId } = GetPropIds(user);
        // if (this.state.isDevMode)
        //     console.log('RemoveQuestion_ViaApi', authorId, organizerId);

        let jsonModel = {
            ...question,
            OrganizerId: organizerId,
            AuthorId: authorId,
            QSetUniqueId: CheckObjectStringEmpty(this.state.QuestionSetDetail, 'UniqueId'),
            QuestionSetModel: qSet,
        };
        ConsoleLog('question delete (json) = ' + JSON.stringify(jsonModel));

        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/QuizBank/Question/Remove',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(jsonModel),
            })
            .then(res => res.json())
            .then((data) => {
                ConsoleLog('question delete (done) = ' + JSON.stringify(data));

                success = data.success;
                if (success === false)
                    errorMessage = data.message;
            })
            .catch(error => {
                errorMessage = error;
                ConsoleLog('question delete (failed) | ' + error);
            });

        if (success) {
            let _qsList = this.state.QuestionList;
            let remove_idx = _qsList.findIndex(x => x.UniqueId === question.UniqueId);
            if (remove_idx > -1) {
                _qsList.splice(remove_idx, 1);
                _qsList.sort((x, y) => x.No - y.No);
                _qsList.map((data, key) => { return data.No = (key + 1); });
                this.setState({
                    QuestionList: _qsList,
                }, () => {
                    ConsoleLog('_qsList (updated) =\n' + JSON.stringify(_qsList));
                });
            }
            qSet.TotalQuestion -= 1;

            this.setState({
                QuestionSetDetail: qSet,
                CachedQuestionSetDetail: JSON.parse(JSON.stringify(qSet)),
                IsSavingSettingsViaAPI: false,
                IsUpdateSuccess: true,
            }, async () => {
                this.CheckOnSaveSettingsConditions();
                this.PopulateFormUiData();
                await Delay(500);
                this.Callback_ReloadQuestionList(
                    this.state.PreviousSelectedQuestionIndex < 0 ? this.state.QuestionList.length
                        : CheckNullValue(this.state.QuestionList[this.state.PreviousSelectedQuestionIndex]) === null ? this.state.QuestionList.length
                            : Number(this.state.QuestionList[this.state.PreviousSelectedQuestionIndex].No) === this.state.QuestionList.length ?
                                this.state.QuestionList.length
                                : this.state.QuestionList[this.state.PreviousSelectedQuestionIndex].No
                );
                await Delay(500);
                if (this.EditQuestionObjective_Ref.current !== null)
                    this.EditQuestionObjective_Ref.current.InitState();
            });
        }

        if (errorMessage.length > 0)
            useAppService.getState().setModal('Error', 'Unable to remove current question.');
        else
            useAppService.getState().setModal('', 'Selected question has been removed.');
    }
    //#endregion


    //2021.12.20
    //#region //=== DownloadQuestionSetInXLSX === start ===//
    DownloadQuestionSetInXLSX = async () => {

        if (this.state.QuestionList === null)
            return null;

        if (this.state.QuestionList.length <= 0)
            return null;

        if (this.state.IsQuestionSetTablePopulationDone === false)
            await this.PopulateQuestionSetTableForDownload();

        // return (<ReactHTMLTableToExcel
        //     id="download-excel-button"
        //     className="btn btn-primary"
        //     table="table-to-xls"
        //     filename={this.state.hiddenQuestionSetTableForXLS_fileName}
        //     sheet="Questions"
        //     buttonText="Download List (XLS)"
        //     autoDownload={true}
        // />);

        //2021.12.21    //=== new = using object method ===//
        var opts = [{ sheetid: 'Questions', header: true }, { sheetid: 'Comprehension', header: false }, { sheetid: 'FillInTheBlanks', header: false }];
        alasql('SELECT INTO XLSX("' + this.state.hiddenQuestionSetTableForXLS_fileName + '.xlsx",?) FROM ?',
            [
                opts, [
                    this.state.hiddenQuestionSetTableForXLS,
                    this.state.hiddenQuestionSetTableForXLS_comp.length > 0 ? this.state.hiddenQuestionSetTableForXLS_comp : [{ Id: '', Start: '', End: '', Passage: '' }],
                    this.state.hiddenQuestionSetTableForXLS_fitb.length > 0 ? this.state.hiddenQuestionSetTableForXLS_fitb : [{ Id: '', Start: '', End: '' }]
                ]
            ]);
    }
    PopulateQuestionSetTableForDownload = async () => {

        //#region       //=== using htmlProps method ===//
        // let htmlProps = '<table id="table-to-xls" hidden><tbody>';
        // htmlProps += '<tr>';
        // htmlProps += '<th>Id</th><th>Content</th><th>Tags</th><th>Hints</th><th>QuizType</th><th>Answer</th>';
        // AtoZ.map((data, key) => {
        //     return htmlProps += '<th>' + data + '</th>';
        // });
        // htmlProps += '</tr>';
        // if (this.state.QuestionList.length > 0) {
        //     this.state.QuestionList.map((data, key) => {
        //         htmlProps += '<tr height="15px">';
        //         htmlProps += '<td>' + (key + 1) + '</td>';
        //         htmlProps += '<td>' + String(data.Content) + '</td>';
        //         htmlProps += '<td>' + String(data.Tags) + '</td>';
        //         htmlProps += '<td>' + String(data.Hints) + '</td>';
        //         htmlProps += '<td>' + String(data.QuizType) + '</td>';
        //         htmlProps += '<td>' + String(data.Answer) + '</td>';
        //         AtoZ.map((alphabet, key) => {
        //             return htmlProps += '<td>' + String(data[alphabet]) + '</td>';
        //         });
        //         htmlProps += '</tr>';
        //         return null;
        //     });
        // }
        // htmlProps += '</tbody></table>';

        // //Comprehension sheet
        // let htmlProps_comp = '<table id="table-to-xls-comp" hidden><tbody>';
        // htmlProps_comp += '<tr><th>Id</th><th>Start</th><th>End</th><th>Passage</th></tr>';
        // if (this.state.ComprehensionPassage.length > 0) {
        //     let _passages = this.state.ComprehensionPassage;
        //     for (var i = 0; i < this.state.QuestionList.length; i++) {
        //         if (String(this.state.QuestionList[i].QuizType) === 'Comprehension') {
        //             if (this.state.QuestionList[i].hasOwnProperty('SpecialMode')) {
        //                 let _mode = String(this.state.QuestionList[i].SpecialMode).split(';');
        //                 if (_mode[0] === 'Comprehension') {
        //                     let _findIndex = Object.keys(_passages).indexOf(_mode[3]);
        //                     if (_findIndex > -1) {
        //                         htmlProps_comp += '<tr height="15px">';
        //                         htmlProps_comp += '<td>' + String(_passages[_findIndex].Id) + '</td>';
        //                         htmlProps_comp += '<td>' + String(_passages[_findIndex].Start) + '</td>';
        //                         htmlProps_comp += '<td>' + String(_passages[_findIndex].End) + '</td>';
        //                         htmlProps_comp += '<td>' + String(_passages[_findIndex].Passage) + '</td>';
        //                         htmlProps_comp += '</tr>';
        //                         break;
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
        // htmlProps_comp += '</tbody></table>';

        // //FillInTheBlanks sheet
        // let htmlProps_fitb = '<table id="table-to-xls-fitb" hidden><tbody>';
        // htmlProps_fitb += '<tr><th>Id</th><th>Start</th><th>End</th></tr>';
        // //blank atm coz hvnt think good good how to apply this quiz type.
        // if (this.state.FillInTheBlanks.length > 0) {
        //     let _items = this.state.FillInTheBlanks;
        //     for (var i = 0; i < this.state.QuestionList.length; i++) {
        //         if (String(this.state.QuestionList[i].QuizType) === 'FillInTheBlanks') {
        //             if (this.state.QuestionList[i].hasOwnProperty('SpecialMode')) {
        //                 let _mode = String(this.state.QuestionList[i].SpecialMode).split(';');
        //                 if (_mode[0] === 'FillInTheBlanks') {
        //                     let _findIndex = Object.keys(_items).indexOf(_mode[3]);
        //                     if (_findIndex > -1) {
        //                         htmlProps_fitb += '<tr height="15px">';
        //                         htmlProps_fitb += '<td>' + String(_items[_findIndex].Id) + '</td>';
        //                         htmlProps_fitb += '<td>' + String(_items[_findIndex].Start) + '</td>';
        //                         htmlProps_fitb += '<td>' + String(_items[_findIndex].End) + '</td>';
        //                         htmlProps_fitb += '</tr>';
        //                         break;
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
        // htmlProps_fitb += '</tbody></table>';

        // this.setState({
        //     hiddenQuestionSetTableForXLS: htmlProps,
        //     hiddenQuestionSetTableForXLS_comp: htmlProps_comp,
        //     hiddenQuestionSetTableForXLS_fitb: htmlProps_fitb,
        //     hiddenQuestionSetTableForXLS_fileName: 'QuestionSet_'
        //         + String(this.state.QuestionSetDetail['Name']).replaceAll(/[$-/:-?{-~!"^_`\[\]\@\#\s]/g, '-')
        //         + '_' + moment.utc().local().format('YYYY-MM-DD_HHmmss'),
        //     IsQuestionSetTablePopulationDone: true,
        // });
        // await Delay(500);
        //#endregion    //=== using htmlProps method ===//


        //2021.12.21    //=== new = using object method ===//
        let questions = [];
        let comps = [];
        let fitbs = [];
        this.state.QuestionList.map((data, key) => {
            if (data.QuizType === 'Objective' || data.QuizType === 'Comprehension') {
                questions.push({
                    Id: CheckObjectNumber(data, 'No'),
                    Content: CheckObjectStringEmpty(data, 'Content'),
                    Tags: CheckObjectStringEmpty(data, 'Tags'),
                    Hints: CheckObjectStringEmpty(data, 'Hints'),
                    QuizType: CheckObjectStringEmpty(data, 'QuizType', 'Objective'),
                    Answer: CheckObjectStringEmpty(data, 'Answer'),
                });
                const qIndex = questions.length - 1;
                AtoZ.map((alphabet, key) => {
                    return questions[qIndex][alphabet] = '';
                });
                let qsAnsOptions = String(data.Selection).split(';');
                qsAnsOptions = qsAnsOptions.slice(0, qsAnsOptions.length - 1);
                qsAnsOptions.map((data, key) => {
                    const ans = data.split(':');
                    return questions[qIndex][String(ans[0])] = String(ans[1]);
                });
                questions[qIndex]['PictureUrl'] = CheckObjectStringEmpty(data, 'PictureUrl');
                questions[qIndex]['ContentPictures'] = data.hasOwnProperty('ContentPictures') ? Array(data.ContentPictures).join(',') : [];
                questions[qIndex]['UniqueId'] = CheckObjectStringEmpty(data, 'UniqueId');
            }
            if (data.QuizType === 'Comprehension') {
                if (data.hasOwnProperty('SpecialMode')) {
                    let _mode = String(data.SpecialMode).split(';');
                    if (_mode[0] === 'Comprehension') {
                        let _passages = this.state.ComprehensionPassage;
                        let _findIndex = Object.keys(_passages).indexOf(_mode[3]);  //e.g. _mode[3] = Content_36_40
                        if (_findIndex > -1) {
                            comps.push({
                                Id: String(_passages[_findIndex].Id),
                                Start: String(_passages[_findIndex].Start),
                                End: String(_passages[_findIndex].End),
                                Passage: String(_passages[_findIndex].Passage),
                            });
                        }
                    }
                }
            }
            else if (data.QuizType === 'FillInTheBlanks') {
                //
            }
            return null;
        });

        this.setState({
            hiddenQuestionSetTableForXLS: questions,
            hiddenQuestionSetTableForXLS_comp: comps,
            hiddenQuestionSetTableForXLS_fitb: fitbs,
            hiddenQuestionSetTableForXLS_fileName: 'QuestionSet_'
                + String(this.state.QuestionSetDetail['Name']).replaceAll(/[$-/:-?{-~!"^_`\[\]\@\#\s]/g, '-')
                + '_' + moment.utc().local().format('YYYY-MM-DD_HHmmss'),
            IsQuestionSetTablePopulationDone: true,
        });
    }
    //#endregion //=== DownloadQuestionSetInXLSX === end ===//


    //2022.06.10
    //#region //=== Delete Question Set ===//
    ConfirmRemoveQuestionSet_ModalComponent = () => {
        return (
            <Modal show={this.state.Show_Delete_QuestionSetModal} onHide={this.ToggleDeleteQuestionSetModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Question Set</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        Question Set:<br />&nbsp;&nbsp;&nbsp;&nbsp;<b>{this.GetDataValue(DataInput.Name)}</b><br />
                        <br />Are you sure you want to <b>delete</b> this question set ?
                        <br />The deletion is not reversible.
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleDeleteQuestionSetModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="primary" onClick={() => this.DeleteThisQuestionSet_ViaAPI()}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        );
    }
    DeleteThisQuestionSet_ViaAPI = async () => {
        // useAppService.getState().setModal('', '<b>Delete Question Set currently not available.</b>', false);

        const user = useGlobal.getState().user;
        if (user === null)
            return null;

        // let success = false;
        this.setState({
            IsSavingSettingsViaAPI: true,
        });
        useAppService.getState().setModal('', 'deleting question set...', null, AlertMode.Loading);

        const { centerUserId, authorId, authorRoleId, organizerId } = GetPropIds(user);
        ConsoleLog(`DeleteThisQuestionSet_ViaAPI ${centerUserId}, ${authorId}, ${authorRoleId}, ${organizerId}`);

        // Api/LearningCentre/QuizBank/QuestionSet/Delete/{centerUserId}/{authorId}/{qSetUid}
        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/QuizBank/QuestionSet/Delete/'
            + centerUserId + '/'
            + authorId + '/'
            + this.state.QuestionSetUniqueId;

        ConsoleLog('DeleteThisQuestionSet_ViaAPI\n' + url);

        let done = false;
        let success = false;
        let errorMessage = '';

        await fetch(url,
            {
                method: 'DELETE',                             // GET, POST, PUT, DELETE, etc.
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then((data) => {
                ConsoleLog('qset delete (done) = ' + JSON.stringify(data));

                success = data.success;
                done = true;

                if (success === false)
                    errorMessage = data.message;

                // if (CheckBoolean(data.success)) {
                //     useAppService.getState().setModal('', 'Question Set has been deleted.', false);
                //     await Delay(2000);
                //     this.BackToList();
                // }
            })
            .catch(error => {
                errorMessage = error;
                ConsoleLog('qset delete (failed) | ' + error);
                // useAppService.getState().setModal('', 'Failed to delete current question set.<br />Please try again.');
                // this.setState({
                //     IsSavingSettingsViaAPI: false,
                // });
                done = true;
            });
        await DelayUntil(() => done === true);

        //2022.11.25
        if (success) {
            useAppService.getState().setModal('', 'Question Set has been deleted.');
            await Delay(2000);
            // this.BackToList();
            this.setState({ redirectLink: Menu['questionSet'].Link, redirect: true, });
        }
        else {
            useAppService.getState().setModal('Delete Failed', 'Failed to delete current question set.<br />Please try again.' +
                (errorMessage === '' ? '' : '<br /><br />Error:<br />' + errorMessage));
        }
        this.setState({
            IsSavingSettingsViaAPI: false,
        });
        // this.setState({
        //     IsSavingSettingsViaAPI: false,
        // }, () => {
        //     if (success) {
        //         useAppService.getState().setModal('', 'Question Set has been deleted.');
        //         // this.BackToList();
        //     }
        // });
    }
    ToggleDeleteQuestionSetModal = () => {
        this.setState({ Show_Delete_QuestionSetModal: !this.state.Show_Delete_QuestionSetModal });
    }
    //#endregion

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<div className="">
            <table className="table page-header">
                <tbody>
                    <tr>
                        <td className="left"><h5>Edit Question Set</h5></td>
                        <td className="center"></td>
                        <td className="right">
                            <Button
                                variant='outline-primary'
                                onClick={() => this.setState({ redirectLink: getMenuLink(LayoutScreen.ManageQuestionSet), redirect: true, })}
                            >Back to List</Button>
                        </td>
                    </tr>
                </tbody>
            </table>
            {
                this.state.IsDetailLoadingDone === false ? null :
                    <div className='page-content'>
                        <div className='rowStyle' style={{ padding: 10, border: '1px solid blue', borderRadius: 10, display: 'grid', justifyContent: 'center' }}>
                            <table cellPadding='5' border='0' style={{ width: '96vw' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '15%', textAlign: 'right' }}>Name *</td>
                                        <td style={{ width: '50%' }}>
                                            <input
                                                ref={this.EditQSet_Name}
                                                type="text"
                                                style={{ width: '100%' }}
                                                placeholder={this.GetDataValue(DataInput.Name)}
                                                onChange={(e) => this.SaveDataInput(e.target.value, DataInput.Name)}
                                            />
                                        </td>
                                        <td style={{ width: '35%', verticalAlign: 'top', paddingRight: '30px' }} rowSpan='2'>
                                            <table cellPadding='5' width='100%' border='0'>
                                                <tbody>
                                                    <tr hidden>
                                                        <td style={{ width: '50%', textAlign: 'right' }}>Is Public</td>
                                                        <td style={{ width: '25%' }}>
                                                            <input
                                                                ref={this.EditQSet_IsPublic}
                                                                type="checkbox"
                                                                checked={this.GetDataValue(DataInput.IsPublic)}
                                                                onChange={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.IsPublic)}
                                                            />&nbsp;
                                                            <OverlayTrigger overlay={<Tooltip><div style={{ textAlign: 'left' }}>Tick this checkbox to show this Question Set to the public or hide it from all, only author or admin(s) who belongs to the same Center are allow to edit.<p style={{ textAlign: 'right' }}>(default: uncheck)</p></div></Tooltip>}>
                                                                <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                    <tr hidden>
                                                        <td style={{ width: '50%', textAlign: 'right' }}>Is Private Group</td>
                                                        <td style={{ width: '25%' }}>
                                                            <input
                                                                ref={this.EditQSet_IsPrivateGroup}
                                                                type="checkbox"
                                                                checked={this.GetDataValue(DataInput.IsPrivateGroup)}
                                                                onChange={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.IsPrivateGroup)}
                                                                disabled={true}
                                                            />&nbsp;
                                                            <OverlayTrigger overlay={<Tooltip><div style={{ textAlign: 'left' }}>Tick this checkbox to continue to customize private group in next step.<p style={{ textAlign: 'right' }}>(default: uncheck)</p></div></Tooltip>}>
                                                                <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                    <tr hidden>
                                                        <td style={{ width: '50%', textAlign: 'right' }}>Published</td>
                                                        <td style={{ width: '25%', }}>
                                                            <input
                                                                ref={this.EditQSet_Published}
                                                                type="checkbox"
                                                                checked={this.GetDataValue(DataInput.Published)}
                                                                onChange={(e) => this.SaveDataInput(e.currentTarget.checked, DataInput.Published)}
                                                            />&nbsp;
                                                            <OverlayTrigger overlay={<Tooltip><div style={{ textAlign: 'left' }}>Tick this checkbox to publish this Question Set. Controls the accessability of this Question Set.<p style={{ textAlign: 'right' }}>(default: checked)</p></div></Tooltip>}>
                                                                <i className="fa fa-info-circle" style={{ color: 'blue' }}></i>
                                                            </OverlayTrigger>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '50%', textAlign: 'right' }}>Display Order</td>
                                                        <td style={{ width: '25%' }}>
                                                            <input
                                                                ref={this.EditQSet_DisplayOrder}
                                                                type="number"
                                                                style={{ width: '100%' }}
                                                                placeholder={this.GetDataValue(DataInput.DisplayOrder)}
                                                                onBlur={(e) => this.SaveDataInput(e.target.value, DataInput.DisplayOrder)} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: '50%', textAlign: 'right' }}>Total Question *</td>
                                                        <td style={{ width: '25%' }}>
                                                            <input
                                                                ref={this.EditQSet_TotalQuestion}
                                                                type="number"
                                                                style={{ width: '100%' }}
                                                                placeholder={this.GetDataValue(DataInput.TotalQuestion)}
                                                                onBlur={(e) => this.SaveDataInput(e.target.value, DataInput.TotalQuestion)} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '15%', textAlign: 'right', verticalAlign: 'top', }}>Remark</td>
                                        <td style={{ width: '35%' }}>
                                            <textarea
                                                ref={this.EditQSet_Remark}
                                                rows="4"
                                                // cols="50"
                                                style={{ width: '100%' }}
                                                placeholder={String(this.GetDataValue(DataInput.Remark)).length > 0 ? this.GetDataValue(DataInput.Remark) : '...remark...'}
                                                onChange={(e) => this.SaveDataInput(e.target.value, DataInput.Remark)}></textarea>
                                            <Row>
                                                <Col style={{ fontWeight: 'bold', fontSize: '13px', color: 'red' }}>{String(this.GetDataValue(DataInput.Remark)).length >= 250 ? '(Max charactors reached.)' : ''}</Col>
                                                <Col style={{ textAlign: 'right', fontSize: '12px', color: 'gray' }}>{'(' + String(this.GetDataValue(DataInput.Remark)).length + '/250 charactors remaining.)'}</Col>
                                            </Row>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '15%', textAlign: 'right' }}>Group *</td>
                                        <td style={{ width: '35%' }}>
                                            <Select
                                                ref={this.EditQSet_Group}
                                                options={useAppService.getState().groupOptions}
                                                placeholder={
                                                    this.GetDataValue(DataInput.Group) !== undefined ?
                                                        this.GetDataValue(DataInput.Group).Name
                                                        : Locale("not-specify-group", this.state.locale)
                                                }
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        neutral50: 'black',  // placeholder color
                                                    },
                                                })}
                                                value={this.GetDataValue(DataInput.Group) !== undefined ? this.GetDataValue(DataInput.Group).Name : null}
                                                onChange={(option) => this.SaveDataInput(option, DataInput.Group)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '15%', textAlign: 'right' }}>Subject *</td>
                                        <td style={{ width: '35%' }}>
                                            <Select
                                                ref={this.EditQSet_Subject}
                                                options={useAppService.getState().subjectOptions}
                                                placeholder={
                                                    this.GetDataValue(DataInput.Subject) !== undefined ?
                                                        // this.GetSubjectLabel(this.props.SubjectOptions.find(x => Number(x.id) === Number(this.GetDataValue(DataInput.Subject).Id)))
                                                        this.GetDataValue(DataInput.Subject).Label
                                                        : Locale("not-specify-group", this.state.locale)
                                                }
                                                theme={theme => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        neutral50: 'black',  // placeholder color
                                                    },
                                                })}
                                                value={this.GetDataValue(DataInput.Subject) !== undefined ? this.GetDataValue(DataInput.Subject).Name : null}
                                                onChange={(option) => this.SaveDataInput(option, DataInput.Subject)} />
                                        </td>
                                    </tr>
                                    <tr><td colSpan='3'><hr /></td></tr>
                                    <tr>
                                        <td colSpan='3' align='center'>
                                            <Button variant="danger" onClick={() => this.ToggleDeleteQuestionSetModal()}>Delete Question Set</Button>&nbsp;&nbsp;
                                            <Button variant="secondary" onClick={() => this.ResetForm()}>Reset</Button>&nbsp;&nbsp;
                                            <Button
                                                variant="primary"
                                                onClick={() => this.SaveQuestionSetSettings()}
                                                disabled={this.state.IsSaveSettingsConditionFullfilled === false}
                                            >Save Settings</Button>&nbsp;&nbsp;
                                            {
                                                this.state.ToggleQuestionListUi === false ?
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => this.LoadQuestionList()}
                                                    >Show Questions</Button>
                                                    :
                                                    this.state.IsQuestionsLoadingDone ?
                                                        <Button
                                                            variant="primary"
                                                            onClick={() => this.DownloadQuestionSetInXLSX()}
                                                        >Download Question Set (XLSX)</Button>
                                                        : null
                                            }
                                        </td>
                                    </tr>
                                    <tr><td colSpan='3'><hr /></td></tr>
                                    {
                                        this.state.ToggleQuestionListUi === false ?
                                            // <tr>
                                            //     <td colSpan='3' align='center'>
                                            //         <Button
                                            //             variant="primary"
                                            //             onClick={() => this.LoadQuestionList()}
                                            //         >Show Questions</Button>
                                            //     </td>
                                            // </tr>
                                            null
                                            :
                                            <tr disabled={this.state.IsSavingSettingsViaAPI}>
                                                <td colSpan='3' align='center'>
                                                    {
                                                        this.state.IsQuestionsLoadingDone ?
                                                            this.state.QuestionList.length > 0 ?
                                                                <>
                                                                    <span style={{ fontSize: 24, fontWeight: 'bold' }}>Question List</span>
                                                                    <br />
                                                                    <table id='QsList' className='table table-bordered' cellPadding='5' style={{ marginTop: 10, width: '95%', }}>
                                                                        <tbody>
                                                                            {this.QuestionListUiComponent()}
                                                                        </tbody>
                                                                    </table>
                                                                </>
                                                                :
                                                                'no question found.'
                                                            : <LoadingIndicator />
                                                    }
                                                </td>
                                            </tr>
                                    }
                                    {
                                        this.state.ToggleQuestionListUi ?
                                            <tr disabled={this.state.IsSavingSettingsViaAPI}>
                                                <td colSpan='3'
                                                >
                                                    <div id='AddOrRemoveQs' style={{ width: '100%', textAlign: 'center' }}>
                                                        <span>Add or Remove Question</span><br />
                                                        <table cellPadding='5' cellSpacing='10' border='0' width='100%'>
                                                            <tbody>
                                                                <tr>
                                                                    <td align='right'>
                                                                        <Button
                                                                            variant="secondary"
                                                                            onClick={() => this.ToggleRemoveQuestion()}
                                                                            disabled={this.state.IsSaveSettingsConditionFullfilled === false}
                                                                        >Remove A Question</Button>
                                                                    </td>
                                                                    <td align='left'>
                                                                        <Button
                                                                            variant="primary"
                                                                            onClick={() => this.ToggleAddQuestion()}
                                                                            disabled={this.state.IsSaveSettingsConditionFullfilled === false}
                                                                        >Add A New Question</Button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                            : null
                                    }
                                    {
                                        this.state.ToggleQuestionListUi ?
                                            this.state.ToggleEditQuestionUi ?
                                                this.state.SelectedQuestion !== null ?
                                                    <tr disabled={this.state.IsSavingSettingsViaAPI}>
                                                        <td colSpan='3'
                                                        // style={{ overflowWrap: 'anywhere' }}
                                                        >
                                                            {/* 'edit question ui by quizType.'
                                                                        <br /> */}
                                                            <div id='EditQs'>
                                                                {this.EditQuestionUiComponent()}
                                                            </div>
                                                            {this.QuestionListUiRedirect_Component()}
                                                        </td>
                                                    </tr>
                                                    : null
                                                : null
                                            : null
                                    }
                                </tbody>
                            </table>
                        </div>
                        {this.AddOrRemoveQuestion_ModalComponent()}
                        {this.ConfirmRemoveQuestion_ModalComponent()}
                        {this.ConfirmSwitchQuestion_ModalComponent()}
                        {this.ConfirmRemoveQuestionSet_ModalComponent()}
                    </div>
            }
            <div dangerouslySetInnerHTML={{ __html: this.state.hiddenQuestionSetTableForXLS }} hidden={true}></div>
            <div dangerouslySetInnerHTML={{ __html: this.state.hiddenQuestionSetTableForXLS_comp }} hidden={true}></div>
            <div dangerouslySetInnerHTML={{ __html: this.state.hiddenQuestionSetTableForXLS_fitb }} hidden={true}></div>
        </div>);
    }
}