const malay = {
    'overview': 'Overview',
    'create': 'Create',
    'and': ' dan ',
    'co-op-ikey': ' dengan koperasi bersama iKEY.',
    'optional': 'Opsyen',

    //===== Common =====//
    'yes': 'Ya',
    'no': 'Bukan',
    'not-specify': 'Belum Pilih',
    'not-specify-yes-no': '(Pilih Ya atau Bukan)',

    'signout-logout': 'Log Keluar',
    'back': 'Balik',
    'close': 'Tutup',
    'reset': 'Semula',
    'save': 'Simpan',
    'clear': 'Semula',
    'enter': 'Masuk',
    'back-to-home': 'Balik ke Halaman Utama',

    'profile': 'Profil',
    'your-email': 'E-mel',
    'email-address': 'Alamat E-mel',
    'your-password': 'Kata Laluan',
    'password-min-req': 'sekurangnya 6 huruf dan ke atas',     //2021.08.25

    'grade': '(Pilih Tahap)',
    'label-grade': 'Tahap',
    'subject': 'Mata Pelajaran',
    'not-specify-subject': '(Pilih Mata Pelajaran)',

    'pre-school': 'Pra-Sekolah',
    'standard': 'Darjah',
    'form': 'Tingkatan',
    'form-123': 'Tingkatan',
    'form-45': 'Tingkatan',
    'form-6': 'Tingkatan',
    'other': 'Lain-lain',

    'time': 'Masa',
    'time-hour': 'jam',
    'time-min': 'min',
    'time-sec': 'saat',

    'time-limit': 'Had Masa',
    'time-hour-full': 'jam',
    'time-hour-full-s': 'jam',
    'time-min-full': 'saat',
    'time-min-full-s': 'saat',
    'time-to': 'sehingga',
    'event-period': 'Tempoh Acara',

    'correct': 'Betul',
    'wrong': 'Salah',
    'confirm': 'Pasti',
    'confirm-type-2': 'Faham',

    'label-existing-user': 'Pengguna Sedia Ada',
    'label-new-user': 'Pengguna Baru',

    //Alert
    'error': 'Kesilapan',
    'success': 'Berjaya',
    'failed': 'Gagal',
    'invalid-info': 'Informasi Tidak Sah',
    'invalid-ops': 'Operasi Tidak Sah',
    'invalid-ops-text': 'Sila kemas-kinikan infomasi Profil anda.',

    'search': 'Cari',
    'search-result': 'Keputusan Semak',
    'processing': 'Sedang diproses...',

    //2023.11.15
    'welcome-title': 'Live Quiz Portal',
    'welcome-note': 'Selamat Datang mengunjungi Live Quiz Portal',

    'invalid-login-info': 'Informasi Log Masuk Tidak Sah',
    'email-not-empty': 'Sila isikan <b>E-mel</b>.',
    'password-not-empty': 'Sila isikan <b>Kata Laluan</b>.',
    'name-not-empty': 'Sila isikan <b>Nama</b>.',
    'center-not-empty': 'Sila isikan <b>Pusat Pendidikan</b>.',
    'grade-not-selected': 'Sila pilihkan <b>Tahap Pendidikan</b>',

    //Internet Not Available.
    'label-internet-not-avai': 'Sambungan Internet tidak tersedia',
    'text-internet-not-avai': 'Tidak ada sambungan Internet yang boleh digunakan.',
    'label-internet-down': 'Internet Tidak berfungsi',
    'text-internet-down': 'Sambungan Internet semasa sama ada terputus atau tidak stabil. Tidak dapat meneruskan tugas.',
    'label-internet-recovering': 'Internet sedang dipulih',
    'text-internet-recovering': 'Sedang menyambungkan semula talian Internet...',
    'label-internet-reconnected': 'Internet Sihat',
    'text-internet-reconnected': 'Talian Internet telah dipulihkan.',

    //Error - update failed.
    'update-failed': 'Kemas kini infomasi telah gagal',
    'update-failed-msg': 'Talian Internet mengalami masalah, kemas kini terbaru tidak dapat dilaksanakan, sila muat semula dan cuba lagi.', //<br />Please be assure that all previously saved records are still well intact.',

    //subject.
    'english': 'Bahasa Inggeris',
    'chinese': 'Bahasa Cina',
    'malay': 'Bahasa Melayu',
    'science': 'Sains',
    'mathematics': 'Matematik',

    //quiz
    'quiz-ended': 'Tamat',
    'category': 'Kategori',

    //2022.08.08
    'label-change-password': 'Tukar Kata-laluan',
    'label-password-retype': 'Kata Laluan (Pasti)',
    'reset-password-warning-text': 'Sebelum memintakan link reset kata-laluan,<br />sila pastikan alamat E-mel anda adalah sah dan boleh menerima E-mel.<br />Untuk reset kata-laluan hanya boleh dilakukan dengan ciri ini.<br />Kalau hanya mahu menukarkan kata-laluan, sila tukar di ciri profile selepas log masuk.',
    'change-password-warning-invalid-pass': 'Sila masukkan <b>Kata Laluan</b> yang sesuai.',
    'change-password-warning-invalid-pass-confirm': 'Sila masukkan <b>Kata Laluan (Pasti)</b> yang sesuai.',
    'change-password-warning-empty-pass': 'Sila masukkan <b>Kata Laluan (Baru)</b>.',
    'change-password-warning-empty-pass-confirm': 'Sila masukkan <b>Kata Laluan (Pasti)</b>.',
    'change-password-warning-not-match': 'Kedua-dua kata-laluan tidak sepadan, sila periksa sekali lagi.',
    'change-password-result-success': '<b>Kata Laluan (Baru)</b> anda telah berjaya diubahkan.',

    //2022.11.01
    'label-password-new': 'Kata Laluan (Baru)',
    'change-password-warning-too-short-pass': '<b>Kata Laluan (Baru)</b> terlalu pendek, sila isikan kata laluan yang berpanjang <b>sekurangnya 6 huruf</b> dan ke atas.',
    'change-password-warning-too-short-pass-confirm': '<b>Kata Laluan (Pasti)</b> terlalu pendek, sila isikan kata laluan yang berpanjang <b>sekurangnya 6 huruf</b> dan ke atas.',

    //2023.12.01
    // 'label-password-current': 'Kata Laluan (Kini)',
    // 'label-password-new-confirm': 'Kata Laluan (Baru) (Pasti)',
    // 'author-change-password-warning-text': '',

    //2023.11.20
    'label-change-email': 'Tukar E-mel',
    'label-email-new': 'E-mel (Baru)',
    'label-email-retype': 'E-mel (Pasti)',
    // 'reset-email-warning-text': 'Please make sure your email address is valid<br />and able to receive email before proceed to request for a password reset link.<br />Email can only be update via this method.<br />If you only want to change email again, you may do it again in profile section after login.',
    'change-email-warning-invalid-format': 'Sila masukkan <b>E-mel (Baru)</b> yang sesuai.',
    'change-email-warning-invalid-format-confirm': 'Sila masukkan <b>E-mel (Pasti)</b> yang sesuai.',
    'change-email-warning-empty': 'Sila masukkan <b>E-mel (Baru)</b>.',
    'change-email-warning-empty-confirm': 'Sila masukkan <b>E-mel (Pasti)</b>.',
    'change-email-warning-not-match': 'Kedua-dua E-mel tidak sepadan, sila periksa sekali lagi.',
    'change-email-result-success': '<b>E-mel (Baru)</b> anda telah berjaya diubahkan.',

    //2023.11.29
    'label-file-download-success': 'File Berjaya Dimuat-turun!',
    'notice-file-download-success': "File anda telah berjaya dimuat-turun. Sila periksanya dalam peti muat turun anda.",

    //===== Login Screen =====//
    'event': 'Acara',
    'organise-by': 'Dianjurkan oleh',
    'partnered-with': 'Bekerjasama dengan', //2022.05.13
    'click-here': 'Tekan<br />Sini',
    'click-here-link': 'tekan sini',
    'coming-soon': 'Akan Datang',
    'private-distribution': 'Akan Diumumkan', //2021.07.14
    'details': 'Perincian',
    'more-info': 'Maklumat Lanjut',
    'label-already-have-account-notice': 'Sudah ada akaun ? Log masuk di bawah.',

    'signup': 'Daftar',
    'signup-register': 'Daftar',
    'signin-login': 'Log Masuk',
    'login': 'Log Masuk',
    'forgot-password': 'Lupa?',
    'keep-email-password': 'Ingatkan e-mel & kata laluan.',
    'logging-in': 'Log masuk sedang dijalankan',
    'please-wait': 'Sila tunggu sebentar.',
    'shared-login-notice': 'Log masuk berkongsi dengan iKey Kidz App.',

    //2021.04.14
    // 'label-login-error': 'Error signing in with password and email !',
    'notice-login-wrong-password': 'Alamak! Salah Kata Laluan!<br /><br />Sila cuba lagi ataupun tekan “Lupa?” untuk menerima pautan penukaran kata laluan dalam emel anda',
    'notice-login-user-not-found': 'E-mel ini masih belum didaftarkan.<br /><br />Sila daftarkan akaun baru dengan e-mel anda supaya log masuk.',


    //===== SignUp Screen =====//
    'your-name': 'Nama Penyerta',
    'your-center': 'Pusat Pendidikan',
    'your-grade': 'Tahap Pendidikan Semasa',
    'your-classroom': 'Bilik Darjah',
    'full-name': 'Nama Penuh',
    'center-name': 'Nama Pusat Pendidikan',
    'classroom-name': 'Nama Bilik Darjah',
    'user-creation-in-progress': 'Profil sedang diproses',
    'wait-patiently': 'Sila menunggu dengan sabar, dan berkekal pada laman web ini sehingga proses habis dijalankan.',
    'invalid-signup': 'Pendaftaran tidak sah',
    'unable-to-create-user': 'Tidak dapat mendaftarkan akaun pengguna.',
    'creating-user-profile': 'Profil pengguna sedang diproses',
    'user-profile-created': 'Proses profil pengguna selesai',
    'redirect-to-login': 'Balik ke halaman log masuk...',

    'skip-this-if-registered-in-app': 'Abaikan halaman ini jika anda telah berdaftar dengan iKEY Kidz app sebelum ini atau sudah ada akaun untuk halaman web ini.',
    'fill-up-required-fields': 'Semua bidang yang ditandai dengan tanda bintang (*) wajib diisi.',

    'email-confirm-not-empty': 'Sila isikan <b>E-mel (Pasti)</b>.',
    'password-confirm-not-empty': 'Sila isikan <b>Kata Laluan (Pasti)</b>.',
    'email-bad-format': 'Sila isikan <b>E-mel</b> dengan format yang betul.<br />(E.g. user@company.com)',
    'email-confirm-bad-format': 'Sila isikan <b>E-mel (Pasti)</b> dengan format yang betul.<br />(E.g. user@company.com)',
    'email-not-matched': '<b>E-mel</b> tidak selaras. Sila periksakan kedua-kedua E-mel anda.',
    'password-not-matched': '<b>Kata Laluan</b> tidak selaras. Sila periksakan kedua-kedua kata-laluan anda.',

    'contact-number': 'Nombor Hubungan',
    'contact-number-sample': '(E.g. +60167552525)',
    'contact-number-not-empty': 'Sila isikan <b>Nombor Hubungan</b>.',
    'contact-number-bad-format': 'Sila isikan <b>Nombor Hubungan</b> dengan format yang betul.<br />(E.g. +60167552525)',

    //2021.08.23
    'password-too-short': '<b>Kata Laluan</b> terlalu pendek, sila isikan kata laluan yang berpanjang <b>sekurangnya 6 huruf</b> dan ke atas.',
    'password-confirm-too-short': '<b>Kata Laluan (Pasti)</b> terlalu pendek, sila isikan kata laluan yang berpanjang <b>sekurangnya 6 huruf</b> dan ke atas.',

    //2021.08.26
    'phone-number-sample': 'contoh: 127552525',
    'contact-number-too-short': '<b>Nombor Hubungan</b> terlalu pendek, sila isikan Nombor Hubungan yang berpanjang <b>sekurangnya 8 digit</b>, dan tidak melebihi jumlah 15.',


    //===== SignUp Screen (National Quiz Event) =====//
    'label-state': 'Negeri',
    'label-national-state': '(E.g. Pulau Pinang)',
    'label-school': 'Sekolah',
    'text-school-name': '(Taip Nama Sekolah)',
    'text-school-sample': '(E.g. Chung Hwa atau <b><i>Other</i></b> untuk yang lain-lain.)',
    'label-agree-policy-tnc': 'Dasar Privasi dan Terma & Syarat',
    'text-accept': 'Saya bersetuju dengan ',
    'text-accept-1': ' yang dinyatakan.',
    'text-privacy-policy': 'Dasar Privasi',
    'text-tnc': 'Terma & Syarat',
    'national-state-not-selected': 'Sila pilihkan <b>Negeri</b> yang berada.',
    'school-name-not-empty': 'Sila isikan <b>Nama Sekolah</b>.',
    'policy-tnc-not-checked': 'Anda mesti bersetuju dengan <b><u>Dasar Privasi</u></b> dan <b><u>Terma & Syarat</u></b> untuk meneruskan pendaftaran anda.',
    'label-guardian-name': 'Nama Penjaga',
    'label-12-and-below': 'Umur 12 tahan dan ke bawah.',
    'guardian-name-not-empty': 'Dengan <b><u>Umur 12 tahan dan ke bawah</u></b> terpilih, sila pastikan <b><u>Nama Penjaga</u></b> sudah diisi.',
    'label-center-student-1': 'Adakah anda pelajar dari ',
    'label-center-student-2': ' ?',
    'center-student-not-selected-1': 'Sila pilihkan <b>Adakah anda pelajar dari ',
    'center-student-not-selected-2': ' ?</b> dengan pilihan <b>Ya</b> atau <b>Bukan</b>.',

    //2020.12.14
    'label-select-student-group': 'Pilihkan kumpulan anda untuk ',
    'not-specify-group': '(Pilih Kumpulan)',
    'student-group-not-selected': 'Sila pilihkan <b>kumpulan</b> anda.',
    'group-a-abacus': 'Kumpulan A (Umur 6 tahun dan ke bawah)',
    'group-b-abacus': 'Kumpulan B (Umur 7 - 9 tahun)',
    'group-c-abacus': 'Kumpulan C (Umur 10 - 12 tahun)',
    'group-d-abacus': 'Kumpulan D (Umur 13 tahun dan ke atas)',

    //2021.07.30
    'label-gender': 'Jantina',
    'not-specify-gender': '(Pilih Jantina)',
    'label-gender-male': 'Lelaki',
    'label-gender-female': 'Perempuan',
    'label-gender-other': 'Lain-lain',
    'label-race': 'Bangsa',
    'not-specify-race': '(Pilih Bangsa)',
    'label-race-0': 'Lain-lain',
    'label-race-1': 'Cina',
    'label-race-2': 'Melayu',
    'label-race-3': 'India',
    // 'label-religion': 'Agama',
    // 'not-specify-religion': '(Pilih Agama)',
    // 'label-religion-0': 'Lain-lain',
    // 'label-religion-1': 'Buddha',
    // 'label-religion-2': 'Kristian',
    // 'label-religion-3': 'Katolik',
    // 'label-religion-4': 'Islam',
    // 'label-religion-5': 'Hindu',

    //2021.10.12
    'label-district': 'Daerah',
    'label-district-area': '(E.g. PPD TIMUR LAUT)',
    'district-area-not-selected': 'Sila pilihkan <b>daerah</b> anda.',

    //2022.05.12
    'years-old': ' tahun',
    'kindergarden': 'Tadika',


    //===== Password Reset Screen =====//
    'password-reset': 'Penukaran Kata Laluan',
    'confirm-your-email': 'E-mel (pasti)',
    'get-password-reset-link': 'Dapatkan E-mel Penukaran Kata Laluan',
    'confirm-email-not-empty': 'Sila jangan tinggalkan E-mel (pasti) kosong.',
    'enter-email-correctly': 'Sila isikan E-mel yang betul.',
    'enter-confirm-email-correctly': 'Sila isikan E-mel (pasti) yang betul.',
    'reset-password-sent': 'E-mel penukaran kata laluan telah dihantarkan ke alamat E-mel anda.',
    'reset-password-failed': 'Gagal menghantarkan E-mel penukaran kata laluan ke alamat E-mel yang diberikan. Sila semak & cuba lagi.',
    'email-not-match': 'Kedua-dua E-mel yang diberikan tidak sepadan. Sila semak & cuba lagi.',
    // 'reset-pass-failed-label-possible-reason': 'Possible reason:',
    'reset-pass-failed-user-not-found': 'Oops! E-mel yang diberikan <u>belum didaftarkan</u> dalam sistem kami.<br />Sila daftarkan akaun baru dengan e-mel anda.',
    'reset-pass-failed-invalid-email': 'E-mel yang diberikan adalah tidak sah.<br />Sila masukkan e-mel yang sah dan mencuba lagi.',


    //===== Home Screen =====//
    'today-last-quiz': 'Kuiz dihadiri terkini',
    'click-to-enter-room': 'Tekan untuk masuk bilik',
    'room-code-1': 'Kod Bilik',
    'room-code': 'Bilik',
    // 'room-title': 'Bilik',
    'room-title': 'Nama Bilik',
    'room-date': 'Tarikh',
    'room-date-of-enter': 'Tarikh Masuk',       //2023.11.16
    'room-submitted-date': 'Tarikh Hantar',     //2023.11.16
    'room-score': 'Skor',
    'room-code-ph': 'Sila masukkan kod bilik di sini.',
    'entering-room': 'Sedang masuk Bilik #',
    'room-code-required': 'Sila masukkan kod bilik.',
    'invalid-room-code-entered': 'Kod bilik tidak sah',
    'loading-room-info': 'Bilik sedang diproses...',
    'room-not-found': 'Bilik tidak wujud.<br /><br />Kemungkinan: <br />(1) Bilik hanya boleh dimasuki pada hari kuiz yang diadakan.<br />(2) Tarikh & masa pada aset ini tidak kemaskini.',
    'profile-update-in-progress': 'Profil sedang dikemaskini',
    'click-to-edit-profile': 'Tekan sini untuk mengemaskini profil.',
    'show-quiz-history': 'Sejarah Kuiz',
    'title-upcoming-quiz': 'Kuiz Akan Datang',
    'policy-tnc-agree-datetime': 'Tarikh Bersetuju',
    'policy-tnc-published': 'Tarikh diumumkan',
    'edit-profile': 'Kemas-kini Profil',
    'title-attention-needed': 'Sila Berhatian',
    'text-policy-tnc-agree-needed':
        'Kepada pengguna yang telah berdaftar sebelum ini, <b><u>Dasar Privasi</u></b> dan <b><u>Terma & Syarat</u></b> yang terkini telah dikeluarkan baru-baru ini.'
        + '<br /><br />'
        + 'Didapati anda belum bersetuju dengan <b><u>Dasar Privasi</u></b> dan <b><u>Terma & Syarat</u></b> yang baru. '
        + '<br /><br />'
        + 'Anda mesti bersetuju dengan <b><u>Dasar Privasi</u></b> dan <b><u>Terma & Syarat</u></b> yang baru ini untuk berterusan.',
    'text-history-list-is-empty': 'Tidak ada kuiz sejarah.',
    'download-ikey-parent-app': "Muat turun Aplikasi <b><u>iKEY</u></b> untuk mengetahui lebih lanjut mengenai keputusan kuiz anak-anak anda.",
    'text-profile-update-needed': 'Terdapat informasi terhilang dalam profil anda, sila turut mengemaskinikan profil anda secepat mungkin.',

    //2020.12.15
    'i-want-to-join': 'Saya mahu menyertai !',
    'quiz-event-join-facebook': 'Klik sini untuk Sertailah kumpulan Facebook kami untuk mengetahui maklumat lanjut.',

    //2021.01.19    //2021.02.01
    'cannot-enter-room-today-title': 'Bilik ini tidak tersedia untuk dimasuki buat masa ini.',
    'cannot-enter-room-today-content': 'Peringatan: <ul style="list-style-type:decimal;padding-left:20px;"><li>Pastikan anda menyambung ke wifi yang lebih kuat untuk pengalaman yang lebih baik.</li><li>Anda dinasihatkan untuk gunakan Google Chrome untuk menyertai kuiz.</li><li>Sila sertailah kumpulan yang sesuai sahaja.</li><li>Sila sertailah pada tarikh dan waktu bermula yang betul. </li></ul>',

    //2022.10.15
    'event-reminders': 'Peringatan: <ul style="list-style-type:decimal;padding-left:20px;"><li>Pastikan signal WIFI yang lebih stabil untuk memastikan kelancaran semasa menjawab soalan kuiz.</li><li>Anda digalakkan menggunakan pelayar Google Chrome untuk menyertai kuiz.</li><li>Pastikan anda menyertai kuiz mengikut tahun yang betul.</li><li>Pastikan anda menyertai kuiz pada tarikh and masa yang ditetapkan.</li><li>Sebelum masuk bilik kuiz, sila <b>pastikan tarikh dan masa alat peranti anda adalah betul</b>.</li>',
    'event-reminders-telegram-info': '<li>Sertai Telegram kami untuk mendapatkan maklumat terkini mengenai kuiz!<br />SJKC: <a href="https://t.me/SPOT_SJKC" target="_blank">https://t.me/SPOT_SJKC</a><br />SK: <a href="https://t.me/SPOT_SK" target="_blank">https://t.me/SPOT_SK</a><br />Tadika: <a href="https://t.me/SPOT_PreSchool" target="_blank">https://t.me/SPOT_PreSchool</a></li>',

    //2021.02.01
    // 'title-attention': 'Perhatian',
    'event-registration-end-datetime': '<span class="blink" style="color:red;"><i class="fa fa-exclamation-triangle"></i> <b>Perhatian</b> <i class="fa fa-exclamation-triangle"></i></span><br /><br />Tarikh dan masa pendaftaran terakhir pada ',
    'event-registration-ended': 'Pendaftaran untuk acara ini telah berakhir pada ',

    //2021.11.10
    'event-registration-overloaded': 'Maaf, pendaftaran sudah penuh.<br />Sila daftar lain kali.',

    //2021.02.17
    'register-now': 'Daftar Sekarang',

    //2023.12.06
    'title-invalid-operation': 'Operasi tertolak',
    'warning-profile-under-maintain-by-organizer': 'Profil kini diselenggara oleh Penganjur, sila hubungi Penganjur untuk sebarang perubahan yang diperlukan.',
    'organizers': 'Penganjur-penganjur',

    //===== Quiz Live Screen =====//
    'no-of-qs': 'Bilangan Soalan',
    'total-duration': 'Jumlah Had Masa',
    'begin': 'Bermula',
    // 'waiting-to-start': 'menunggu untuk bermula',
    'waiting-to-start': 'menunggu untuk bermula<br /><br />jika sampai masa bermula tapi button bermula masih belum ditunjukkan, sila balik ke laman depan & kembali ke bilik ini semula lagi.',       //2021.08.28
    'loading-qs': 'Soalan-soalan sedang diproseskan...',
    'submit-and-show-result': 'Serah Jawapan & Tunjuk Keputusan',
    'quiz-result': 'Keputusan Kuiz',
    'quiz-score': 'Markah',
    'quiz-total-time-consumed': 'Jumlah masa digunakan',
    // 'quiz-total-correct': 'Jumlah soalan yang Betul',
    // 'quiz-total-wrong': 'Jumlah soalan yang Salah',
    'quiz-total-correct': 'Jumlah Jawapan Betul',
    'quiz-total-wrong': 'Jumlah Jawapan Salah',
    'question': 'Soalan',
    'question-to': 'hingga',
    'result': 'Keputusan',
    'goto': 'Layar Pintas',
    'goto-qs': 'Lompat ke Soalan',
    'processing-result': 'Keputusan sedang diproses...',
    'time-left': 'Masa tertinggal',
    'quiz-time-left': 'Masa Kuiz Aktif Tertinggal',
    'created-date': 'Tarikh Kuiz',
    'held-date': 'Tarikh Kuiz',
    'start-time': 'Masa Bermula',
    'end-time': 'Masa Tamat',
    'participated-date': 'Tarikh Penyertaan',       //2021.04.05

    //2023.10.18
    'quiz-room-access-restricted-label': 'Bilik ini telah diberhentikan',
    'quiz-room-access-restricted-notice': 'Bilik Kuiz ini telah diberhentikan buat sementara waktu.<br />Sila cuba sebentar lagi.',

    //===== Quiz Question component =====//
    'quiz-times-up': "Masa tamat, tiada jawapan diberikan",
    // 'quiz-qs-comprehension': 'Bacakan karangan yang terdapat di bawah dengan teliti, dan jawapkan soalan-soalan bermula dari No.',
    'quiz-qs-comprehension': 'Berdasarkan petikan di bawah, pilih jawapan yang betul.<br />(Soalan ',
    'quiz-qs-comp-to': ' hingga ',
    // 'quiz-qs-comp-end': ' ',
    'quiz-qs-comp-end': ')',
    'hide-article': 'Tutup Petikan',
    'show-article': 'Tunjuk Petikan',
    'next': 'Soalan seterusnya',
    'goto-result': 'Keputusan',
    'fill-in-the-blanks': 'Isi Tempat Kosong',
    'comprehension': 'Pemahaman',
    'your-answer': 'Jawapan Anda',
    'correct-answer': 'Jawapan Betul',
    'times-up': "Masa Tamat",
    'options-for-qs': 'Jawapan Pilihan untuk Soalan ',
    'FITB-instruction-1': 'Klik pada butang ',
    'FITB-instruction-2': ' untuk memilih jawapan.',
    'subjective': 'Subjektif',
    'enter-answer-here': 'Isikan jawapan di sini',
    'subjective-instruction': 'Kira jumlah dan isikan jawapan<br />Enter atau tekan pasti untuk seterusnya',

    //2021.04.30
    'quiz-ans-empty': "Tiada Jawapan diperolehi",
    'label-quiz-sub-possible-answers': 'Jawapan-jawapan',
    'label-quiz-sub-possible-answers-combination': 'Jawapan-jawapan',


    //===== ParticipatedEventList =====//
    'no-event-found': 'Tiada apa-apa acara yang disertai pada masa kini.',
    'label-download-certificate': 'Muat turun e-Sijil (PDF)',
    'label-download-certificate-delayed': 'Fungsi muat-turn e-Sijil belum lagi tersedia.',
    'label-download-certificate-delayed-deadline': 'e-Sijil akan tersedia selepas tarikh <>.',      //2021.11.16
    'label-download-certificate-delayed-deadline-1': '(atau akan tersedia lebih awal)',      //2022.06.11
    'label-view-history': 'Layari<br />Kuiz',
    'label-participated-on-date': '* Acara ini disertai pada ',
    'label-participated-on-date-1': '* 於 ',
    'label-participated-on-date-2': ' 参与了这项活动',
    // 'label-notice-participated-event': 'Muat turun e-Sijil (PDF) melalui laman web Participated Event.',
    //2021.08.25
    'label-notice-participated-event': 'Klik banner di bawah mengetahui cara-cara untuk muat turun e-sijil kuiz.',
    'label-notice-participated-event-innerpagebtn': 'Klik sini untuk mengetahui cara-cara<br />muat turun e-sijil kuiz.',

    'label-pe-feature': 'Fungsi ini dikehendaki berkoperasi sama dengan aplikasi &#60;iKEY&#62;.',
    // 'notice-pe-download-pdf': 'Fungsi Muat Turun ini hanya boleh diakses melalui aplikasi <b>iKEY</b>.<br /><br />Sila muat turun dan pasangkan <b>iKEY</b> dan <u>kembali ke laman web ini melalui aplikasi</u> untuk muat turun sijil dalam PDF.',
    'notice-pe-download-pdf': '<b>Anda perlu muat turun iKEY APP untuk mendapatkan e-Sijil!</b><br /><br />Tekan <a>sini</a> untuk dapatkan info lanjutan.',
    'notice-pe-load-more': 'Fungsi ini hanya boleh diakses melalui aplikasi <b>iKEY</b>.<br /><br />Sila muat turun dan pasangkan <b>iKEY</b> dan <u>kembali ke laman web ini melalui aplikasi</u> untuk memuatkan <u>acara-acara lain yang telah disertai</u>.',
    'notice-pe-list-limit-5': '* Muat turun aplikasi <b><u>iKEY</u></b> untuk senarai acara-acara yeng lengkap.',
    'label-load-more': 'lain-lain',
    'pdf-download-success': 'Muat turun e-Sijil telah berjaya!',
    'label-pdf-download-reminder': 'Peringatan',
    'notice-pdf-download-reminder': 'Sekiranya muat turun e-Sijil anda tidak berjaya, sila kemas kini iKEY anda ke versi terkini dan cuba lagi.',
    'label-pdf-download-manual-btn': 'Muat e-Sijil turun secara manual',

    //2021.08.30
    'label-pdf-download-success': 'Berjaya Dimuat-turun!',
    'notice-pdf-download-success': "e-Sijil anda telah berjaya dimuat-turun. Sila periksanya dalam peti muat turun anda.",

    //2021.09.10
    'label-pdf-download-failed': 'Servis Muat-turun Gagal',
    'notice-pdf-download-failed': "Servis muat-turun e-Sijil gagal pada masa kini. Sila cuba lagi pada masa yang lain.",

    //2021.12.09
    'click-to-view-participant-works': 'Tekan untuk melayari hasil kerja oleh para penyerta',
    'participant-submitted-entries': 'Hasil Kerja Entri Para Penyerta',

    //===== Others =====//
    'quiz-history': 'iKEY Sejarah Kuiz',
    'last-update': 'Kemas-kini Terakhir',
    'download-ikey-parent-for-complete-history': '* Muat turun aplikasi <a href="#download-app-pos"><b><u>iKEY</u></b></a> untuk senarai sejarah kuiz yang lengkap.',

    //===== QuizRoomTypeFileUpload =====//
    'room-essay-title': 'Tajuk Karangan',
    'room-essay-report-label': 'Laporan',
    'room-essay-report-url-pending': 'laporan rasmi belum lagi tersedia.',
    'file-uploaded': 'Fail',
    'file-upload-date': 'Tarikh Fail Muat-naik',
    'file-upload-success': 'Fail telah berjaya dimuat-naik.',
    'score-issue-date': 'Tarikh Markah Diberi',
    'not-yet-specify-result': 'masih menunggu keputusan',
    'room-essay-upload-ended': 'kuiz telah tamat, fungsi muat-naik telah dipadamkan.',      //2021.11.15
    'room-essay-cert-sn-label': 'Nombor Siri e-Sijil',     //2021.12.07
    'pending': 'masih menunggu keputusan',   //2021.12.10

    //===== ManageStudentProfile =====//
    'label-custom-group': 'Kumpulan Tersuai (Custom Group)',
};
export default malay;