import React, { createRef } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button } from "react-bootstrap";

import { Access, LayoutScreen, ReportMenu } from "../utilities/GlobalSetting";
import { useGlobal } from "../utilities/GlobalVariables";
import { CheckBoolean, CheckObjectStringEmpty, CheckStringEmpty, ConsoleLog } from "../utilities/GlobalFunctions";

import ReportRoomsResult from "./Reports/ReportRoomsResult";
import ReportAbsentStudentScreen from "./Reports/ReportAbsentStudentScreen";
import ReportStudentStatisticScreen from "./Reports/ReportStudentStatisticScreen";


export default class ManageReportScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.

        this.ReportRoomResult_Ref = createRef();
        this.ReportAbsentStudent_Ref = createRef();
        this.ReportStudentStatistic_Ref = createRef();
    }

    getInitState = () => ({
        // isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,

        isSuperAdmin: false,
        isMasterAdmin: false,
        isAdmin: false,
        isTeacher: false,
        // isAuthor: false,
        screen: LayoutScreen.ManageReport,
        Option: null,
    });

    componentWillUnmount = () => { }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.setState({
            screen: CheckStringEmpty(useGlobal.getState().screen, LayoutScreen.ManageReport),
            isSuperAdmin: CheckBoolean(useGlobal.getState().isSuperAdmin),
            isMasterAdmin: CheckBoolean(useGlobal.getState().isMasterAdmin),
            isAdmin: CheckBoolean(useGlobal.getState().isAdmin),
            isTeacher: CheckBoolean(useGlobal.getState().isTeacher),
            // isAuthor: CheckBoolean(useGlobal.getState().isAuthor),
        });
    }

    SetMenuOption = (option = null) => {
        if (option === null)
            return null;
        this.setState({ Option: option, });
        const screen = CheckObjectStringEmpty(option, 'Screen');
        if (screen !== '') {
            useGlobal.getState().setScreen(screen);
            this.setState({ screen: screen, });
            ConsoleLog('SetSettingOption = ' + screen + ' / ' + useGlobal.getState().screen + '\n' + JSON.stringify(option));
            if (typeof useGlobal.getState().refreshListCallbackFn === 'function')
                useGlobal.getState().refreshListCallbackFn();
        }
    }

    MenuButtonComponents = () => {
        let components = [];
        Object.keys(ReportMenu).map((name, key) => {
            const data = ReportMenu[name];
            if (data.ShowInMenu) {
                if (
                    data.Show === Access.All
                    || data.Show === Access.ByPermission
                    || (data.Show === Access.SuperAdminOnly && this.state.isSuperAdmin)
                    || (data.Show === Access.MasterAdminOnly && this.state.isMasterAdmin)
                    || (data.Show === Access.AdminOnly && this.state.isAdmin)
                ) {
                    // if (ShowOrHideMenu(data)) {
                    components.push(<Button
                        key={'ms-mp-btn-' + key}
                        variant={this.state.screen === LayoutScreen.ManageReport || data.Screen === this.state.screen ? 'primary' : 'secondary'}
                        onClick={() => this.SetMenuOption(data)}
                        style={data.Disabled ? { userSelect: 'none', cursor: 'not-allowed' } : {}}
                    >{data.Label}</Button>);
                }
            }
            return null;
        });
        return (components);
    }

    MainPanelComponent = () => {
        switch (CheckObjectStringEmpty(this.state.Option, 'Screen')) {

            //Manage Custom Group.
            case ReportMenu['roomResult'].Screen:
                return (<ReportRoomsResult ref={ref => ref = this.ReportRoomResult_Ref} />);

            //Manage Report Absent Student.
            case ReportMenu['absentStudent'].Screen:
                return (<ReportAbsentStudentScreen ref={ref => ref = this.ReportAbsentStudent_Ref} />);

            //Manage Report Student Statistic.
            case ReportMenu['studentStatistic'].Screen:
                return (<ReportStudentStatisticScreen ref={ref => ref = this.ReportStudentStatistic_Ref} />);

            //Manage Reports - buttons.
            default:
                return (<div className="manage-setting-buttons">
                    <h4>Manage Reports</h4>
                    {this.MenuButtonComponents()}
                </div>);
        }
    }

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<>
            <div className="manage-setting">
                {
                    this.state.isSuperAdmin || this.state.isMasterAdmin || this.state.isAdmin || this.state.isTeacher ?
                        this.state.screen === LayoutScreen.ManageReport ?
                            this.MainPanelComponent()
                            :
                            <>
                                <div className="left-panel" style={{ width: 250 }}>{this.MenuButtonComponents()}</div>
                                <div className="main-panel">{this.MainPanelComponent()}</div>
                            </>
                        :
                        <span>Invalid permission.</span>
                }
            </div>
        </>);
    }
}