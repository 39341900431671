import { create } from 'zustand';
import { LayoutScreen } from './GlobalSetting';
import { CheckBoolean, CheckNullValue, CheckObjectNumber, CheckObjectStringEmpty } from './GlobalFunctions';

export const useGlobal = create((set) => ({

    locale: 'en',
    setLocale: (locale) => set({ locale }),

    screen: LayoutScreen.None,
    setScreen: (screen) => set({ screen }),

    menuLabel: '',
    setMenuLabel: (menuLabel) => set({ menuLabel }),

    email: '',
    setEmail: (email) => set({ email }),

    password: '',
    setPassword: (password) => set({ password }),

    user: null,
    setUser: (user) => set({ user }),

    authorName: '',
    setAuthorName: (authorName) => set({ authorName }),

    credential: null,
    setCredential: (credential) => set({ credential }),

    isAuthor: false,
    setIsAuthor: (isAuthor) => set({ isAuthor }),

    isTeacher: false,
    setIsTeacher: (isTeacher) => set({ isTeacher }),

    isAdmin: false,
    setIsAdmin: (isAdmin) => set({ isAdmin }),

    isMasterAdmin: false,
    setIsMasterAdmin: (isMasterAdmin) => set({ isMasterAdmin }),

    isSuperAdmin: false,
    setIsSuperAdmin: (isSuperAdmin) => set({ isSuperAdmin }),

    organizerInfo: null,
    setOrganizerInfo: (organizerInfo) => set({ organizerInfo }),
    updateOrganizerInfo: (organizerInfo) => {
        const _organizerInfo = PopulateOrganizerInfoModal(organizerInfo);
        useGlobal.getState().setOrganizerInfo(_organizerInfo);
        let user = { ...useGlobal.getState().user };
        if (user !== null) {
            user.OrganizerInfo = _organizerInfo;
            useGlobal.getState().setUser(user);
            // console.log('user (updateOrganizerInfo) =\n' + JSON.stringify(useGlobal.getState().user));
        }
        const screen = useGlobal.getState().screen;
        const callbackFn = useGlobal.getState().refreshListCallbackFn;
        // const params = GetPropIds(useGlobal.getState().user);
        // if (callbackFn !== null && callbackFn !== undefined)
        if (CheckNullValue(callbackFn) !== null && typeof (callbackFn) === 'function') {
            switch (screen) {
                case LayoutScreen.Dashboard: callbackFn(); break;
                default: callbackFn(true); break;
            }
        }
        // console.log('callback (params) =\n' + JSON.stringify(params));
    },

    refreshListCallbackFn: () => { },
    setRefreshListCallbackFn: (refreshListCallbackFn) => set({ refreshListCallbackFn }),

    freezeAllControls: false,
    setFreezeAllControls: (freezeAllControls) => set({ freezeAllControls }),
    updateFreezeAllControls: (freezeAllControls) => {
        useGlobal.getState().setFreezeAllControls(freezeAllControls);
        const com = document.getElementById('main-layout');
        if (com !== null)
            com.style.pointerEvents = CheckBoolean(useGlobal.getState().freezeAllControls) ? 'none' : 'auto';
    },

    isInternetReachable: false,
    setIsInternetReachable: (isInternetReachable) => set({ isInternetReachable }),

}));

//2024.05.06
function PopulateOrganizerInfoModal(info = null) {
    const Name = CheckObjectStringEmpty(info, 'Name');
    const DisplayName = CheckObjectStringEmpty(info, 'DisplayName');
    const Identity = CheckObjectStringEmpty(info, 'Identity');
    const NationalState = CheckObjectStringEmpty(info, 'NationalState');
    const DistrictArea = CheckObjectStringEmpty(info, 'DistrictArea');
    const SchoolName = CheckObjectStringEmpty(info, 'SchoolName');
    const SchoolCompleteName = CheckObjectStringEmpty(info, 'SchoolCompleteName');
    const Id = CheckObjectNumber(info, 'Id');
    const OrganizerId = CheckObjectNumber(info, 'OrganizerId');
    const LogoId = CheckObjectNumber(info, 'LogoId');
    const LogoUrl = CheckObjectStringEmpty(info, 'LogoUrl');
    const SchoolAddress = CheckObjectStringEmpty(info, 'SchoolAddress');
    const SchoolCode = CheckObjectStringEmpty(info, 'SchoolCode');

    //2024.07.18
    const tmp_cp = CheckObjectStringEmpty(info, 'CustomPermissions');
    const CustomPermissions = tmp_cp === '' ? {} : JSON.parse(tmp_cp);

    return { Name, DisplayName, Identity, NationalState, DistrictArea, SchoolName, SchoolCompleteName, Id, OrganizerId, LogoId, LogoUrl, SchoolAddress, SchoolCode, CustomPermissions };
};

//2024.05.06
export function GetOrganizerInfo() {
    return PopulateOrganizerInfoModal(useGlobal.getState().organizerInfo);
}