import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import Select from "react-select";
import Chart from "react-apexcharts";
import moment from "moment";
import { collection, doc, onSnapshot, query, setDoc, where } from "firebase/firestore";

import LoadingIndicator from "./LoadingIndicator";
import { useGlobal } from "../utilities/GlobalVariables";
import { useAppService } from "../services/AppService";
import { Access, GlobalSetting, LayoutScreen, Menu, MenuReverse, ToastPosition } from "../utilities/GlobalSetting";
import { CapitalizeJsonKeys, CheckBoolean, CheckNullValue, CheckNumber, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, Delay, DelayUntil, GetDurationText, GetGroup, GetPropIds, GetSubject } from "../utilities/GlobalFunctions";
import { AlertMode } from "./AlertComponent";
import { firestore } from "../utilities/Firebase";


const defaultSettings = { year: moment().year(), month: moment().month() + 1, groupId: 1, subjectId: 1, classroom: '', quizRoomCode: '' };
// const defaultSettings = { year: '2023', month: 10, groupId: 1, subjectId: 1, classroom: '', quizRoomCode: '' };
const ParamTarget = {
    None: 0,
    Year: 1,
    Month: 2,
    Group: 3,
    Subject: 4,
    Classroom: 5,
    QuizRoom: 6,
};
const chartParamOptions = [
    {
        //0
        id: ParamTarget.Year,
        label: 'Year',
        name: 'year',
        options: [
            { value: '', label: '', id: 0 },
            { value: '', label: '', id: 0 },
            { value: '', label: '', id: 0 }
        ]
    },
    {
        //1
        id: ParamTarget.Month,
        label: 'Month',
        name: 'month',
        options: []
    },
    {
        //2
        id: ParamTarget.Group,
        label: 'Group',
        name: 'groupId',
        options: []
    },
    {
        //3
        id: ParamTarget.Subject,
        label: 'Subject',
        name: 'subjectId',
        options: []
    },
    {
        //4
        id: ParamTarget.Classroom,
        label: 'Classroom',
        name: 'classroom',
        options: []
    },
    {
        //5
        id: ParamTarget.QuizRoom,
        label: 'Room',
        name: 'quizRoom',
        options: []
    }
];
let DashboardProcessingMessage_listenToNode = null;
let studentsByTP_stageRange = null;
const ReportType = {
    None: 0,
    ChartAttendance: 1,
    ChartPassedFailed: 2,
    ChartTrend: 3,
}

export default class DashboardScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,
        statusLog_preText: '',

        screen: LayoutScreen.None,
        isSuperAdmin: false,
        isAuthor: false,
        uid: '',
        organizerId: 0,
        loadStatistic: false,

        chart_params_options: chartParamOptions,
        chart_params_setting: defaultSettings,
        isLoaded_chart: false,
        isLoaded_chart_QuizRooms: false,
        // isLoaded_chart_bar_StudentsByTP: false,
        // isLoaded_chart_pie_PassedFailed: false,
        // isLoaded_chart_pie_Attendance: false,
        // isLoaded_chart_line_KdeByClassrooms: false,
        chart_Report: null,
        chart_QuizRooms_Options: null,
        chart_bar_StudentsByTP: null,
        chart_pie_PassedFailed: null,
        chart_pie_Attendance: null,
        chart_line_KdeByClassrooms: null,

        viewChartData_Toggle: false,
        viewChartData_Setting: { title: '', body: '' },
    });

    componentWillUnmount = () => {
        this.DashboardProcessingMessage_Unsubscribe();
    }

    componentDidMount = async () => {
        if (useGlobal.getState().isTeacher) {
            //2024.07.30
            useGlobal.getState().setScreen(Menu['reports'].Screen);
            await Delay(0);
            this.gotoScreen(Menu['reports'].Screen, Menu['reports'].Link);
        }
        useGlobal.getState().setScreen(LayoutScreen.Dashboard);
        const { uid, organizerId, isTeacher } = GetPropIds(useGlobal.getState().user);
        this.setState({
            screen: CheckStringEmpty(useGlobal.getState().screen, LayoutScreen.Dashboard),
            isSuperAdmin: CheckBoolean(useGlobal.getState().isSuperAdmin),
            isAuthor: CheckBoolean(useGlobal.getState().isAuthor),
            uid: uid,
            organizerId: organizerId,
        });
        await Delay(200);
        if (this.state.isAuthor === false)
            return null;
        useAppService.getState().setModal();
        await useAppService.getState().getGroups();
        await Delay(200);
        await useAppService.getState().getSubjects();
        await Delay(200);
        await this.PopulateChartParams();
        await Delay(200);
        // this.SetDefaultChartParamSetting();
        // await Delay(0);
        if (this.state.isDevMode)
            console.log('chart_params_setting =\n' + JSON.stringify(this.state.chart_params_setting));
        useGlobal.getState().setRefreshListCallbackFn(this.ResetAndLoadDashboardStatistic_ViaApi);
        useGlobal.getState().updateFreezeAllControls(false);
        useAppService.getState().setModal();
        await Delay(0);
        this.CheckDefaultLoadSetting();
    }

    gotoScreen = (screen = LayoutScreen.None, link = '') => {
        link = CheckStringEmpty(link);
        if (link.length > 0) {
            useGlobal.getState().setScreen(screen);
            this.setState({ redirectLink: link, redirect: true, });
        }
    }

    //#region === Set Default Load ===
    CheckDefaultLoadSetting = (bypassed = false) => {
        if (bypassed) {
            this.LoadDashboardStatistic_ViaApi();
            return null;
        }
        if (CheckNullValue(this.state.uid) === null)
            return null;
        const { uid, organizerId } = GetPropIds(useGlobal.getState().user);
        let loadStatistic = localStorage.getItem('LoadDashboardStatistic_' + uid + '_' + organizerId);
        // if (this.state.isDevMode)
        //     console.log('loadStatistic = ' + loadStatistic.toString() + ' = ' + (typeof (loadStatistic)).toString());
        if (typeof (loadStatistic) === 'undefined' || loadStatistic === null) {
            localStorage.setItem('LoadDashboardStatistic_' + this.state.uid, 'false');
        }
        loadStatistic = CheckBoolean(loadStatistic);
        this.setState({
            loadStatistic: loadStatistic,
        });
        const checkbox_com = document.getElementById('checkbox-load-statistic');
        if (checkbox_com !== null)
            checkbox_com.checked = CheckBoolean(loadStatistic);
        if (this.state.isDevMode)
            console.log('loadStatistic (' + this.state.organizerId + ') (onload) = ' + loadStatistic.toString());
        // let prompt = false;
        // if (loadStatistic) {
        //     prompt = window.confirm('Continue to load Dashboard Statistic ?');
        //     if (prompt)
        //         this.LoadDashboardStatistic_ViaApi();
        // }
        // if (loadStatistic === false || prompt === false) {
        if (loadStatistic === false) {
            // useAppService.getState().toggleToast('Dashboard', moment().format('lll'), 'Statistic loading has been cancelled.');
            this.setState({
                isLoaded_chart: true,
                isLoaded_chart_QuizRooms: false,
                // isLoaded_chart_bar_StudentsByTP: false,
                // isLoaded_chart_pie_PassedFailed: false,
                // isLoaded_chart_pie_Attendance: false,
                // isLoaded_chart_line_KdeByClassrooms: false,
                chart_Report: null,
                chart_QuizRooms_Options: null,
                chart_bar_StudentsByTP: null,
                chart_pie_PassedFailed: null,
                chart_pie_Attendance: null,
                chart_line_KdeByClassrooms: null,
                isLoading: false,
            });
        }
        else {
            this.LoadDashboardStatistic_ViaApi();
        }
        // setTimeout(() => {
        //     useGlobal.getState().updateFreezeAllControls(true);
        // }, 1000);
    }
    SetDefaultLoadSetting = async () => {
        // await Delay(200);
        const loadStatistic = !this.state.loadStatistic;
        this.setState({
            loadStatistic: loadStatistic,
        });
        const { uid, organizerId } = GetPropIds(useGlobal.getState().user);
        localStorage.setItem('LoadDashboardStatistic_' + uid + '_' + organizerId, loadStatistic.toString());
        const checkbox_com = document.getElementById('checkbox-load-statistic');
        if (checkbox_com !== null)
            checkbox_com.checked = CheckBoolean(loadStatistic);
        // await Delay(200);
        if (loadStatistic) {
            await Delay(200);
            const prompt = window.confirm('Continue to load Dashboard Statistic ?');
            if (prompt)
                this.CheckDefaultLoadSetting();
        }
        if (this.state.isDevMode)
            console.log('SetDefaultLoadSetting = loadStatistic = '
                + localStorage.getItem('LoadDashboardStatistic_' + this.state.uid));
    }
    //#endregion

    //#region === Params ===
    PopulateChartParams = async () => {

        const curr = moment();
        const year = curr.year();
        let chartParamOptions = this.state.chart_params_options;

        //year.
        let year_options = [];
        year_options.push({ value: '', label: '', id: -1 });
        for (let y = 0; y < 3; y++) {
            const id = (year - y).toString();
            year_options.push({ value: id, label: id, id: Number(id) });
        }
        chartParamOptions[0].options = year_options;

        //month.
        chartParamOptions[1].options = [
            { id: 0, value: 0, label: '' },
            { id: 1, value: 1, label: 'January' },
            { id: 2, value: 2, label: 'February' },
            { id: 3, value: 3, label: 'March' },
            { id: 4, value: 4, label: 'April' },
            { id: 5, value: 5, label: 'May' },
            { id: 6, value: 6, label: 'June' },
            { id: 7, value: 7, label: 'July' },
            { id: 8, value: 8, label: 'August' },
            { id: 9, value: 9, label: 'September' },
            { id: 10, value: 10, label: 'October' },
            { id: 11, value: 11, label: 'November' },
            { id: 12, value: 12, label: 'December' }
        ];

        //group.
        chartParamOptions[2].options = useAppService.getState().groupOptions;

        //subject.
        chartParamOptions[3].options = useAppService.getState().subjectOptions;

        //classroom.
        const { success, data } = await useAppService.getState().getClassrooms();
        await Delay(0);
        let classroom_options = [];
        classroom_options.push({ value: '', label: 'Select All', id: 0 });
        if (success && Array.isArray(data) && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                classroom_options.push({
                    id: i + 1,
                    value: data[i],
                    label: data[i]
                });
            }
        }
        chartParamOptions[4].options = classroom_options;

        //save.
        this.setState({
            chart_params_options: chartParamOptions,
        });

        //set placeholder of chartParamOptions to look better.
        const coms = [
            'param-' + chartParamOptions[ParamTarget.Subject - 1].name,
            'param-' + chartParamOptions[ParamTarget.Classroom - 1].name,
            'param-' + chartParamOptions[ParamTarget.QuizRoom - 1].name
        ];
        coms.map((data, key) => {
            const com = document.getElementById(data);
            if (com !== null) {
                let found = false;
                const ccom = com.children[0].children;
                for (let i = 0; i < ccom.length; i++) {
                    if (ccom[i].className.includes('ValueContainer')) {
                        const cccom = ccom[i].children;
                        for (let j = 0; j < cccom.length; j++) {
                            if (cccom[j].className.includes('placeholder')) {
                                cccom[j].style.width = 'max-content';
                                found = true;
                            }
                            if (found)
                                break;
                        }
                    }
                    if (found)
                        break;
                }
            }
            return null;
        });
    }
    ResetChartParamSetting = () => {
        let params = this.state.chart_params_setting;
        params.classroom = '';
        params.quizRoomCode = '';
        this.setState({
            chart_params_setting: params,
        });
        //clear placeholder in select components.
        this.state.chart_params_options.map((data, key) => {
            if (key > 3) {
                const com = document.getElementById('param-' + data.name);
                if (com !== null) {
                    let found = false;
                    const ccom = com.children[0].children;
                    for (let i = 0; i < ccom.length; i++) {
                        if (ccom[i].className.includes('ValueContainer')) {
                            const cccom = ccom[i].children;
                            for (let j = 0; j < cccom.length; j++) {
                                if (cccom[j].className.includes('placeholder') || cccom[j].className.includes('singleValue')) {
                                    cccom[j].innerText = 'Select ' + data.label;
                                    found = true;
                                }
                                if (found)
                                    break;
                            }
                        }
                        if (found)
                            break;
                    }
                }
            }
            return null;
        })

        // this.state.chart_params_options.map((data, key) => {
        //     return this.SetStatisticParam(data.id, '', true);
        // });       
    }
    ChartParamComponent = (data = null) => {
        if (data === null)
            return null;

        return (<div className="chart-param-div" key={'chart-param-div-' + data.name}>
            <label htmlFor={'param-' + data.name} className="form-label">{data.label.toUpperCase()}</label>
            <Select
                id={'param-' + data.name}
                options={data.options}
                placeholder={this.GetChartParamSettingPlaceholder(data)}
                onChange={(option) => {
                    this.SetStatisticParam(data.id, option);
                    // if (this.state.isDevMode)
                    //     console.log(JSON.stringify(option));
                }}
                theme={theme => ({
                    ...theme,
                    width: 'max-content',
                    colors: {
                        ...theme.colors,
                        neutral50: 'black',  // placeholder color
                    }
                })}
                isDisabled={this.state.isLoading}
            />
        </div>);
    }
    GetChartParamSettingPlaceholder = (option = null) => {
        if (option === null)
            return null;
        const optionName = CheckObjectStringEmpty(option, 'name');
        // const optionLabel = CheckObjectStringEmpty(option, 'label');
        // const defaultText = 'Select ' + optionLabel.charAt(0).toUpperCase() + optionLabel.slice(1);
        const defaultText = 'Select ' + CheckObjectStringEmpty(option, 'label');
        const setting = CheckObjectStringEmpty(this.state.chart_params_setting, optionName);
        if (setting === '')
            return defaultText;
        const options = this.state.chart_params_options[Number(option.id) - 1].options;
        switch (option.id) {
            case ParamTarget.Group:
            case ParamTarget.Subject:
                const findIndex_gs = options.findIndex(x => String(x.id) === setting);
                if (findIndex_gs < 0)
                    break;
                else
                    return options[findIndex_gs].label;
            case ParamTarget.Year:
            case ParamTarget.Month:
            case ParamTarget.Classroom:
            case ParamTarget.QuizRoom:
                const findIndex_tmp = options.findIndex(x => String(x.value) === setting);
                if (findIndex_tmp < 0)
                    break;
                else
                    return options[findIndex_tmp].label;
            default:
                return setting;
        }
        return defaultText;
    }
    SetStatisticParam = (target = ParamTarget.None, option = null, reset = false) => {
        if (target === ParamTarget.None || option === null)
            return null;
        const value = CheckObjectStringEmpty(option, 'value');
        const id = CheckObjectNumber(option, 'id');
        let params = this.state.chart_params_setting;
        switch (target) {
            case ParamTarget.Year: params.year = CheckNumber(value, moment().year()); break;
            case ParamTarget.Month: params.month = CheckNumber(value, moment().month() + 1); break;
            case ParamTarget.Group: params.groupId = CheckNumber(id, 1); break;
            case ParamTarget.Subject: params.subjectId = CheckNumber(id, 1); break;
            case ParamTarget.Classroom: params.classroom = CheckStringEmpty(value, ''); break;
            case ParamTarget.QuizRoom: params.quizRoomCode = CheckStringEmpty(value, ''); break;
            default: break;
        }
        this.setState({
            chart_params_setting: params,
        }, () => {
            if (this.state.isDevMode)
                console.log(`chart_params_setting (${Object.keys(ParamTarget)[target]}) (${value}) (${id}) \n ${JSON.stringify(this.state.chart_params_setting)}`);

            if (reset === false) {
                switch (target) {
                    case ParamTarget.Classroom:
                    case ParamTarget.QuizRoom:
                        this.RePopulateChartsByParamOptions();
                        break;
                    default:
                        // this.LoadDashboardStatistic_ViaApi();   //reload.
                        break;
                }
            }
        });
    }
    //#endregion

    //#region === FS Node - Listen ===
    DashboardProcessingMessage_Subscribe = () => {
        if (this.state.isDevMode)
            console.log('DashboardProcessingMessage (Subscribed)');
        DashboardProcessingMessage_listenToNode = onSnapshot(
            query(
                collection(firestore, 'ILE_Portal_Status_Logs'),
                where('uid', '==', this.state.uid)
            ), (querySnapshot) => {
                const statusLogs = [];
                querySnapshot.forEach((doc) => {
                    statusLogs.push(doc.data().status);
                });
                const status = statusLogs.join('');
                if (CheckStringEmpty(status) !== '')
                    // useAppService.getState().setModal('', this.state.statusLog_preText + '<br />' + status, null, AlertMode.Loading);
                    useAppService.getState().setModal('', CheckStringEmpty(status, this.state.statusLog_preText), null, AlertMode.Loading);
                if (this.state.isDevMode)
                    console.log('DashboardProcessingMessage (Logs)\n' + statusLogs.join('\n'));
            });
    }
    DashboardProcessingMessage_Unsubscribe = () => {
        if (DashboardProcessingMessage_listenToNode !== null) {
            if (typeof (DashboardProcessingMessage_listenToNode) === 'function') {
                DashboardProcessingMessage_listenToNode();
                useAppService.getState().setModal();
            }
        }
        if (this.state.isDevMode)
            console.log('DashboardProcessingMessage (Unsubscribed)');
    }
    //#endregion

    //#region === Load Statistic. ===
    ResetAndLoadDashboardStatistic_ViaApi = () => {
        this.ResetChartParamSetting();
        // this.LoadDashboardStatistic_ViaApi();
        // this.CheckDefaultLoadSetting(true);
        this.CheckDefaultLoadSetting();
    }
    LoadDashboardStatistic_ViaApi = async (bypassed = false) => {

        useAppService.getState().toggleToast('Dashboard', moment().format('lll'), 'loading dashboard statistic...', ToastPosition.TopCenter);
        if (this.state.isDevMode === false) {
            const preText = 'loading dashboard statistic report...';
            this.setState({
                statusLog_preText: preText,
            });
            useAppService.getState().setModal('', preText, null, AlertMode.Loading);
            if (this.state.isDevMode === false) {
                await setDoc(doc(firestore, 'ILE_Portal_Status_Logs', this.state.uid), { 'status': preText, 'log': '', 'uid': this.state.uid });
                this.DashboardProcessingMessage_Subscribe();
            }
            // useGlobal.getState().updateFreezeAllControls(true);
        }
        this.setState({
            isLoaded_chart: false,
            isLoaded_chart_QuizRooms: false,
            // isLoaded_chart_bar_StudentsByTP: false,
            // isLoaded_chart_pie_PassedFailed: false,
            // isLoaded_chart_pie_Attendance: false,
            // isLoaded_chart_line_KdeByClassrooms: false,
            chart_Report: null,
            chart_QuizRooms_Options: null,
            chart_bar_StudentsByTP: null,
            chart_pie_PassedFailed: null,
            chart_pie_Attendance: null,
            chart_line_KdeByClassrooms: null,
            isLoading: true,
        });

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        if (authorId === 0 || organizerId === 0)
            return null;

        if (this.state.isDevMode)
            console.log(`LoadDashboardStatistic_ViaApi ${authorId} ${organizerId} \n ${JSON.stringify(this.state.chart_params_setting)}`);

        // let success = false;
        let messages = [];
        let responseData = null;
        let done = false;
        const params = this.state.chart_params_setting;

        if (bypassed === false) {
            await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Dashboard/Statistic/Report',
                // Api/LearningCentre/Dashboard/Statistic/Report
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        organizerId,
                        authorId,
                        year: params.year,
                        month: params.month,
                        groupId: params.groupId,
                        subjectId: params.subjectId,
                        classroom: params.classroom,
                    }),
                })
                .then(res => res.json())
                .then(data => {
                    if (this.state.isDevMode)
                        console.log(`LoadDashboardStatistic_ViaApi (response) \n ${JSON.stringify(data)}`);

                    // success = CheckBoolean(data.success);
                    // const result = JSON.parse(JSON.stringify(data));
                    if (CheckObjectBoolean(data, 'success')) {
                        responseData = data['data'];
                    }
                    else {
                        if (CheckObjectNullValue(data, 'message') !== null)
                            messages.push(data['message']);
                    }
                    done = true;
                })
                .catch(error => {
                    messages.push(error.message);
                    if (this.state.isDevMode)
                        console.log('Error', 'api - dashboard statistic - load (error)\n' + error.message);
                    done = true;
                });
        }
        await DelayUntil(() => done === true);

        // let data_report = null;
        let data_chart_QuizRooms_options = null;
        let data_chart_bar_StudentsByTP = null;
        let data_chart_pie_PassedFailed = null;
        let data_chart_pie_Attendance = null;
        let data_chart_line_KdeByClassrooms = null;

        if (responseData !== null) {

            // const report = CapitalizeJsonKeys(responseData);
            const report = CapitalizeJsonKeys(JSON.parse(JSON.stringify(responseData)));
            // const report = JSON.parse(JSON.stringify(responseData));
            // useAppService.getState().setModal('Debug', JSON.stringify(report), null, AlertMode.Debug);
            if (this.state.isDevMode)
                console.log(JSON.stringify(report));
            // data_report = report;
            this.setState({
                chart_Report: report,
            });

            const chart_QuizRooms_options = this.PopulateChartParamForQuizRoomOptions();
            const {
                chart_bar_StudentsByTP,
                chart_pie_PassedFailed,
                chart_pie_Attendance,
                chart_line_KdeByClassrooms
            } = await this.PopulateChartsByResponseData();
            data_chart_QuizRooms_options = chart_QuizRooms_options;
            data_chart_bar_StudentsByTP = chart_bar_StudentsByTP;
            data_chart_pie_PassedFailed = chart_pie_PassedFailed;
            data_chart_pie_Attendance = chart_pie_Attendance;
            data_chart_line_KdeByClassrooms = chart_line_KdeByClassrooms;
            if (this.state.isDevMode) {
                console.log('chart_QuizRooms_options =\n' + JSON.stringify(chart_QuizRooms_options));
                console.log('chart_bar_StudentsByTP =\n' + JSON.stringify(chart_bar_StudentsByTP));
                console.log('chart_pie_PassedFailed =\n' + JSON.stringify(chart_pie_PassedFailed));
                console.log('chart_pie_Attendance =\n' + JSON.stringify(chart_pie_Attendance));
                console.log('chart_line_KdeByClassrooms =\n' + JSON.stringify(chart_line_KdeByClassrooms));
            }
        }

        // if (messages.length > 0)
        //     useAppService.getState().setModal(Locale("error", this.state.locale), messages.join('<br />'));

        this.setState({
            isLoaded_chart: true,
            isLoaded_chart_QuizRooms: true,
            // isLoaded_chart_bar_StudentsByTP: true,
            // isLoaded_chart_pie_PassedFailed: true,
            // isLoaded_chart_pie_Attendance: true,
            // isLoaded_chart_line_KdeByClassrooms: true,
            // chart_Report: data_report,
            chart_QuizRooms_Options: data_chart_QuizRooms_options,
            chart_bar_StudentsByTP: data_chart_bar_StudentsByTP,
            chart_pie_PassedFailed: data_chart_pie_PassedFailed,
            chart_pie_Attendance: data_chart_pie_Attendance,
            chart_line_KdeByClassrooms: data_chart_line_KdeByClassrooms,
            isLoading: false,
        });

        // useGlobal.getState().updateFreezeAllControls(false);
        useAppService.getState().setModal();
        if (this.state.isDevMode === false)
            this.DashboardProcessingMessage_Unsubscribe();
    }
    PopulateChartParamForQuizRoomOptions = () => {
        let quizRoomOptions = [];
        quizRoomOptions.push({ value: '', label: 'Select All', id: 0 });
        const report = this.state.chart_Report;
        if (CheckObjectNullValue(report, 'Rooms') !== null) {
            if (Array.isArray(report['Rooms'])) {
                report['Rooms'].map((data, key) => {
                    if (CheckObjectNullValue(data, 'Value') !== null) {
                        if (CheckObjectNullValue(data['Value'], 'Room') !== null) {
                            const _room = data['Value']['Room'];
                            quizRoomOptions.push({
                                value: CheckObjectStringEmpty(_room, 'RoomCode'),
                                label: CheckObjectStringEmpty(_room, 'RoomTitle'),
                                id: CheckObjectNumber(data['Value'], 'Id'),
                            });
                        }
                    }
                    return null;
                });
            }
        }
        const data_chart_QuizRooms_options = {
            id: ParamTarget.QuizRoom,
            label: 'Room',
            name: 'room',
            options: quizRoomOptions,
        };
        return data_chart_QuizRooms_options;
    }
    RePopulateChartsByParamOptions = async () => {

        if (this.state.chart_Report === null) {
            useAppService.getState().toggleToast('Dashboard', moment().format('lll'),
                'statistic not available, please reload dashboard statistic.', ToastPosition.TopCenter);
            return null;
        }
        //all classrooms & rooms.
        const {
            chart_bar_StudentsByTP,
            chart_pie_PassedFailed,
            chart_pie_Attendance,
            chart_line_KdeByClassrooms
        } = await this.PopulateChartsByResponseData();
        await Delay(200);
        this.setState({
            chart_bar_StudentsByTP: chart_bar_StudentsByTP,
            chart_pie_PassedFailed: chart_pie_PassedFailed,
            chart_pie_Attendance: chart_pie_Attendance,
            chart_line_KdeByClassrooms: chart_line_KdeByClassrooms,
        });
    }
    //for all rooms.
    PopulateChartsByResponseData = async () => {
        let data_chart_bar_StudentsByTP = null;
        let data_chart_pie_PassedFailed = null;
        let data_chart_pie_Attendance = null;
        let data_chart_line_KdeByClassrooms = null;
        const _report = this.state.chart_Report;
        const profiles = CheckObjectNullValue(_report, 'Profiles', []);
        const { classroom, quizRoomCode } = this.state.chart_params_setting;
        await Delay(0);

        //#region === chart_bar_StudentsByTP. ===
        let studentsByTP_EducationStages_Range = {};
        let studentsByTP_Categories = [];
        let studentsByTP_Totals = [];
        if (CheckObjectNullValue(_report, 'EducationStages') !== null) {
            if (Array.isArray(_report['EducationStages'])) {
                _report['EducationStages'].map((data, key) => {
                    studentsByTP_Categories.push(CheckObjectStringEmpty(data, 'Name'));
                    studentsByTP_EducationStages_Range[CheckObjectStringEmpty(data, 'Name')] = {
                        RangeBegin: CheckObjectNumber(data, 'RangeBegin'),
                        RangeEnd: CheckObjectNumber(data, 'RangeEnd')
                    };
                    studentsByTP_Totals.push(0);
                    return null;
                });
            }
        }
        const { reports: trend_reports } = this.GetRoomsAndReports(ReportType.ChartTrend);
        trend_reports.map((base, key) => {
            const { roomCode, report } = base;
            let proceed = true;
            if (quizRoomCode !== '' && quizRoomCode !== roomCode)
                proceed = false;
            if (proceed) {
                const { StudentEducationStages } = report;
                if (CheckNullValue(StudentEducationStages) !== null) {
                    Object.keys(StudentEducationStages).map((name, key) => {
                        const stage = StudentEducationStages[name];
                        if (Array.isArray(stage)) {
                            let list = [];
                            stage.map((id, key) => {
                                let profile = null;
                                let findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === roomCode);
                                if (findIndex > -1) {
                                    profile = profiles[findIndex];
                                }
                                else {
                                    findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === '');
                                    if (findIndex > -1)
                                        profile = profiles[findIndex];
                                }
                                if (profile !== null) {
                                    if (classroom === '')
                                        list.push(id);
                                    if (classroom !== '' && CheckObjectStringEmpty(profile, 'Classroom') === classroom)
                                        list.push(id);
                                }
                                return null;
                            });
                            studentsByTP_Totals[key] += list.length;
                        }
                        return null;
                    });
                }
            }
            return null;
        });
        data_chart_bar_StudentsByTP = {
            series: [{ name: 'Student(s)', data: studentsByTP_Totals }],
            categories: studentsByTP_Categories,
            stageRange: studentsByTP_EducationStages_Range, //for debug only.
        };
        studentsByTP_stageRange = studentsByTP_EducationStages_Range;   //global var. for chart, important.
        //#endregion

        //#region === chart_pie_PassedFailed. ===
        let passedFailed_rooms_base = [];
        const { reports: passedFailed_reports } = this.GetRoomsAndReports(ReportType.ChartPassedFailed);
        passedFailed_reports.map((base, key) => {
            const { roomCode, report } = base;
            let proceed = true;
            if (quizRoomCode !== '' && quizRoomCode !== roomCode)
                proceed = false;
            if (proceed) {
                let passedStudents = [];
                let failedStudents = [];
                const { PassedStudentIds, FailedStudentIds } = report;
                if (Array.isArray(PassedStudentIds)) {
                    PassedStudentIds.map((id, key) => {
                        let profile = null;
                        let findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === roomCode);
                        if (findIndex > -1) {
                            profile = profiles[findIndex];
                        }
                        else {
                            findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === '');
                            if (findIndex > -1)
                                profile = profiles[findIndex];
                        }
                        if (profile !== null) {
                            if (classroom === '')
                                passedStudents.push(profile);
                            if (classroom !== '' && CheckObjectStringEmpty(profile, 'Classroom') === classroom)
                                passedStudents.push(profile);
                        }
                        return null;
                    });
                }
                if (Array.isArray(FailedStudentIds)) {
                    FailedStudentIds.map((id, key) => {
                        let profile = null;
                        let findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === roomCode);
                        if (findIndex > -1) {
                            profile = profiles[findIndex];
                        }
                        else {
                            findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === '');
                            if (findIndex > -1)
                                profile = profiles[findIndex];
                        }
                        if (profile !== null) {
                            if (classroom === '')
                                failedStudents.push(profile);
                            if (classroom !== '' && CheckObjectStringEmpty(profile, 'Classroom') === classroom)
                                failedStudents.push(profile);
                        }
                        return null;
                    });
                }
                passedFailed_rooms_base.push({
                    roomCode,
                    total: passedStudents.length + failedStudents.length,
                    totalPassed: passedStudents.length,
                    totalFailed: failedStudents.length,
                    // passedStudents: passedStudents,
                    // failedStudents: failedStudents
                });
            }
            return null;
        });
        let passed = 0;
        let failed = 0;
        passedFailed_rooms_base.map((base, key) => {
            const { roomCode, totalPassed, totalFailed } = base;
            let proceed = true;
            if (quizRoomCode !== '' && quizRoomCode !== roomCode)
                proceed = false;
            if (proceed) {
                passed += totalPassed;
                failed += totalFailed;
            }
            return null;
        });
        const passedFailed_series = [passed, failed];
        data_chart_pie_PassedFailed = {
            options: {
                labels: ['Passed', 'Failed'],
            },
            series: passedFailed_series,
        };
        //#region old codes.
        // if (quizRoomCode === '') {
        //     //all rooms.
        //     passedFailed_series = [CheckObjectNumber(_report, 'GrandTotalPassed'), CheckObjectNumber(_report, 'GrandTotalFailed')];
        // }
        // else {
        //     //single room.
        //     const report = this.GetReportInRoom(_report, quizRoomCode);
        //     let passed = 0;
        //     let failed = 0;
        //     if (report !== null) {
        //         if (CheckObjectNullValue(report, 'ChartPassedFailed') !== null) {
        //             if (CheckObjectNullValue(report['ChartPassedFailed'], 'PassedStudentIds') !== null)
        //                 if (Array.isArray(report['ChartPassedFailed']['PassedStudentIds'])) {
        //                     if (classroom === '') {
        //                         passed = report['ChartPassedFailed']['PassedStudentIds'].length;
        //                     }
        //                     else {
        //                         let list = [];
        //                         report['ChartPassedFailed']['PassedStudentIds'].map((id, key) => {
        //                             const findIndex = profiles.findIndex(x => x.Id === id);
        //                             if (findIndex > -1)
        //                                 if (CheckObjectStringEmpty(profiles[findIndex], 'Classroom') === classroom)
        //                                     list.push(id);
        //                             return null;
        //                         });
        //                         passed = list.length;
        //                     }
        //                 }
        //             if (CheckObjectNullValue(report['ChartPassedFailed'], 'FailedStudentIds') !== null) {
        //                 if (Array.isArray(report['ChartPassedFailed']['FailedStudentIds'])) {
        //                     if (classroom === '') {
        //                         failed = report['ChartPassedFailed']['FailedStudentIds'].length;
        //                     }
        //                     else {
        //                         let list = [];
        //                         report['ChartPassedFailed']['FailedStudentIds'].map((id, key) => {
        //                             const findIndex = profiles.findIndex(x => x.Id === id);
        //                             if (findIndex > -1)
        //                                 if (CheckObjectStringEmpty(profiles[findIndex], 'Classroom') === classroom)
        //                                     list.push(id);
        //                             return null;
        //                         });
        //                         failed = list.length;
        //                     }
        //                 }
        //             }
        //         }
        //     }
        //     passedFailed_series = [passed, failed];
        // }
        // data_chart_pie_PassedFailed = {
        //     options: {
        //         labels: ['Passed', 'Failed'],
        //     },
        //     series: passedFailed_series,
        // };
        //#endregion
        //#endregion

        //#region === data_chart_pie_Attendance. ===
        let attendance_rooms_base = [];
        const { reports: attendance_reports } = this.GetRoomsAndReports(ReportType.ChartAttendance);
        attendance_reports.map((base, key) => {
            const { roomCode, report } = base;
            let proceed = true;
            if (quizRoomCode !== '' && quizRoomCode !== roomCode)
                proceed = false;
            if (proceed) {
                let attendedStudents = [];
                let unattendedStudents = [];
                const { AttendedStudentIds, UnattendedStudentIds } = report;
                if (Array.isArray(AttendedStudentIds)) {
                    AttendedStudentIds.map((id, key) => {
                        let profile = null;
                        let findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === roomCode);
                        if (findIndex > -1) {
                            profile = profiles[findIndex];
                        }
                        else {
                            findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === '');
                            if (findIndex > -1)
                                profile = profiles[findIndex];
                        }
                        if (profile !== null) {
                            if (classroom === '')
                                attendedStudents.push(profile);
                            if (classroom !== '' && CheckObjectStringEmpty(profile, 'Classroom') === classroom)
                                attendedStudents.push(profile);
                        }
                        return null;
                    });
                }
                if (Array.isArray(UnattendedStudentIds)) {
                    UnattendedStudentIds.map((id, key) => {
                        let profile = null;
                        let findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === roomCode);
                        if (findIndex > -1) {
                            profile = profiles[findIndex];
                        }
                        else {
                            findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === '');
                            if (findIndex > -1)
                                profile = profiles[findIndex];
                        }
                        if (profile !== null) {
                            if (classroom === '')
                                unattendedStudents.push(profile);
                            if (classroom !== '' && CheckObjectStringEmpty(profile, 'Classroom') === classroom)
                                unattendedStudents.push(profile);
                        }
                        return null;
                    });
                }
                attendance_rooms_base.push({
                    roomCode,
                    total: attendedStudents.length + unattendedStudents.length,
                    totalAttended: attendedStudents.length,
                    totalUnattended: unattendedStudents.length,
                    // attendedStudents,
                    // unattendedStudents
                });
            }
            return null;
        });
        let present = 0;
        let absent = 0;
        attendance_rooms_base.map((base, key) => {
            const { roomCode, totalAttended, totalUnattended } = base;
            let proceed = true;
            if (quizRoomCode !== '' && quizRoomCode !== roomCode)
                proceed = false;
            if (proceed) {
                present += totalAttended;
                absent += totalUnattended;
            }
            return null;
        });
        const attendance_series = [present, absent];
        data_chart_pie_Attendance = {
            options: {
                labels: ['Present', 'Absent'],
            },
            series: attendance_series,
        };
        //#region  old codes.
        // if (quizRoomCode === '') {
        //     //all rooms.
        //     attendance_series = [CheckObjectNumber(_report, 'GrandTotalPresent'), CheckObjectNumber(_report, 'GrandTotalAbsent')];
        // }
        // else {
        //     //single room.
        //     const report = this.GetReportInRoom(_report, quizRoomCode);
        //     let present = 0;
        //     let absent = 0;
        //     if (report !== null) {
        //         if (CheckObjectNullValue(report, 'ChartAttendance') !== null) {
        //             if (CheckObjectNullValue(report['ChartAttendance'], 'AttendedStudentIds') !== null)
        //                 if (Array.isArray(report['ChartAttendance']['AttendedStudentIds'])) {
        //                     if (classroom === '') {
        //                         present = report['ChartAttendance']['AttendedStudentIds'].length;
        //                     }
        //                     else {
        //                         let list = [];
        //                         report['ChartAttendance']['AttendedStudentIds'].map((id, key) => {
        //                             const findIndex = profiles.findIndex(x => x.Id === id);
        //                             if (findIndex > -1)
        //                                 if (CheckObjectStringEmpty(profiles[findIndex], 'Classroom') === classroom)
        //                                     list.push(id);
        //                             return null;
        //                         });
        //                         present = list.length;
        //                     }
        //                 }
        //             if (CheckObjectNullValue(report['ChartAttendance'], 'UnattendedStudentIds') !== null)
        //                 if (Array.isArray(report['ChartAttendance']['UnattendedStudentIds'])) {
        //                     if (classroom === '') {
        //                         absent = report['ChartAttendance']['UnattendedStudentIds'].length;
        //                     }
        //                     else {
        //                         let list = [];
        //                         report['ChartAttendance']['UnattendedStudentIds'].map((id, key) => {
        //                             const findIndex = profiles.findIndex(x => x.Id === id);
        //                             if (findIndex > -1)
        //                                 if (CheckObjectStringEmpty(profiles[findIndex], 'Classroom') === classroom)
        //                                     list.push(id);
        //                             return null;
        //                         });
        //                         absent = list.length;
        //                     }
        //                 }
        //         }
        //     }
        //     attendance_series = [present, absent];
        // }
        // data_chart_pie_Attendance = {
        //     options: {
        //         labels: ['Present', 'Absent'],
        //     },
        //     series: attendance_series,
        // };
        //#endregion
        //#endregion

        //#region === chart_line_KdeByClassrooms.
        data_chart_line_KdeByClassrooms = {};
        //#endregion

        await Delay(0);
        return {
            chart_bar_StudentsByTP: data_chart_bar_StudentsByTP,
            chart_pie_PassedFailed: data_chart_pie_PassedFailed,
            chart_pie_Attendance: data_chart_pie_Attendance,
            chart_line_KdeByClassrooms: data_chart_line_KdeByClassrooms
        };
    }
    GetReportInRoom = (t_report, quizRoomCode) => {
        let report = null;
        if (CheckObjectNullValue(t_report, 'Rooms') !== null) {
            if (Array.isArray(t_report['Rooms'])) {
                const findIndex_room = t_report['Rooms'].findIndex(x => x.Key === quizRoomCode);
                if (findIndex_room > -1) {
                    if (CheckObjectNullValue(t_report['Rooms'][findIndex_room], 'Value') !== null)
                        if (CheckObjectNullValue(t_report['Rooms'][findIndex_room]['Value'], 'Report') !== null)
                            report = t_report['Rooms'][findIndex_room]['Value']['Report'];
                }
            }
        }
        return report;
    }
    GetProfilesInRoom = (t_report, quizRoomCode) => {
        let profiles = null;
        if (CheckObjectNullValue(t_report, 'Rooms') !== null)
            if (Array.isArray(t_report['Rooms'])) {
                const findIndex_room = t_report['Rooms'].findIndex(x => x.Key === quizRoomCode);
                if (findIndex_room > -1) {
                    if (CheckObjectNullValue(t_report['Rooms'][findIndex_room], 'Value') !== null)
                        if (CheckObjectNullValue(t_report['Rooms'][findIndex_room]['Value'], 'Profiles') !== null)
                            profiles = t_report['Rooms'][findIndex_room]['Value']['Profiles'];
                }
            }
        return profiles;
    }
    //#endregion

    //#region === View Statistic Data ===
    GetRoomsAndReports = (reportType = ReportType.None) => {
        const rooms_base = CheckObjectNullValue(this.state.chart_Report, 'Rooms', []);
        const { quizRoomCode } = this.state.chart_params_setting;
        let rooms = [];
        let reports = [];
        rooms_base.map((data, key) => {
            const roomKey = CheckObjectNullValue(data, 'Key');
            let proceed = true;
            if (quizRoomCode !== '' && quizRoomCode !== roomKey)
                proceed = false;
            if (proceed) {
                const roomValue = CheckObjectNullValue(data, 'Value');
                if (roomValue !== null) {
                    const room = CheckObjectNullValue(roomValue, 'Room');
                    if (room !== null)
                        rooms.push(room);
                    const report_base = CheckObjectNullValue(roomValue, 'Report');
                    if (report_base !== null) {
                        let report = CheckObjectNullValue(report_base, Object.keys(ReportType)[reportType]);
                        if (report !== null)
                            reports.push({ roomCode: roomKey, report });
                    }
                }
            }
            return null;
        });
        return { rooms, reports };
    }
    GetRoomDataPropertyValue = (data = null) => {
        if (data === null)
            return '-';
        const type = typeof (data);
        switch (type) {
            case 'string': return CheckStringEmpty(data);
            case 'number': return CheckNumber(data);
            case 'boolean': return CheckBoolean(data) ? '✔' : '-';
            default: return '-';
        }
    }
    ReformatRoomDataProperties = (roomData = null) => {
        if (roomData === null)
            return null;

        const group = GetGroup(CheckObjectNumber(roomData, 'GroupId'));
        if (group !== null)
            roomData['Group'] = CheckObjectStringEmpty(group, 'label', '-');
        const subject = GetSubject(CheckObjectNumber(roomData, 'SubjectId'));
        if (subject !== null)
            roomData['Subject'] = CheckObjectStringEmpty(subject, 'label', '-');

        roomData['DateStart'] = moment(CheckObjectStringEmpty(roomData, 'DateStart')).format('LL');
        roomData['DateEnd'] = moment(CheckObjectStringEmpty(roomData, 'DateEnd')).format('LL');
        const timeStart = `${moment(moment().format('YYYY-MM-DD ') + CheckObjectStringEmpty(roomData, 'TimeStart')).format('hh:mm A')} (${CheckObjectStringEmpty(roomData, 'TimeStart')})`;
        roomData['TimeStart'] = timeStart;
        const timeEnd = `${moment(moment().format('YYYY-MM-DD ') + CheckObjectStringEmpty(roomData, 'TimeEnd')).format('hh:mm A')} (${CheckObjectStringEmpty(roomData, 'TimeEnd')})`;
        roomData['TimeEnd'] = timeEnd;
        roomData['Duration'] = GetDurationText(CheckObjectNumber(roomData, 'Duration'));

        delete roomData['RoomId'];
        delete roomData['OrganizerId'];
        delete roomData['CenterUserId'];
        delete roomData['AuthorId'];
        delete roomData['EventCode'];
        delete roomData['QuestionSetUniqueId'];
        delete roomData['SubjectId'];
        delete roomData['RoomType'];
        delete roomData['ExtraUrl'];
        delete roomData['EnableStatisticReport'];
        delete roomData['DemoTestingChecking'];
        delete roomData['Answers'];
        delete roomData['AuthorUser'];
        delete roomData['GroupId'];
        delete roomData['Organizer'];
        delete roomData['OrganizerIdentity'];
        // delete roomData['Group'];
        delete roomData['Grade'];
        // delete roomData['Subject'];
        delete roomData['RoomEndedDateTime'];
        delete roomData['Id'];
        delete roomData['MarkAsDeleted'];
        delete roomData['CreatedOnUtc'];
        delete roomData['LastUpdatedOnUtc'];
        delete roomData['DeletedOnUtc'];
        delete roomData['CreatedByUserId'];
        delete roomData['UpdatedByUserId'];
        delete roomData['DeletedByUserId'];

        return roomData;
    }
    ReformatRoomDataPerpertyName = (name = '') => {
        if (CheckNullValue(name) === null)
            return '-';
        if (name.includes('QnQty'))
            name = name.replace('QnQty', 'TotalQuestion');
        return name.replace(/([A-Z])/g, ' $1').trim()
    }
    ViewChartData_StudentsByTP = () => {
        let components = [];
        // console.log('ViewChartData_StudentsByTP');

        const _report = this.state.chart_Report;
        const { classroom, quizRoomCode } = this.state.chart_params_setting;

        const profiles = CheckObjectNullValue(_report, 'Profiles', []);
        if (Array.isArray(profiles) === false)
            useAppService.getState().setModal('Chart :: Trend', 'Profiles not found.<br />No data to display.');

        const { rooms, reports } = this.GetRoomsAndReports(ReportType.ChartTrend);
        if (reports.length === 0)
            useAppService.getState().setModal('Chart :: Trend', 'No data to display.');

        const title = 'Monthly TP for ('
            + this.GetChartParamSettingPlaceholder(this.state.chart_params_options.find(x => x.id === ParamTarget.Month))
            + '), '
            + this.GetChartParamSettingPlaceholder(this.state.chart_params_options.find(x => x.id === ParamTarget.Year));

        let studentsByTP_EducationStages_Range = {};
        let studentsByTP_Categories = [];
        if (CheckObjectNullValue(_report, 'EducationStages') !== null) {
            if (Array.isArray(_report['EducationStages'])) {
                _report['EducationStages'].map((data, key) => {
                    studentsByTP_Categories.push(CheckObjectStringEmpty(data, 'Name'));
                    studentsByTP_EducationStages_Range[CheckObjectStringEmpty(data, 'Name')] = {
                        RangeBegin: CheckObjectNumber(data, 'RangeBegin'),
                        RangeEnd: CheckObjectNumber(data, 'RangeEnd')
                    };
                    return null;
                });
            }
        }

        let rooms_base = [];
        reports.map((base, key) => {
            const { roomCode, report } = base;
            let studentsByTP_series = [];
            let total = 0;
            let proceed = true;
            if (quizRoomCode !== '' && quizRoomCode !== roomCode)
                proceed = false;
            if (proceed) {
                const { StudentEducationStages } = report;
                if (CheckNullValue(StudentEducationStages) !== null) {
                    Object.keys(StudentEducationStages).map((name, key) => {
                        const stage = StudentEducationStages[name];
                        if (Array.isArray(stage)) {
                            let list = [];
                            stage.map((id, key) => {
                                let profile = null;
                                let findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === roomCode);
                                if (findIndex > -1) {
                                    profile = profiles[findIndex];
                                }
                                else {
                                    findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === '');
                                    if (findIndex > -1)
                                        profile = profiles[findIndex];
                                }
                                if (profile !== null) {
                                    if (classroom === '')
                                        list.push(profile);
                                    if (classroom !== '' && CheckObjectStringEmpty(profile, 'Classroom') === classroom)
                                        list.push(profile);
                                }
                                return null;
                            });
                            studentsByTP_series.push(list);
                            total += list.length;
                        }
                        return null;
                    });
                }
            }
            return rooms_base.push({
                roomCode,
                total,
                studentsByTP_series,
            });
        });

        let rooms_body = [];
        rooms_base.map((base, key) => {
            const { roomCode, studentsByTP_series } = base;
            let room = null;
            const room_index = rooms.findIndex(x => x.RoomCode === roomCode);
            if (room_index > -1)
                room = this.ReformatRoomDataProperties(rooms[room_index]);
            let roomProperties = [];
            Object.keys(room).map((name, key) => {
                return roomProperties.push({ name: name, value: CheckObjectNullValue(room, name), });
            });
            return rooms_body.push(<Tabs defaultActiveKey={studentsByTP_Categories.length + 1} id={`tabs-passed-failed-child-${key}`}>
                {
                    studentsByTP_Categories.map((name, key) => {
                        return (<Tab eventKey={key} title={name} className={`base-tab tab-result-${name}`}>
                            <span className="span-total">Student(s) in {name} : {studentsByTP_series[key].length}</span>
                            <table className="table table-bordered table-hover tbStyle">
                                <thead>
                                    <tr>
                                        <th style={{ width: 40 }}>#</th>
                                        <th style={{ width: 0 }}>Classroom</th>
                                        <th style={{ width: 60 }}>Scores</th>
                                        <th style={{ textAlign: 'left' }}>Name</th>
                                        <th style={{ width: 220 }}>Email</th>
                                        <th style={{ width: 220 }}>Guardian</th>
                                        <th style={{ width: 135 }}>Contact Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        studentsByTP_series[key].length === 0 ?
                                            <tr><td colSpan={15}>-n/a-</td></tr>
                                            :
                                            studentsByTP_series[key].map((data, key) => {
                                                return <tr key={'tb-' + name + '-' + CheckObjectStringEmpty(data, 'Id')}>
                                                    <td>{key + 1}</td>
                                                    <td>{CheckObjectStringEmpty(data, 'Classroom', '-')}</td>
                                                    <td>{CheckObjectNumber(data, 'Scores').toFixed(2)}</td>
                                                    <td style={{ textAlign: 'left' }}>{CheckObjectStringEmpty(data, 'Name', '-')}</td>
                                                    <td>{CheckObjectStringEmpty(data, 'Email', '-')}</td>
                                                    <td>{CheckObjectStringEmpty(data, 'Guardian', '-')}</td>
                                                    <td>{CheckObjectStringEmpty(data, 'ContactNumber', '-')}</td>
                                                </tr>;
                                            })
                                    }
                                </tbody>
                            </table>
                        </Tab>);
                    })
                }
                <Tab eventKey={studentsByTP_Categories.length + 1} title='Room Detail' className="base-tab">
                    <div className="tab-result-room-detail">
                        <ul className="ul-tb">
                            {
                                roomProperties.map((data, key) => {
                                    return (<li className="th">{this.ReformatRoomDataPerpertyName(data.name)}</li>);
                                })
                            }
                        </ul>
                        <ul className="ul-tb ul-tb-body">
                            {
                                roomProperties.map((data, key) => {
                                    return (<li>{this.GetRoomDataPropertyValue(data.value)}</li>);
                                })
                            }
                        </ul>
                    </div>
                </Tab>
            </Tabs>);
        });
        rooms_body.map((body, key) => {
            const { roomCode, total } = rooms_base[key];
            return components.push(<Tab
                eventKey={key}
                title={`Room (${roomCode})`}
                className="base-tab tab-result-room"
            ><div className="span-total">Total Student(s) : {total}</div>{body}</Tab>);
        });
        this.ToggleViewChartData(title, <Tabs defaultActiveKey={0} id='tabs-trend-parent'>{components}</Tabs>);
    }
    ViewChartData_PassedFailed = () => {
        let components = [];
        // console.log('ViewChartData_PassedFailed');

        const { classroom } = this.state.chart_params_setting;

        const profiles = CheckObjectNullValue(this.state.chart_Report, 'Profiles', []);
        if (Array.isArray(profiles) === false)
            useAppService.getState().setModal('Chart :: Passed / Failed', 'Profiles not found.<br />No data to display.');

        const { rooms, reports } = this.GetRoomsAndReports(ReportType.ChartPassedFailed);
        if (reports.length === 0)
            useAppService.getState().setModal('Chart :: Passed / Failed', 'No data to display.');

        let rooms_base = [];
        reports.map((base, key) => {
            let passedStudents = [];
            let failedStudents = [];
            const { roomCode, report } = base;
            const { PassedStudentIds, FailedStudentIds } = report;
            if (Array.isArray(PassedStudentIds)) {
                PassedStudentIds.map((id, key) => {
                    let profile = null;
                    let findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === roomCode);
                    if (findIndex > -1) {
                        profile = profiles[findIndex];
                    }
                    else {
                        findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === '');
                        if (findIndex > -1)
                            profile = profiles[findIndex];
                    }
                    if (profile !== null) {
                        if (classroom === '')
                            passedStudents.push(profile);
                        if (classroom !== '' && CheckObjectStringEmpty(profile, 'Classroom') === classroom)
                            passedStudents.push(profile);
                    }
                    return null;
                });
            }
            if (Array.isArray(FailedStudentIds)) {
                FailedStudentIds.map((id, key) => {
                    let profile = null;
                    let findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === roomCode);
                    if (findIndex > -1) {
                        profile = profiles[findIndex];
                    }
                    else {
                        findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === '');
                        if (findIndex > -1)
                            profile = profiles[findIndex];
                    }
                    if (profile !== null) {
                        if (classroom === '')
                            failedStudents.push(profile);
                        if (classroom !== '' && CheckObjectStringEmpty(profile, 'Classroom') === classroom)
                            failedStudents.push(profile);
                    }
                    return null;
                });
            }
            return rooms_base.push({
                roomCode,
                total: passedStudents.length + failedStudents.length,
                // totalPassed: passedStudents.length,
                // totalFailed: failedStudents.length,
                passedStudents: passedStudents,
                failedStudents: failedStudents
            });
        });

        let rooms_body = [];
        rooms_base.map((base, key) => {
            const { roomCode, passedStudents, failedStudents } = base;
            let room = null;
            const room_index = rooms.findIndex(x => x.RoomCode === roomCode);
            if (room_index > -1)
                room = this.ReformatRoomDataProperties(rooms[room_index]);
            let roomProperties = [];
            Object.keys(room).map((name, key) => {
                return roomProperties.push({ name: name, value: CheckObjectNullValue(room, name), });
            });
            return rooms_body.push(<Tabs defaultActiveKey={2} id={`tabs-passed-failed-child-${key}`}>
                <Tab eventKey={0} title='Passed' className="base-tab tab-result-passed">
                    <span className="span-total">Total Passed(s): {passedStudents.length}</span>
                    <table className="table table-bordered table-hover tbStyle">
                        <thead>
                            <tr>
                                <th style={{ width: 40 }}>#</th>
                                <th style={{ width: 0 }}>Classroom</th>
                                <th style={{ width: 60 }}>Scores</th>
                                <th style={{ textAlign: 'left' }}>Name</th>
                                <th style={{ width: 220 }}>Email</th>
                                <th style={{ width: 220 }}>Guardian</th>
                                <th style={{ width: 135 }}>Contact Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                passedStudents.length === 0 ?
                                    <tr><td colSpan={15}>-n/a-</td></tr>
                                    :
                                    passedStudents.map((data, key) => {
                                        return <tr key={'tb-passed-' + CheckObjectStringEmpty(data, 'Id')}>
                                            <td>{key + 1}</td>
                                            <td>{CheckObjectStringEmpty(data, 'Classroom', '-')}</td>
                                            <td>{CheckObjectNumber(data, 'Scores').toFixed(2)}</td>
                                            <td style={{ textAlign: 'left' }}>{CheckObjectStringEmpty(data, 'Name', '-')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'Email', '-')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'Guardian', '-')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'ContactNumber', '-')}</td>
                                        </tr>;
                                    })
                            }
                        </tbody>
                    </table>
                </Tab>
                <Tab eventKey={1} title='Failed' className="base-tab tab-result-failed">
                    <span className="span-total">Total Failed(s): {failedStudents.length}</span>
                    <table className="table table-bordered table-hover tbStyle">
                        <thead>
                            <tr>
                                <th style={{ width: 40 }}>#</th>
                                <th style={{ width: 0 }}>Classroom</th>
                                <th style={{ width: 60 }}>Scores</th>
                                <th style={{ textAlign: 'left' }}>Name</th>
                                <th style={{ width: 220 }}>Email</th>
                                <th style={{ width: 220 }}>Guardian</th>
                                <th style={{ width: 135 }}>Contact Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                failedStudents.length === 0 ?
                                    <tr><td colSpan={15}>-n/a-</td></tr>
                                    :
                                    failedStudents.map((data, key) => {
                                        return <tr key={'tb-failed-' + CheckObjectStringEmpty(data, 'Id')}>
                                            <td>{key + 1}</td>
                                            <td>{CheckObjectStringEmpty(data, 'Classroom', '-')}</td>
                                            <td>{CheckObjectNumber(data, 'Scores').toFixed(2)}</td>
                                            <td style={{ textAlign: 'left' }}>{CheckObjectStringEmpty(data, 'Name', '-')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'Email', '-')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'Guardian', '-')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'ContactNumber', '-')}</td>
                                        </tr>;
                                    })
                            }
                        </tbody>
                    </table>
                </Tab>
                <Tab eventKey={2} title='Room Detail' className="base-tab">
                    <div className="tab-result-room-detail">
                        <ul className="ul-tb">
                            {
                                roomProperties.map((data, key) => {
                                    return (<li className="th">{this.ReformatRoomDataPerpertyName(data.name)}</li>);
                                })
                            }
                        </ul>
                        <ul className="ul-tb ul-tb-body">
                            {
                                roomProperties.map((data, key) => {
                                    return (<li>{this.GetRoomDataPropertyValue(data.value)}</li>);
                                })
                            }
                        </ul>
                    </div>
                </Tab>
            </Tabs>);
        });
        rooms_body.map((body, key) => {
            const { roomCode, total } = rooms_base[key];
            return components.push(<Tab
                eventKey={key}
                title={`Room (${roomCode})`}
                className="base-tab tab-result-room"
            ><div className="span-total">Total Student(s) : {total}</div>{body}</Tab>);
        });
        this.ToggleViewChartData('Passed/Failed', <Tabs defaultActiveKey={0} id='tabs-passed-failed-parent'>{components}</Tabs>);
    }
    ViewChartData_Attendance = () => {
        let components = [];

        // console.log('ViewChartData_Attendance');

        const { classroom } = this.state.chart_params_setting;

        const profiles = CheckObjectNullValue(this.state.chart_Report, 'Profiles', []);
        if (Array.isArray(profiles) === false)
            useAppService.getState().setModal('Chart :: Attendance', 'Profiles not found.<br />No data to display.');

        const { rooms, reports } = this.GetRoomsAndReports(ReportType.ChartAttendance);
        if (reports.length === 0)
            useAppService.getState().setModal('Chart :: Attendance', 'No data to display.');

        let rooms_base = [];
        reports.map((base, key) => {
            let attendedStudents = [];
            let unattendedStudents = [];
            const { roomCode, report } = base;
            const { AttendedStudentIds, UnattendedStudentIds } = report;
            if (Array.isArray(AttendedStudentIds)) {
                AttendedStudentIds.map((id, key) => {
                    let profile = null;
                    let findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === roomCode);
                    if (findIndex > -1) {
                        profile = profiles[findIndex];
                    }
                    else {
                        findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === '');
                        if (findIndex > -1)
                            profile = profiles[findIndex];
                    }
                    if (profile !== null) {
                        if (classroom === '')
                            attendedStudents.push(profile);
                        if (classroom !== '' && CheckObjectStringEmpty(profile, 'Classroom') === classroom)
                            attendedStudents.push(profile);
                    }
                    return null;
                });
            }
            if (Array.isArray(UnattendedStudentIds)) {
                UnattendedStudentIds.map((id, key) => {
                    let profile = null;
                    let findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === roomCode);
                    if (findIndex > -1) {
                        profile = profiles[findIndex];
                    }
                    else {
                        findIndex = profiles.findIndex(x => x.Id === id && x.RoomCode === '');
                        if (findIndex > -1)
                            profile = profiles[findIndex];
                    }
                    if (profile !== null) {
                        if (classroom === '')
                            unattendedStudents.push(profile);
                        if (classroom !== '' && CheckObjectStringEmpty(profile, 'Classroom') === classroom)
                            unattendedStudents.push(profile);
                    }
                    return null;
                });
            }
            return rooms_base.push({
                roomCode,
                total: attendedStudents.length + unattendedStudents.length,
                totalAttended: attendedStudents.length,
                totalUnattended: unattendedStudents.length,
                attendedStudents,
                unattendedStudents
            });
        });

        let rooms_body = [];
        rooms_base.map((base, key) => {
            const { roomCode, attendedStudents, unattendedStudents } = base;
            let room = null;
            const room_index = rooms.findIndex(x => x.RoomCode === roomCode);
            if (room_index > -1)
                room = this.ReformatRoomDataProperties(rooms[room_index]);
            let roomProperties = [];
            Object.keys(room).map((name, key) => {
                return roomProperties.push({ name: name, value: CheckObjectNullValue(room, name), });
            });
            return rooms_body.push(<Tabs defaultActiveKey={2} id={`tabs-attendance-child-${key}`}>
                <Tab eventKey={0} title='Present' className="base-tab tab-result-present">
                    <span className="span-total">Total Present(s): {attendedStudents.length}</span>
                    <table className="table table-bordered table-hover tbStyle">
                        <thead>
                            <tr>
                                <th style={{ width: 40 }}>#</th>
                                <th style={{ width: 0 }}>Classroom</th>
                                <th style={{ width: 60 }}>Scores</th>
                                <th style={{ textAlign: 'left' }}>Name</th>
                                <th style={{ width: 220 }}>Email</th>
                                <th style={{ width: 220 }}>Guardian</th>
                                <th style={{ width: 135 }}>Contact Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                attendedStudents.length === 0 ?
                                    <tr><td colSpan={15}>-n/a-</td></tr>
                                    :
                                    attendedStudents.map((data, key) => {
                                        return <tr key={'tb-passed-' + CheckObjectStringEmpty(data, 'Id')}>
                                            <td>{key + 1}</td>
                                            <td>{CheckObjectStringEmpty(data, 'Classroom', '-')}</td>
                                            <td>{CheckObjectNumber(data, 'Scores').toFixed(2)}</td>
                                            <td style={{ textAlign: 'left' }}>{CheckObjectStringEmpty(data, 'Name', '-')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'Email', '-')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'Guardian', '-')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'ContactNumber', '-')}</td>
                                        </tr>;
                                    })
                            }
                        </tbody>
                    </table>
                </Tab>
                <Tab eventKey={1} title='Absent' className="base-tab tab-result-absent">
                    <span className="span-total">Total Absent(s): {unattendedStudents.length}</span>
                    <table className="table table-bordered table-hover tbStyle">
                        <thead>
                            <tr>
                                <th style={{ width: 40 }}>#</th>
                                <th style={{ width: 0 }}>Classroom</th>
                                <th style={{ width: 60 }}>Scores</th>
                                <th style={{ textAlign: 'left' }}>Name</th>
                                <th style={{ width: 220 }}>Email</th>
                                <th style={{ width: 220 }}>Guardian</th>
                                <th style={{ width: 135 }}>Contact Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                unattendedStudents.length === 0 ?
                                    <tr><td colSpan={15}>-n/a-</td></tr>
                                    :
                                    unattendedStudents.map((data, key) => {
                                        return <tr key={'tb-failed-' + CheckObjectStringEmpty(data, 'Id')}>
                                            <td>{key + 1}</td>
                                            <td>{CheckObjectStringEmpty(data, 'Classroom', '-')}</td>
                                            <td>{CheckObjectNumber(data, 'Scores').toFixed(2)}</td>
                                            <td style={{ textAlign: 'left' }}>{CheckObjectStringEmpty(data, 'Name', '-')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'Email', '-')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'Guardian', '-')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'ContactNumber', '-')}</td>
                                        </tr>;
                                    })
                            }
                        </tbody>
                    </table>
                </Tab>
                <Tab eventKey={2} title='Room Detail' className="base-tab">
                    <div className="tab-result-room-detail">
                        <ul className="ul-tb">
                            {
                                roomProperties.map((data, key) => {
                                    return <li className="th">{this.ReformatRoomDataPerpertyName(data.name)}</li>;
                                })
                            }
                        </ul>
                        <ul className="ul-tb ul-tb-body">
                            {
                                roomProperties.map((data, key) => {
                                    return <li>{this.GetRoomDataPropertyValue(data.value)}</li>;
                                })
                            }
                        </ul>
                    </div>
                </Tab>
            </Tabs>);
        });
        rooms_body.map((body, key) => {
            const { roomCode, total } = rooms_base[key];
            return components.push(<Tab
                eventKey={key}
                title={`Room (${roomCode})`}
                className="base-tab tab-result-room"
            ><div className="span-total">Total Student(s) : {total}</div>{body}</Tab>);
        });
        this.ToggleViewChartData('Attendance', <Tabs defaultActiveKey={0} id='tabs-attendance-parent'>{components}</Tabs>);
    }
    ViewChartData_KdeByClassrooms = () => {

    }
    ToggleViewChartData = (title = '', body = '') => {
        this.setState({
            viewChartData_Setting: { title: title, body: body, },
            viewChartData_Toggle: !this.state.viewChartData_Toggle,
        });
    }
    //#endregion

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<div className="layout-dashboard">
            <h4>Welcome to iKEY Learning Evaluation :: Portal</h4>

            <div className="dashboard-load-statistic-setting">

                <Button variant="primary" className="setting-refresh" title='reload dashboard statistic'
                    onClick={() => this.ResetAndLoadDashboardStatistic_ViaApi()}
                    data-toggle="tooltip" data-placement="bottom"
                ><i className="fa fa-refresh"></i></Button>

                <div className="form-check setting-checkbox" onChange={() => this.SetDefaultLoadSetting()}>
                    <input className="form-check-input" type="checkbox" value="" id="checkbox-load-statistic"
                        readOnly={true}
                        defaultChecked={this.state.loadStatistic}
                    />
                    <label className="form-check-label" htmlFor="checkbox-load-statistic" style={{ cursor: 'pointer' }}
                    >show statistic on every load.</label>
                </div>

            </div>

            <div className="dashboard-statistic">
                <div className="chart-params">
                    {
                        this.state.chart_params_options.map((data, key) => {
                            if (key < this.state.chart_params_options.length - 1) {
                                return (this.ChartParamComponent(data));
                            }
                            return null;
                        })
                    }
                    {
                        this.state.isLoaded_chart_QuizRooms === false ? null :
                            this.ChartParamComponent(this.state.chart_QuizRooms_Options)
                    }
                </div>

                <div className="charts row g-0 gy-0" id='charts-div' style={this.state.isLoaded_chart ? {} : { flexDirection: 'row' }}>
                    {
                        this.state.isLoaded_chart ?
                            <>
                                <div className="chart-layout col pointer">
                                    <Chart
                                        series={CheckObjectNullValue(this.state.chart_bar_StudentsByTP, 'series', [{ name: 'Student(s)', data: [10, 15, 20, 35, 10, 15, 25] }])}
                                        // options={CheckObjectNullValue(this.state.chart_bar_StudentsByTP, 'options', {
                                        options={{
                                            plotOptions: {
                                                bar: {
                                                    borderRadius: 10,
                                                    dataLabels: {
                                                        position: 'top', // top, center, bottom
                                                    },
                                                }
                                            },
                                            dataLabels: {
                                                enabled: true,
                                                formatter: function (val) {
                                                    return val;
                                                },
                                                offsetY: -20,
                                                style: {
                                                    fontSize: '12px',
                                                    colors: ["#304758"]
                                                }
                                            },
                                            xaxis: {
                                                // categories: ['TD', 'TP1', 'TP2', 'TP3', 'TP4', 'TP5', 'TP6'],
                                                categories: CheckObjectNullValue(this.state.chart_bar_StudentsByTP, 'categories', ['TD', 'TP1', 'TP2', 'TP3', 'TP4', 'TP5', 'TP6']),
                                                position: 'top',
                                                axisBorder: {
                                                    show: false
                                                },
                                                axisTicks: {
                                                    show: false
                                                },
                                                tooltip: {
                                                    enabled: true
                                                },
                                                crosshairs: {
                                                    fill: {
                                                        type: 'gradient',
                                                        gradient: {
                                                            colorFrom: '#D8E3F0',
                                                            colorTo: '#BED1E6',
                                                            stops: [0, 100],
                                                            opacityFrom: 0.4,
                                                            opacityTo: 0.5,
                                                        }
                                                    }
                                                }
                                            },
                                            yaxis: {
                                                axisBorder: {
                                                    show: false
                                                },
                                                axisTicks: {
                                                    show: false,
                                                },
                                                labels: {
                                                    show: false,
                                                    formatter: function (val) {
                                                        return val;
                                                    }
                                                }
                                            },
                                            title: {
                                                text: 'Monthly TP for ('
                                                    + this.GetChartParamSettingPlaceholder(this.state.chart_params_options.find(x => x.id === ParamTarget.Month))
                                                    + '), '
                                                    + this.GetChartParamSettingPlaceholder(this.state.chart_params_options.find(x => x.id === ParamTarget.Year)),
                                                floating: true,
                                                offsetY: 230,
                                                align: 'center',
                                                style: {
                                                    color: '#444'
                                                }
                                            },
                                            tooltip: {
                                                x: {
                                                    formatter: function (val) {
                                                        const rangeBegin = CheckObjectNullValue(CheckObjectNullValue(studentsByTP_stageRange, val), 'RangeBegin');
                                                        const rangeEnd = CheckObjectNullValue(CheckObjectNullValue(studentsByTP_stageRange, val), 'RangeEnd');
                                                        if (rangeBegin !== null && rangeEnd !== null)
                                                            return val + " (" + rangeBegin + "~" + rangeEnd + ")"
                                                        return val;
                                                    }
                                                }
                                            },
                                        }}
                                        type='bar'
                                        height='100%'
                                        onClick={() => this.ViewChartData_StudentsByTP()}
                                    />
                                    {this.state.chart_bar_StudentsByTP === null ? <div className="chart-no-data"><span>no data</span></div> : null}
                                </div>
                                <div className="chart-layout col pointer">
                                    <Chart
                                        series={CheckObjectNullValue(this.state.chart_pie_PassedFailed, 'series', [55, 17])}
                                        options={CheckObjectNullValue(this.state.chart_pie_PassedFailed, 'options', {
                                            labels: ['Passed', 'Failed'],
                                            plotOptions: {
                                                pie: {
                                                    expandOnClick: false,
                                                }
                                            },
                                            legend: {
                                                markers: {
                                                    onClick: () => this.ViewChartData_PassedFailed(),
                                                }
                                            },
                                        })}
                                        type='donut'
                                        height='100%'
                                        onClick={() => this.ViewChartData_PassedFailed()}
                                    />
                                    {this.state.chart_pie_PassedFailed === null ? <div className="chart-no-data"><span>no data</span></div> : null}
                                </div>
                                <div className="chart-layout col pointer">
                                    <Chart
                                        series={CheckObjectNullValue(this.state.chart_pie_Attendance, 'series', [55, 17])}
                                        options={CheckObjectNullValue(this.state.chart_pie_Attendance, 'options', {
                                            labels: ['Present', 'Absent'],
                                            plotOptions: {
                                                pie: {
                                                    expandOnClick: false,
                                                }
                                            },
                                            legend: {
                                                markers: {
                                                    onClick: () => this.ViewChartData_Attendance(),
                                                }
                                            },
                                        })}
                                        type='donut'
                                        height='100%'
                                        onClick={() => this.ViewChartData_Attendance()}
                                    />
                                    {this.state.chart_pie_Attendance === null ? <div className="chart-no-data"><span>no data</span></div> : null}
                                </div>
                                <div className="chart-layout col pointer" hidden={true}>
                                    <Chart
                                        series={CheckObjectNullValue(this.state.chart_line_KdeByClassrooms, 'series', [{ name: 'Student(s)', data: [10, 15, 20, 35, 10, 15, 25] }])}
                                        options={CheckObjectNullValue(this.state.chart_line_KdeByClassrooms, 'options', {
                                            plotOptions: {
                                                bar: {
                                                    borderRadius: 10,
                                                    dataLabels: {
                                                        position: 'top', // top, center, bottom
                                                    },
                                                }
                                            },
                                            dataLabels: {
                                                enabled: true,
                                                formatter: function (val) {
                                                    return val;
                                                },
                                                offsetY: -20,
                                                style: {
                                                    fontSize: '12px',
                                                    colors: ["#304758"]
                                                }
                                            },
                                            xaxis: {
                                                categories: ['TD', 'TP1', 'TP2', 'TP3', 'TP4', 'TP5', 'TP6'],
                                                position: 'top',
                                                axisBorder: {
                                                    show: false
                                                },
                                                axisTicks: {
                                                    show: false
                                                },
                                                tooltip: {
                                                    enabled: true,
                                                },
                                                crosshairs: {
                                                    fill: {
                                                        type: 'gradient',
                                                        gradient: {
                                                            colorFrom: '#D8E3F0',
                                                            colorTo: '#BED1E6',
                                                            stops: [0, 100],
                                                            opacityFrom: 0.4,
                                                            opacityTo: 0.5,
                                                        }
                                                    }
                                                }
                                            },
                                            yaxis: {
                                                axisBorder: {
                                                    show: false
                                                },
                                                axisTicks: {
                                                    show: false,
                                                },
                                                labels: {
                                                    show: false,
                                                    formatter: function (val) {
                                                        return val;
                                                    }
                                                }
                                            },
                                            title: {
                                                text: 'Monthly TP for (April), 2024',
                                                floating: true,
                                                offsetY: 230,
                                                align: 'center',
                                                style: {
                                                    color: '#444'
                                                }
                                            }
                                        })}
                                        type='bar'
                                        height='100%'
                                    />
                                    {this.state.chart_line_KdeByClassrooms === null ? <div className="chart-no-data"><span>no data</span></div> : null}
                                </div>
                            </>
                            :
                            <>
                                <div className="col"><LoadingIndicator /></div>
                                <div className="col"><LoadingIndicator /></div>
                                <div className="col"><LoadingIndicator /></div>
                                {/* <div className="col"><LoadingIndicator /></div> */}
                            </>

                    }
                </div>
            </div>

            <p />
            <div className="dashboard-buttons">
                {
                    MenuReverse.map((data, key) => {
                        if (data.ShowInMenu)
                            if (data.Show === Access.All
                                || (data.Show === Access.AuthorOnly && this.state.isAuthor)
                                || (data.Show === Access.AdminOnly && this.state.isSuperAdmin)
                            )
                                return <Button
                                    key={'db-btn-' + key}
                                    hidden={data.Screen === this.state.screen || data.Disabled}
                                    onClick={() => { if (!data.Disabled) this.gotoScreen(data.Screen, data.Link); }}
                                    disabled={data.Disabled}
                                    style={data.Disabled ? { pointerEvents: 'initial', userSelect: 'none', cursor: 'not-allowed' } : {}}
                                >{data.Label}</Button>;
                        return null;
                    })
                }
            </div>

            {/* Chart - View Chart Data - Modal */}
            <Modal
                show={this.state.viewChartData_Toggle}
                onHide={() => this.ToggleViewChartData()}
                size='xl'
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title><b>{CheckObjectNullValue(this.state.viewChartData_Setting, 'title')}</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {CheckObjectNullValue(this.state.viewChartData_Setting, 'body')}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleViewChartData()}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>);
    }
}