
import { initializeApp } from 'firebase/app';
// import { getDatabase } from 'firebase/database';
import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCrcClX5GAEmH4ro4tdrx22VCBs85kuNWw",
    authDomain: "ikeyedutech-student.firebaseapp.com",
    databaseURL: "https://ikeyedutech-student.firebaseio.com",
    projectId: "ikeyedutech-student",
    storageBucket: "ikeyedutech-student.appspot.com",
    messagingSenderId: "838670185333",
    appId: "1:838670185333:web:2a892db22266ceef"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

//===== New Quiz Bank DB =====//
// let firebaseConfig_QuizBank = { ...firebaseConfig };
// firebaseConfig_QuizBank.databaseURL = 'https://ikey-quiz-bank-question-set.firebaseio.com/';
// const app5 = initializeApp(firebaseConfig_QuizBank, 'app5');
const app_rtdb_dbQuizBank = initializeApp({ databaseURL: 'https://ikey-quiz-bank-question-set.firebaseio.com/' }, 'app_rtdb_dbQuizBank');
const dbQuizBank = getDatabase(app_rtdb_dbQuizBank);

// let firebaseConfig_PkQuiz = { ...firebaseConfig };
// firebaseConfig_PkQuiz.databaseURL = 'https://ikeyedutech-student-5ea3c-pkquiz.firebaseio.com/';
const app_rtdb_dbLiveQuiz = initializeApp({ databaseURL: 'https://ikeyedutech-student-5ea3c-pkquiz.firebaseio.com/' }, 'app_rtdb_dbLiveQuiz');
const dbLiveQuiz = getDatabase(app_rtdb_dbLiveQuiz);

const app_rtdb_Logs = initializeApp({ databaseURL: 'https://ikeyedutech-student-a28c5-logs.firebaseio.com/' }, 'app_rtdb_Logs');
const dbLogs = getDatabase(app_rtdb_Logs);

export {
    app, firestore, firebaseConfig,
    auth, signInWithCustomToken, signOut,
    // dbQuizBank
    // app_rtdb_dbQuizBank, app_rtdb_dbLiveQuiz, app_rtdb_Logs,
    dbQuizBank, dbLiveQuiz, dbLogs
};