//Jay added 2024.03.01
//same in Base.
export const UserLoginResult = {
    /// <summary>
    /// Login successful
    /// </summary>
    Successful: 1,
    /// <summary>
    /// user does not exist (email or contact number)
    /// </summary>
    UserNotExist: 2,
    /// <summary>
    /// Wrong password
    /// </summary>
    WrongPassword: 3,
    /// <summary>
    /// Account have not been activated
    /// </summary>
    NotActive: 4,
    /// <summary>
    /// Customer has been deleted 
    /// </summary>
    Deleted: 5,
    /// <summary>
    /// Customer not registered 
    /// </summary>
    NotRegistered: 6,
    /// <summary>
    /// Temporary locked out the account
    /// </summary>
    LockedOutTemporary: 7,
    /// <summary>
    /// Full locked out the account
    /// </summary>
    FullLockedOut: 8,
    /// <summary>
    /// Another user access the account at this moment
    /// </summary>
    DuplicateLogin: 9,
}