import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Modal, ProgressBar, Tab, Tabs } from "react-bootstrap";

import { collection, doc, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { firestore } from "../../utilities/Firebase";

import { getMenuLink, GlobalSetting, InputType, LayoutScreen, PermissionAccessType, ReportStages } from "../../utilities/GlobalSetting";
import { PermissionAccess, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckValueNA, Delay, GetInputComponent, GetPropIds, SetTempTarget, TriggerDownloadFile, ConsoleLog, CheckNumber, CheckStringEmpty, CapitalizeJsonKeys, CheckNullValue, CheckBoolean, PagingComponents } from "../../utilities/GlobalFunctions";
import { useGlobal } from "../../utilities/GlobalVariables";
import { useAppService } from "../../services/AppService";
import { AlertMode } from "../AlertComponent";



const Default_NewReportSettings = {
    GroupOptions: [],
    ClassroomOptions: [],
    SubjectOptions: [],
    ErrorMessage: '',
    Info: {
        // OrganizerId: 0,
        // AuthorId: 0,
        // DateRangeBegin: '',
        // DateRangeEnd: '',
        Title: '',
        Description: '',
        Remark: '',
    },
    Groups: null,
    Classrooms: null,
    Subjects: null,
};

const ReportSettingPropertyNames = {
    OrganizerId: 'OrganizerId',
    AuthorId: 'AuthorId',
    // DateRangeBegin: 'DateRangeBegin',
    // DateRangeEnd: 'DateRangeEnd',
    Title: 'Title',
    Description: 'Description',
    Remark: 'Remark',
    Groups: 'Groups',
    Classrooms: 'Classrooms',
    Subjects: 'Subjects',   //new.
    //etc.
    Id: 'Id',
    CreatedDate: 'CreatedDate',
    File: 'File',
    // FileLocation: 'FileLocation',
    // FileName: 'FileName',
    // FileExt: 'FileExt',
    ReportGenerateSetting: 'ReportGenerateSetting',
};

export default class ReportRoomsResult extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,

        PA_View: false,
        PA_Search: false,
        PA_Create: false,
        PA_Update: false,
        PA_Delete: false,
        PA_Upload: false,
        PA_Download: false,

        List: [],
        IsListLoaded: false,
        TotalRows: 0,
        PageIndex: 0,
        PageSize: 10,
        // OrderBy: 'Name',
        // OrderType: 'ASC',

        //Edit.
        // Toggle_EditReportModal: false,
        // ReportModal_Create: false,
        ReportModal_Index: -1,
        ReportModal: null,
        ReportModal_Cache: null,
        // isUpdating: false,

        //View.
        ViewReportUiModal_Toggle: false,
        ViewReport_IsLoading: false,
        ViewReport_IsLoadSuccess: false,
        ViewReport_IsLoaded: false,
        ViewReport_Components: null,

        //Delete.
        DeleteReportUiModal_Toggle: false,

        //Generate.
        NewReportUiModal_Toggle: false,
        NewReport_Components: null,
    });

    componentWillUnmount = () => { }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.LoadList_ViaApi();
        if (typeof useGlobal.getState().setRefreshListCallbackFn === 'function')
            useGlobal.getState().setRefreshListCallbackFn(this.LoadList_ViaApi);
    }

    //2024.07.24
    CheckPermissions = async () => {
        const { uid, organizerId } = GetPropIds(useGlobal.getState().user);
        this.setState({
            PA_View: PermissionAccess(LayoutScreen.ManageReportRoomsResult, PermissionAccessType.View),
            PA_Search: PermissionAccess(LayoutScreen.ManageReportRoomsResult, PermissionAccessType.Search),
            PA_Create: PermissionAccess(LayoutScreen.ManageReportRoomsResult, PermissionAccessType.Create),
            PA_Update: PermissionAccess(LayoutScreen.ManageReportRoomsResult, PermissionAccessType.Update),
            PA_Delete: PermissionAccess(LayoutScreen.ManageReportRoomsResult, PermissionAccessType.Delete),
            PA_Upload: PermissionAccess(LayoutScreen.ManageReportRoomsResult, PermissionAccessType.Upload),
            PA_Download: PermissionAccess(LayoutScreen.ManageReportRoomsResult, PermissionAccessType.Download),

            PageSize: CheckNumber(localStorage.getItem(`ManageReport_RoomsResult_List_PageSize_${uid}_${organizerId}`), GlobalSetting.PageSize),
        });
        await Delay(0);
    }

    LoadList_ViaApi = async () => {

        await this.CheckPermissions();    //2024.07.24

        if (this.state.PA_View === false)
            return null;

        this.setState({
            isLoading: true,
            List: [],
            TotalRows: 0,
            IsListLoaded: false,
        });
        window.scrollTo(0, 0);

        let groupIds = '-1';
        let classrooms = '-1';
        let subjectIds = '-1';


        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Admin/Quiz/Room/Result/Report/List/${organizerId}/${authorId}/${this.state.PageIndex}/${this.state.PageSize}/${groupIds}/${classrooms}/${subjectIds}`;

        let totalRows = 0;
        let _List = [];

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                ConsoleLog(`LoadList_ViaApi \n ${JSON.stringify(data)}`);
                if (data.success) {
                    if (data.data !== undefined)
                        if (Array.isArray(data.data.list)) {
                            _List = data.data.list;
                            totalRows = CheckObjectNumber(data.data, 'totalCount', _List.length);
                        }
                        else {
                            ConsoleLog('List is empty.');
                        }
                }
                else {
                    ConsoleLog('Error \n api - report - load list (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                ConsoleLog('Error \n api - report - load list (error)\n' + error.message);
            });

        this.setState({
            isLoading: false,
            List: JSON.parse(JSON.stringify(_List)),
            TotalRows: totalRows,
            IsListLoaded: true,
        }, () => {
            ConsoleLog('TotalRows = ' + totalRows);
            ConsoleLog('List \n' + JSON.stringify(_List));
        });
    }

    ListComponents = () => {
        let components = [];

        if (this.state.IsListLoaded === false)
            return null;

        if (this.state.List.length === 0)
            return (<tr><td colSpan={15} align='center'>- list is empty -</td></tr>);

        this.state.List.map((data, key) => {
            components.push(<tr key={'tbi_' + key}>
                <td>{this.state.PageIndex + key + 1}</td>
                <td>{CheckValueNA(data['createdDate'])}</td>
                <td className='left'>{CheckValueNA(data['fileName'])}</td>
                {/* <td className='left'>{CheckValueNA(data['title'])}</td> */}
                <td style={{ display: 'flex', gap: 10 }}>
                    <button
                        type='button'
                        className='btn btn-danger'
                        onClick={() => this.ToggleDeleteReportUiModal(key)}
                        title="Delete current report."
                    >Delete</button>
                    <button
                        type='button'
                        className='btn btn-info'
                        onClick={() => TriggerDownloadFile(data['fileLocation'], data['fileName'], data['fileExt'], this.state.locale)}
                        title="Download report."
                    >Download</button>
                    <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => this.ToggleViewReportUiModal(key)}
                        title="View report."
                    >View</button>
                </td>
            </tr>);
            return null;
        });

        return (components);
    }

    //#region === Paging Components
    CallbackFunctionForPagingComponents_PageSize = (pageSize = GlobalSetting.PageSize) => {
        this.setState({
            PageSize: pageSize < GlobalSetting.PageSize ? GlobalSetting.PageSize : pageSize,
        }, () => {
            const { uid, organizerId } = GetPropIds(useGlobal.getState().user);
            localStorage.setItem(`ManageReport_RoomsResult_List_PageSize_${uid}_${organizerId}`, this.state.PageSize);
            setTimeout(() => {
                this.LoadList_ViaApi();
            }, 500);
        });
    }
    CallbackFunctionForPagingComponents_PageIndex = (pageIndex = 0) => {
        this.setState({
            PageIndex: pageIndex,
        }, () => {
            setTimeout(() => {
                this.LoadList_ViaApi();
            }, 500);
        });
    }
    //#endregion === Paging Components

    //#region === Report - View ===
    ToggleViewReportUiModal = async (index = -1) => {
        if (index < 0) {
            this.setState({
                ViewReportUiModal_Toggle: false,
            });
            await Delay(200);
        }
        this.setState({
            ReportModal_Index: this.state.PA_View === false ? -1 : index,
            ReportModal: Array.isArray(this.state.List) && index > -1 ? this.state.List[index] : null,
            ViewReportUiModal_Toggle: this.state.PA_View === false || index < 0 ? false : true,
            ViewReport_IsLoading: index < 0 ? false : true,
            ViewReport_IsLoadSuccess: false,
            ViewReport_IsLoaded: false,
            ViewReport_Components: null,
        }, async () => {
            if (this.state.ViewReportUiModal_Toggle) {
                await this.Populating_ViewReport_Components(true);    //init.
                await Delay(200);
                await this.LoadReportDetail_ViaApi();             //load report details.
                await this.Populating_ViewReport_Components(true);
                await Delay(200);
                await this.Populating_ViewReport_Components();    //refresh with fetched data.
            }
        });
    }
    LoadReportDetail_ViaApi = async () => {
        if (this.state.PA_View === false)
            return null;
        if (this.state.ReportModal_Index < 0 || this.state.ReportModal === null)
            return null;

        // this.setState({
        //     ViewReport_IsLoading: true,
        //     ViewReport_IsLoadSuccess: false,
        // });

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const { id } = this.state.ReportModal;

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Admin/Quiz/Room/Result/Report/Get/${organizerId}/${authorId}/${id}`;
        if (this.state.isDevMode)
            console.log('LoadReportDetail_ViaApi', url);

        let success = false;
        let _data = this.state.ReportModal;

        if (organizerId > 0 && authorId > 0 && Number(id) > 0) {
            await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        // 'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    success = CheckObjectBoolean(data, 'success');
                    if (success) {
                        if (CheckObjectNullValue(data, 'data') !== null)
                            _data = CapitalizeJsonKeys(data.data);
                    }
                    else {
                        if (this.state.isDevMode)
                            console.log('Error', 'api - report - load detail (failed)\n' + JSON.stringify(data));
                    }
                })
                .catch(error => {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - report - load detail (error)\n' + error.message);
                });
        }

        this.setState({
            ViewReport_IsLoading: false,
            ViewReport_IsLoadSuccess: success,
            ReportModal: _data,
        }, () => {
            if (this.state.isDevMode) {
                console.log('Report Detail \n' + JSON.stringify(_data));
            }
        });
        await Delay(0);
    }
    Populating_ViewReport_Components = async (init = false) => {
        let components = [];
        let base_component = null;
        const noDetail = <>- no detail is available -</>;

        if (this.state.ReportModal_Index < 0 || this.state.ReportModal === null) {
            // components.push(noDetail);
            base_component = <span>{noDetail}</span>;
        }
        else {

            //Info.
            let components_Info = [];
            const {
                // Id, 
                Title, Description, Remark,
                // FileLocation,
                FileName, FileExt,
                // DateRangeBegin, DateRangeEnd, 
                CreatedDate, ReportResult,
                CustomSelectedGroups, CustomSelectedClassrooms,
            } = this.state.ReportModal;
            // const { CustomSelectedGroups, CustomSelectedClassrooms } = Settings ?? { CustomSelectedGroups: [], CustomSelectedClassrooms: [] };
            let components_Info_Groups = null;
            if (Array.isArray(CustomSelectedGroups)) {
                let tmp = [];
                CustomSelectedGroups.map((option, key) => {
                    if (CheckObjectBoolean(option, 'Selected')) {
                        // tmp.push(CheckObjectStringEmpty(option, 'Name'));
                        tmp.push(`<div class="badge-item"><span>${CheckObjectStringEmpty(option, 'Name')}</span></div>`);
                    }
                    return null;
                });
                // components_Info_Groups = tmp.join(', ');
                components_Info_Groups = tmp.join('');
            }
            let components_Info_Classrooms = null;
            if (Array.isArray(CustomSelectedClassrooms)) {
                let tmp = [];
                if (CustomSelectedClassrooms.length > 0) {
                    CustomSelectedClassrooms.map((option, key) => {
                        if (CheckObjectBoolean(option, 'Selected')) {
                            // tmp.push(CheckObjectStringEmpty(option, 'Name'));
                            tmp.push(`<div class="badge-item"><span>${CheckObjectStringEmpty(option, 'Name')}</span></div>`);
                        }
                        return null;
                    });
                }
                else {
                    //backup.
                    if (this.state.ViewReport_IsLoading === false && this.state.ViewReport_IsLoadSuccess) {
                        ReportResult.map((data, rrKey) => {
                            // return tmp.push(CheckObjectStringEmpty(data, 'Classroom'));
                            const classroom = CheckObjectStringEmpty(data, 'Classroom');
                            if (classroom !== '')
                                tmp.push(`<div class="badge-item"><span>${classroom}</span></div>`);
                            return null;
                        });
                        // tmp = tmp.filter(x => x !== '').sort((a, b) => {
                        //     if (a < b) { return -1 };
                        //     if (a > b) { return 1 };
                        //     return 0;
                        // });
                    }
                }
                // components_Info_Classrooms = tmp.join(', ');
                components_Info_Classrooms = tmp.join('');
            }
            components_Info.push(<table className="table table-hover" border="1"><tbody>
                <tr><td style={{ width: 175 }}>Title</td><td>{Title}</td></tr>
                <tr><td>Description</td><td>{Description}</td></tr>
                <tr><td>Remark</td><td>{Remark}</td></tr>
                {/* <tr><td>Date Begin</td><td>{DateRangeBegin}</td></tr> */}
                {/* <tr><td>Date End</td><td>{DateRangeEnd}</td></tr> */}
                <tr><td>File Name</td><td>{CheckStringEmpty(FileName) + CheckStringEmpty(FileExt)}</td></tr>
                <tr><td>Created Date</td><td>{CreatedDate}</td></tr>
                {
                    init || components_Info_Groups === null ? null :
                        <tr>
                            <td>Selected Groups</td>
                            <td><div dangerouslySetInnerHTML={{ __html: components_Info_Groups }} style={{ display: 'flex', gap: 5, flexWrap: 'wrap' }} /></td>
                        </tr>
                }
                {
                    init || components_Info_Classrooms === null ? null :
                        <tr>
                            <td>Selected Classrooms</td>
                            <td><div dangerouslySetInnerHTML={{ __html: components_Info_Classrooms }} style={{ display: 'flex', gap: 5, flexWrap: 'wrap' }} /></td>
                        </tr>
                }
            </tbody></table>);
            await Delay(0);

            //Details.
            if (this.state.ViewReport_IsLoadSuccess === false) {
                components.push(components_Info);
                components.push(<hr />);
                //loading.
                if (this.state.ViewReport_IsLoading)
                    components.push(<>Fetching report...<br /><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} /></>);
                else
                    components.push(noDetail);

                base_component = components;
            }
            else {
                if (init) {
                    components.push(components_Info);
                    components.push(<hr />);
                    components.push(<><b>Populating report... </b>(please wait patiently)<br /><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 7 }} /></>);
                    base_component = components;
                }
                else {

                    //Info tab.
                    components.push(<Tab eventKey={0} title='Info' className="base-tab tab-info">{components_Info}</Tab>);

                    //#region === not needed atm ===
                    // //loaded success.
                    // const { GroupFigures, ClassroomFigures, RoomFigures, StudentFigures } = this.state.ReportModal;

                    // //Group Tab.
                    // let components_Groups = [];
                    // if (Array.isArray(GroupFigures)) {
                    //     GroupFigures.map((data, gKey) => {
                    //         let student_components = [];
                    //         if (Array.isArray(data['StudentIds']) && Array.isArray(StudentFigures)) {
                    //             let students = [];
                    //             data['StudentIds'].map((id, key) => {
                    //                 const findIndex_student = StudentFigures.findIndex(x => Number(x.UserProfileId) === Number(id));
                    //                 if (findIndex_student > -1)
                    //                     students.push(StudentFigures[findIndex_student]);
                    //                 return null;
                    //             });
                    //             students.map((stu, key) => {
                    //                 return student_components.push(<tr>
                    //                     <td>{key + 1}</td>
                    //                     <td>{CheckObjectStringEmpty(stu, 'Classroom')}</td>
                    //                     <td>{CheckObjectStringEmpty(stu, 'Name')}</td>
                    //                     <td style={{ width: 'max-content' }}>{CheckObjectStringEmpty(stu, 'Email')}</td>
                    //                 </tr>);
                    //             });
                    //         }
                    //         return components_Groups.push(<Tab eventKey={gKey} title={CheckObjectStringEmpty(data, 'GroupName')} className="base-tab report-tab-detail-base tab-groups-detail">
                    //             <span style={{ paddingLeft: 5, }}><b>Total Student(s) : {CheckObjectNumber(data, 'TotalStudent')}</b></span>
                    //             {
                    //                 student_components === null ? null
                    //                     :
                    //                     <table className="table table-hover tb-no-border-style" border="1" style={{ marginTop: 10, }}>
                    //                         <thead>
                    //                             <tr>
                    //                                 <th>#</th>
                    //                                 <th>Classroom</th>
                    //                                 <th>Name</th>
                    //                                 <th width={255}>Email</th>
                    //                             </tr>
                    //                         </thead>
                    //                         <tbody>
                    //                             {student_components}
                    //                         </tbody>
                    //                     </table>
                    //             }
                    //         </Tab>);
                    //     });
                    // }
                    // components.push(<Tab eventKey={1} title='Group(s)' className="base-tab report-tab-detail-base tab-groups">
                    //     <span style={{ fontWeight: 'bold', paddingLeft: 15, }}>Total Group(s) : {Array.isArray(GroupFigures) ? GroupFigures.length : '-'}</span>
                    //     <Tabs defaultActiveKey={0} id={'tab-groups-detail'} style={{ marginTop: 10, }}>{components_Groups}</Tabs>
                    // </Tab>);
                    // await Delay(0);

                    // //Classroom Tab.
                    // let components_Classrooms = [];
                    // if (Array.isArray(ClassroomFigures)) {
                    //     ClassroomFigures.map((data, crKey) => {
                    //         let student_components = [];
                    //         if (Array.isArray(data['StudentIds']) && Array.isArray(StudentFigures)) {
                    //             let students = [];
                    //             data['StudentIds'].map((id, key) => {
                    //                 const findIndex_student = StudentFigures.findIndex(x => Number(x.UserProfileId) === Number(id));
                    //                 if (findIndex_student > -1)
                    //                     students.push(StudentFigures[findIndex_student]);
                    //                 return null;
                    //             });
                    //             students.map((stu, key) => {
                    //                 return student_components.push(<tr>
                    //                     <td>{key + 1}</td>
                    //                     <td>{CheckObjectStringEmpty(stu, 'Classroom')}</td>
                    //                     <td>{CheckObjectStringEmpty(stu, 'Name')}</td>
                    //                     <td style={{ width: 'max-content' }}>{CheckObjectStringEmpty(stu, 'Email')}</td>
                    //                 </tr>);
                    //             });
                    //         }
                    //         return components_Classrooms.push(<Tab eventKey={crKey} title={CheckObjectStringEmpty(data, 'Classroom')} className="base-tab report-tab-detail-base tab-classrooms-detail">
                    //             <span style={{ paddingLeft: 5, }}><b>Total Student(s) : {CheckObjectNumber(data, 'TotalStudent')}</b></span>
                    //             {
                    //                 student_components === null ? null
                    //                     :
                    //                     <table className="table table-hover tb-no-border-style" border="1" style={{ marginTop: 10, }}>
                    //                         <thead>
                    //                             <tr>
                    //                                 <th>#</th>
                    //                                 <th>Classroom</th>
                    //                                 <th>Name</th>
                    //                                 <th width={255}>Email</th>
                    //                             </tr>
                    //                         </thead>
                    //                         <tbody>
                    //                             {student_components}
                    //                         </tbody>
                    //                     </table>
                    //             }
                    //         </Tab>);
                    //     });
                    // }
                    // components.push(<Tab eventKey={2} title='Classroom(s)' className="base-tab report-tab-detail-base tab-classrooms">
                    //     <span style={{ fontWeight: 'bold', paddingLeft: 15, }}>Total Classrooms : {Array.isArray(ClassroomFigures) ? ClassroomFigures.length : '-'}</span>
                    //     <Tabs defaultActiveKey={0} id={'tab-classrooms-detail'} style={{ marginTop: 10, }}>{components_Classrooms}</Tabs>
                    // </Tab>);
                    // await Delay(0);
                    //#endregion === not needed atm ===

                    //Quiz Room Tab.
                    let components_Classrooms = [];
                    if (Array.isArray(ReportResult)) {
                        ReportResult.map((data, rrKey) => {
                            const { Rooms, Students, Classroom, Group } = data;
                            // let components_Rooms_parent = [];
                            let components_Rooms = [];
                            let roomCounter = 0;
                            if (Array.isArray(Rooms)) {
                                Rooms.map((room, rKey) => {
                                    const { RoomId, RoomCode, RoomTitle, QnQty, Group, PresentStudents } = room;
                                    if (Array.isArray(PresentStudents) && PresentStudents.length > 0) {
                                        let student_components = [];
                                        if (Array.isArray(Students)) {
                                            PresentStudents.map((student, sKey) => {
                                                const findIndex_student = Students.findIndex(x => Number(x['UserProfileId']) === Number(student['UserProfileId']));
                                                const stu = findIndex_student < 0 ? {} : Students[findIndex_student];
                                                return student_components.push(<tr>
                                                    <td>{sKey + 1}</td>
                                                    <td>{CheckObjectStringEmpty(stu, 'Name')}</td>
                                                    <td style={{ width: 'max-content' }}>{CheckObjectStringEmpty(stu, 'Email')}</td>
                                                    <td align="right">{CheckObjectNumber(student, 'Score').toFixed(2)}</td>
                                                </tr>);
                                            });
                                        }
                                        components_Rooms.push(<Tab eventKey={roomCounter} title={RoomTitle} className="base-tab report-tab-detail-base tab-room-detail">
                                            <table cellPadding={5} cellSpacing={5} border="0">
                                                <tbody>
                                                    <tr><td><b>Title</b></td><td width={10} align="left">:</td><td>{RoomTitle}</td></tr>
                                                    <tr><td><b>Room Code</b></td><td width={10} align="left">:</td><td>{RoomCode}</td></tr>
                                                    <tr><td><b>Group</b></td><td width={10} align="left">:</td><td>{Group}</td></tr>
                                                    <tr><td><b>Total Question(s)</b></td><td width={10} align="left">:</td><td>{QnQty}</td></tr>
                                                    <tr><td><b>Total Student(s)</b></td><td width={10} align="left">:</td><td>{Array.isArray(PresentStudents) ? PresentStudents.length : 0}</td></tr>
                                                </tbody>
                                            </table>
                                            {
                                                Array.isArray(student_components) ?
                                                    <table className="table table-hover tb-no-border-style" border="1" style={{ marginTop: 10, }}>
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Name</th>
                                                                <th width={255}>Email</th>
                                                                <th style={{ textAlign: 'right' }}>Score</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {student_components}
                                                        </tbody>
                                                    </table>
                                                    : null
                                            }
                                        </Tab >);
                                        roomCounter++;
                                    }
                                    return null;
                                });
                                // components_Rooms_parent.push(<Tabs defaultActiveKey={0} id={'tab-rooms-detail'} style={{ marginTop: 10, }}>{components_Rooms}</Tabs>);
                            }
                            return components_Classrooms.push(<Tab eventKey={rrKey} title={Classroom} className="base-tab report-tab-detail-base tab-rooms-detail">
                                <table cellPadding={5} cellSpacing={5} border="0">
                                    <tbody>
                                        <tr><td><b>Classroom</b></td><td width={10} align="center">:</td><td>{Classroom}</td></tr>
                                        <tr><td><b>Group</b></td><td width={10} align="center">:</td><td>{Group}</td></tr>
                                        {/* <tr><td><b>Total Rooms(s)</b></td><td width={10} align="center">:</td><td>{Array.isArray(Rooms) ? Rooms.length : 0}</td></tr> */}
                                        <tr><td><b>Total Rooms(s)</b></td><td width={10} align="center">:</td><td>{roomCounter}</td></tr>
                                    </tbody>
                                </table>
                                <Tabs defaultActiveKey={0} id={'tab-rooms-detail'} style={{ marginTop: 10, }}>{components_Rooms}</Tabs>
                            </Tab>);
                        });
                    }
                    components.push(<Tab eventKey={1} title='Classroom(s)' className="base-tab report-tab-detail-base tab-classrooms">
                        <span style={{ fontWeight: 'bold', paddingLeft: 15, }}>Total Classroom(s) : {Array.isArray(ReportResult) ? ReportResult.length : '-'}</span>
                        <Tabs defaultActiveKey={0} id={'tab-classrooms-detail'} style={{ marginTop: 10, }}>{components_Classrooms}</Tabs>
                    </Tab>);
                    await Delay(0);

                    //final.
                    base_component = <Tabs defaultActiveKey={0} id={'tabs-report-detail'}>{components}</Tabs>;
                }
            }
        }
        await Delay(0);
        this.setState({
            ViewReport_IsLoaded: !init,
        }, () => {
            this.setState({
                ViewReport_Components: base_component,
            });
        });
    }
    ViewReportFooterComponents = () => {
        let components = [];
        const loading = this.state.ViewReport_IsLoading;
        const success = this.state.ViewReport_IsLoadSuccess;
        if (loading) { }
        else if (loading === false) {
            components.push(<Button variant="secondary" onClick={() => this.ToggleViewReportUiModal()}>Close</Button>);
        }
        if (loading === false && success && this.state.ViewReport_IsLoaded) {
            components.push(<Button variant="primary"
                // onClick={() => this.DownloadReportFile(this.state.ReportModal_Index)}
                onClick={() => {
                    const data = this.state.ReportModal;
                    // console.log(JSON.stringify(data));
                    TriggerDownloadFile(data['FileLocation'], data['FileName'], data['FileExt'], this.state.locale);
                }}
                hidden={this.state.ReportModal === null}
            >Download Report</Button>);
        }
        return (components);
    }
    //#endregion === Report - View ===

    //#region === Report - Delete ===
    ToggleDeleteReportUiModal = (index = -1) => {
        this.setState({
            ReportModal_Index: this.state.PA_Delete === false ? -1 : index,
            ReportModal: Array.isArray(this.state.List) && index > -1 ? this.state.List[index] : null,
            DeleteReportUiModal_Toggle: this.state.PA_Delete === false ? false : !this.state.DeleteReportUiModal_Toggle,
        });
    }
    ProcessDeleteReport_ViaApi = async () => {

        if (this.state.PA_Delete === false)
            return null;

        if (this.state.ReportModal_Index < 0 || this.state.ReportModal === null)
            return null;

        // this.setState({ DeleteReportUiModal_Toggle: false, });
        useAppService.getState().setModal('', 'Removing Report...', null, AlertMode.Loading);
        let success = false;
        let errorMessage = '';

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const { id, title } = this.state.ReportModal;

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Admin/Quiz/Room/Result/Report/Remove/${organizerId}/${authorId}/${id}`;
        if (this.state.isDevMode)
            console.log('ProcessDeleteReport_ViaApi', url);

        //remove report record in CMS, MarkedAsDeleted/delete azure files/mappings etc.
        if (organizerId > 0 && authorId > 0 && Number(id) > 0) {
            await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        // 'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    success = CheckObjectBoolean(data, 'success');
                    if (!success) {
                        errorMessage = CheckObjectStringEmpty(data, 'message');
                        if (this.state.isDevMode)
                            console.log('Error', 'api - report delete (failed) (' + organizerId + '/' + authorId + '/' + id + ') \n <' + title + '> \n' + JSON.stringify(data));
                    }
                })
                .catch(error => {
                    errorMessage = CheckObjectStringEmpty(error, 'message');
                    if (this.state.isDevMode)
                        console.log('Error', 'api - report delete (error) (' + organizerId + '/' + authorId + '/' + id + ') \n <' + title + '> \n' + error.message);
                });
        }

        //done.
        this.ToggleDeleteReportUiModal();   //reset data.
        if (success) {
            this.LoadList_ViaApi();
            // useAppService.getState().setModal('', 'Report &#60;' + title + '&#62; has been removed.');
            useAppService.getState().setModal('', 'Report <b>' + title + '</b> has been removed.');
        }
        else {
            // useAppService.getState().setModal('', 'Failed to remove Report &#60;' + title + '&#62;.<br /><br />' + errorMessage);
            useAppService.getState().setModal('', 'Failed to remove Report <b>' + title + '</b>.<br /><br />' + errorMessage);
        }
    }
    //#endregion === Report - Delete ===

    //#region === Report - New / Generate ===
    ToggleNewReportUiModal = async (forceClose = false) => {
        this.setState({
            NewReportUiModal_Toggle: forceClose ? false : !this.state.NewReportUiModal_Toggle,
            NewReportStage: ReportStages.Init,
            NewReportSettings: Default_NewReportSettings,
            NewReportProgressionStatus: '',
        }, async () => {
            if (this.state.NewReportUiModal_Toggle) {
                this.Populate_NewReportComponents();
                await this.LoadNewReportSettings_ViaApi();        //load report details.
                this.setState({
                    NewReportStage: ReportStages.GroupTab,
                });
                this.Populate_NewReportComponents();
            }
        });
    }
    ResetNewReportUiModal = async () => {
        await this.ToggleNewReportUiModal(true);
        await Delay(200);
        this.ToggleNewReportUiModal();
    }
    LoadNewReportSettings_ViaApi = async (fetchClassroom = false) => {

        if (this.state.PA_Create === false)
            return null;

        let groupIds = '0';
        let classrooms = '0';
        if (fetchClassroom === false) {
            this.setState({
                NewReportSettings: Default_NewReportSettings,
            });
        }
        else {
            const { Groups, Classrooms } = this.state.NewReportSettings;
            groupIds = CheckStringEmpty(Groups, '0');
            classrooms = CheckStringEmpty(Classrooms, '0');
        }
        const { authorId, organizerId, uid } = GetPropIds(useGlobal.getState().user);

        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Report/Setting/Get/${organizerId}/${authorId}/${groupIds}/${classrooms}`;
        if (this.state.isDevMode)
            console.log(`LoadNewReportSettings_ViaApi \n${url}`);

        let _Data = [];

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    _Data = CapitalizeJsonKeys(data.data);
                }
                else {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - settings - load (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - settings - load (error)\n' + error.message);
            });

        //Refresh Options.
        let { GroupOptions, ClassroomOptions, SubjectOptions } = this.state.NewReportSettings;
        if (Array.isArray(_Data['CustomSelectedGroups'])) {
            GroupOptions = [];
            const _GroupOptions = _Data['CustomSelectedGroups'];
            _GroupOptions.map((data, key) => {
                return GroupOptions.push({
                    value: CheckObjectNumber(data, 'Id'),
                    label: CheckObjectStringEmpty(data, 'Name'),
                });
            });
        }
        if (Array.isArray(_Data['CustomSelectedClassrooms'])) {
            ClassroomOptions = [];
            const _ClassroomOptions = _Data['CustomSelectedClassrooms'];
            _ClassroomOptions.map((data, key) => {
                return ClassroomOptions.push({
                    // value: CheckObjectNumber(data, 'Id'),
                    value: CheckObjectStringEmpty(data, 'Name'),
                    label: CheckObjectStringEmpty(data, 'Name'),
                });
            });
        }
        if (Array.isArray(_Data['CustomSelectedSubjects'])) {     //new.
            SubjectOptions = [];
            const _SubjectOptions = _Data['CustomSelectedSubjects'];
            _SubjectOptions.map((data, key) => {
                return SubjectOptions.push({
                    value: CheckObjectNumber(data, 'Id'),
                    label: CheckObjectStringEmpty(data, 'Name'),
                });
            });
        }

        this.setState({
            NewReportSettings: {
                ...this.state.NewReportSettings,
                GroupOptions: GroupOptions,
                ClassroomOptions: ClassroomOptions,
                SubjectOptions: SubjectOptions,     //new.
            },
        }, () => {
            if (this.state.isDevMode) {
                console.log('LoadNewReportSettings_ViaApi (raw)\n' + JSON.stringify(_Data));
                console.log('LoadNewReportSettings_ViaApi (settings)\n' + JSON.stringify(this.state.NewReportSettings));
            }
        });
    }
    Populate_NewReportComponents = () => {
        let components = [];
        if (this.state.NewReportUiModal_Toggle) {
            if (this.state.NewReportStage === ReportStages.Init) {
                components.push(<span>initializing...</span>);
                components.push(<ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} />);
            }
            else if (this.state.NewReportStage === ReportStages.Processing) {
                components.push(<><span>Generating report...</span><br /></>);
                components.push(<ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10, marginBottom: 10, }} />);
                components.push(<><span>{this.state.NewReportProgressionStatus}</span><br /></>);
            }
            else if (this.state.NewReportStage === ReportStages.Error) {
                components.push(<><span>Failed to generate report.</span><br /></>);
                components.push(<><span>{CheckObjectStringEmpty(this.state.NewReportSettings, 'ErrorMessage')}</span><br /></>);
            }
            else if (this.state.NewReportStage === ReportStages.InfoTab) {
                components.push(this.NewReportNaviComponents());
                components.push(<hr />);
                const {
                    // OrganizerId, AuthorId, 
                    // DateRangeBegin, DateRangeEnd,
                    Title, Description, Remark
                } = this.state.NewReportSettings['Info'];
                let tabComs = [];

                tabComs.push(<div className="form-group">
                    <label htmlFor={`new-report-${ReportSettingPropertyNames.Title}`}>Title (optional)</label>
                    <input className='form-control' type="text" style={{ width: '100%' }}
                        id={`new-report-${ReportSettingPropertyNames.Title}`}
                        onChange={(e) => this.SetNewReportInfo(ReportSettingPropertyNames.Title, e.target.value)}
                        defaultValue={Title}
                        disabled={this.state.NewReportStage === ReportStages.Processing}
                    />
                </div>);

                tabComs.push(<div className="form-group">
                    <label htmlFor={`new-report-${ReportSettingPropertyNames.Description}`}>Description (optional)</label>
                    <input className='form-control' type="text" style={{ width: '100%' }}
                        id={`new-report-${ReportSettingPropertyNames.Description}`}
                        onChange={(e) => this.SetNewReportInfo(ReportSettingPropertyNames.Description, e.target.value)}
                        defaultValue={Description}
                        disabled={this.state.NewReportStage === ReportStages.Processing}
                    />
                </div>);

                tabComs.push(<div className="form-group">
                    <label htmlFor={`new-report-${ReportSettingPropertyNames.Remark}`}>Remark (optional)</label>
                    <input className='form-control' type="text" style={{ width: '100%' }}
                        id={`new-report-${ReportSettingPropertyNames.Remark}`}
                        onChange={(e) => this.SetNewReportInfo(ReportSettingPropertyNames.Remark, e.target.value)}
                        defaultValue={Remark}
                        disabled={this.state.NewReportStage === ReportStages.Processing}
                    />
                </div>);

                // tabComs.push(<div className="form-group">
                //     <label htmlFor={`new-report-${ReportSettingPropertyNames.DateRangeBegin}`}>Date Begin</label>
                //     <input className='form-control' type="date" style={{ width: '100%' }}
                //         id={`new-report-${ReportSettingPropertyNames.DateRangeBegin}`}
                //         onChange={(e) => this.SetNewReportInfo(ReportSettingPropertyNames.DateRangeBegin, e.target.value)}
                //         defaultValue={DateRangeBegin}
                //         disabled={this.state.NewReportStage === ReportStages.Processing}
                //     />
                // </div>);

                // tabComs.push(<div className="form-group">
                //     <label htmlFor={`new-report-${ReportSettingPropertyNames.DateRangeEnd}`}>Date End</label>
                //     <input className='form-control' type="date" style={{ width: '100%' }}
                //         id={`new-report-${ReportSettingPropertyNames.DateRangeEnd}`}
                //         onChange={(e) => this.SetNewReportInfo(ReportSettingPropertyNames.DateRangeEnd, e.target.value)}
                //         defaultValue={DateRangeEnd}
                //         disabled={this.state.NewReportStage === ReportStages.Processing}
                //     />
                // </div>);

                components.push(<div style={{ display: 'flex', flexDirection: 'column' }}>{tabComs}</div>);
            }
            else if (this.state.NewReportStage === ReportStages.GroupTab) {
                components.push(this.NewReportNaviComponents());
                components.push(<hr />);
                const { GroupOptions, Groups } = this.state.NewReportSettings;
                let tbodyComs = [];
                if (Array.isArray(GroupOptions)) {
                    const selectedAll_group = Array.isArray(Groups) ? Groups.length === GroupOptions.length : false;
                    tbodyComs.push(<tr
                        onClick={() => this.SetNewReportInfo(ReportSettingPropertyNames.Groups, 'all', !selectedAll_group)}
                        style={{ cursor: 'pointer' }}
                    >
                        <td valign="middle" className="select">
                            <input type="checkbox" className="form-check form-check-input"
                                checked={selectedAll_group}
                                readOnly
                            ></input>
                        </td>
                        <td valign="middle">Select All</td>
                    </tr>);
                    GroupOptions.map((group, key) => {
                        const findIndex = Array.isArray(Groups) ? Groups.findIndex(x => Number(x) === Number(group.value)) : -1;
                        return tbodyComs.push(<tr
                            onClick={() => this.SetNewReportInfo(ReportSettingPropertyNames.Groups, group.value)}
                            style={{ cursor: 'pointer' }}
                        >
                            <td valign="middle" className="select">
                                <input type="checkbox" className="form-check form-check-input"
                                    checked={findIndex > -1}
                                    readOnly
                                ></input>
                            </td>
                            <td valign="middle">{CheckObjectStringEmpty(group, 'label', '-')}</td>
                        </tr>);
                    });
                }
                const tableCom = <table className="table table-hover tbStyle-options" border="1" style={{ width: 'max-content' }}>
                    <thead>
                        <tr>
                            <th className="select">Select</th>
                            <th>Group</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tbodyComs}
                    </tbody>
                </table>;
                components.push(<div style={{ display: 'flex', justifyContent: 'center' }}>{tableCom}</div>);
            }
            else if (this.state.NewReportStage === ReportStages.ClassroomTab) {
                components.push(this.NewReportNaviComponents());
                components.push(<hr />);
                const { ClassroomOptions, Classrooms } = this.state.NewReportSettings;
                let tbodyComs = [];
                if (Array.isArray(ClassroomOptions)) {
                    if (ClassroomOptions.length > 0) {
                        const selectedAll_classroom = Array.isArray(Classrooms) ? Classrooms.length === ClassroomOptions.length : false;
                        tbodyComs.push(<tr
                            onClick={() => this.SetNewReportInfo(ReportSettingPropertyNames.Classrooms, 'all', !selectedAll_classroom)}
                            style={{ cursor: 'pointer' }}
                        >
                            <td valign="middle" className="select">
                                <input type="checkbox" className="form-check form-check-input"
                                    checked={selectedAll_classroom}
                                    readOnly
                                ></input>
                            </td>
                            <td valign="middle">Select All</td>
                        </tr>);
                        ClassroomOptions.map((classroom, key) => {
                            const findIndex = Array.isArray(Classrooms) ? Classrooms.findIndex(x => String(x) === String(classroom.value)) : -1;
                            return tbodyComs.push(<tr
                                onClick={() => this.SetNewReportInfo(ReportSettingPropertyNames.Classrooms, classroom.value)}
                                style={{ cursor: 'pointer' }}
                            >
                                <td valign="middle" className="select">
                                    <input type="checkbox" className="form-check form-check-input"
                                        checked={findIndex > -1}
                                        readOnly
                                    ></input>
                                </td>
                                <td valign="middle">{CheckObjectStringEmpty(classroom, 'label', '-')}</td>
                            </tr>);
                        });
                    }
                    else {
                        tbodyComs.push(<tr><td colSpan={2}>- no assigned group(s) -</td></tr>);
                    }
                }
                else {
                    tbodyComs.push(<tr><td colSpan={2}>- no classroom found -</td></tr>);
                }
                const tableCom = <table className="table table-hover tbStyle-options" border="1" style={{ width: 'max-content' }}>
                    <thead>
                        <tr>
                            <th className="select">Select</th>
                            <th>Classroom</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tbodyComs}
                    </tbody>
                </table>;
                components.push(<div style={{ display: 'flex', justifyContent: 'center' }}>{tableCom}</div>);
            }
            else if (this.state.NewReportStage === ReportStages.SubjectTab) {   //new.
                components.push(this.NewReportNaviComponents());
                components.push(<hr />);
                const { SubjectOptions, Subjects } = this.state.NewReportSettings;
                let tbodyComs = [];
                if (Array.isArray(SubjectOptions)) {
                    const selectedAll_subject = Array.isArray(Subjects) ? Subjects.length === SubjectOptions.length : false;
                    tbodyComs.push(<tr
                        onClick={() => this.SetNewReportInfo(ReportSettingPropertyNames.Subjects, 'all', !selectedAll_subject)}
                        style={{ cursor: 'pointer' }}
                    >
                        <td valign="middle" className="select">
                            <input type="checkbox" className="form-check form-check-input"
                                checked={selectedAll_subject}
                                readOnly
                            ></input>
                        </td>
                        <td valign="middle">Select All</td>
                    </tr>);
                    SubjectOptions.map((subject, key) => {
                        const findIndex = Array.isArray(Subjects) ? Subjects.findIndex(x => Number(x) === Number(subject.value)) : -1;
                        return tbodyComs.push(<tr
                            onClick={() => this.SetNewReportInfo(ReportSettingPropertyNames.Subjects, subject.value)}
                            style={{ cursor: 'pointer' }}
                        >
                            <td valign="middle" className="select">
                                <input type="checkbox" className="form-check form-check-input"
                                    checked={findIndex > -1}
                                    readOnly
                                ></input>
                            </td>
                            <td valign="middle">{CheckObjectStringEmpty(subject, 'label', '-')}</td>
                        </tr>);
                    });
                }
                const tableCom = <table className="table table-hover tbStyle-options" border="1" style={{ width: 'max-content' }}>
                    <thead>
                        <tr>
                            <th className="select">Select</th>
                            <th>Subject</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tbodyComs}
                    </tbody>
                </table>;
                components.push(<div style={{ display: 'flex', justifyContent: 'center' }}>{tableCom}</div>);
            }
        }
        // return (components);
        this.setState({ NewReport_Components: components, });
    }
    NewReportFooterComponents = () => {
        let components = [];
        const stage = this.state.NewReportStage;
        if (stage === ReportStages.Init || stage === ReportStages.Processing) { }
        else if (stage === ReportStages.Success || stage === ReportStages.Error) {
            components.push(<Button variant="secondary" onClick={() => this.ToggleNewReportUiModal(true)}>Cancel</Button>);
        }
        else {
            components.push(<Button variant="secondary" onClick={() => this.ToggleNewReportUiModal(true)}>Cancel</Button>);
            components.push(<Button variant="secondary" onClick={() => this.ResetNewReportUiModal()}>Reset</Button>);
            components.push(<Button variant="primary"
                onClick={() => this.ProcessGenerateNewReport_ViaApi()}
            >Confirm</Button>);
        }
        return (components);
    }
    NewReportNaviComponents = () => {
        let components = [];
        const stage = this.state.NewReportStage;
        // components.push(<button type="button" className={"btn " + (stage === ReportStages.InfoTab ? 'btn-primary' : 'btn-outline-primary')} onClick={() => this.SetNewReportNaviStage(ReportStages.InfoTab)}>Info</button>);
        components.push(<button type="button" className={"btn " + (stage === ReportStages.GroupTab ? 'btn-primary' : 'btn-outline-primary')} onClick={() => this.SetNewReportNaviStage(ReportStages.GroupTab)}>Group</button>);
        // components.push(<button type="button" className={"btn " + (stage === ReportStages.SubjectTab ? 'btn-primary' : 'btn-outline-primary')} onClick={() => this.SetNewReportNaviStage(ReportStages.SubjectTab)}>Subject</button>); //new.
        components.push(<button type="button" className={"btn " + (stage === ReportStages.ClassroomTab ? 'btn-primary' : 'btn-outline-primary')} onClick={() => this.SetNewReportNaviStage(ReportStages.ClassroomTab)}>Classroom</button>);
        return (<div style={{ display: 'flex', flexDirection: 'row', gap: 20, width: '100%', justifyContent: 'center', alignItems: 'baseline' }}>{components}</div>);
    }
    SetNewReportNaviStage = async (name = ReportStages.Init) => {
        switch (name) {
            case ReportStages.InfoTab:
                break;
            case ReportStages.GroupTab:
                break;
            case ReportStages.ClassroomTab:
                useAppService.getState().setModal('Loading', 'Fetching classrooms...', null, AlertMode.Loading);
                await this.LoadNewReportSettings_ViaApi(true);
                await Delay(1000);
                useAppService.getState().setModal();
                break;
            case ReportStages.SubjectTab:   //new.
                break;
            default: break;
        }
        this.setState({ NewReportStage: name, }, () => this.Populate_NewReportComponents());
    }
    SetNewReportInfo = (name = '', value = null, selectAll = null) => {
        if (CheckNullValue(name) === null || value === null)
            return null;
        if (this.state.isDevMode)
            console.log('SetNewReportInfo (value) \n' + JSON.stringify(value));
        let { Title, Description, Remark, DateRangeBegin, DateRangeEnd, } = this.state.NewReportSettings['Info'];
        let { Groups, Classrooms, Subjects, GroupOptions, ClassroomOptions, SubjectOptions } = this.state.NewReportSettings;
        switch (name) {
            case ReportSettingPropertyNames.Title:
                Title = CheckStringEmpty(value);
                if (this.state.isDevMode)
                    console.log(ReportSettingPropertyNames.Title + '\n' + String(value));
                break;
            case ReportSettingPropertyNames.Description:
                Description = CheckStringEmpty(value);
                if (this.state.isDevMode)
                    console.log(ReportSettingPropertyNames.Description + '\n' + String(value));
                break;
            case ReportSettingPropertyNames.Remark:
                Remark = CheckStringEmpty(value);
                if (this.state.isDevMode)
                    console.log(ReportSettingPropertyNames.Remark + '\n' + String(value));
                break;
            case ReportSettingPropertyNames.DateRangeBegin:
                DateRangeBegin = CheckStringEmpty(value);
                if (this.state.isDevMode)
                    console.log(ReportSettingPropertyNames.DateRangeBegin + '\n' + String(value));
                break;
            case ReportSettingPropertyNames.DateRangeEnd:
                DateRangeEnd = CheckStringEmpty(value);
                if (this.state.isDevMode)
                    console.log(ReportSettingPropertyNames.DateRangeEnd + '\n' + String(value));
                break;
            case ReportSettingPropertyNames.Groups:
                let tmp_groups = [];
                if (selectAll !== null) {
                    if (CheckBoolean(selectAll)) {
                        if (Array.isArray(GroupOptions)) {
                            GroupOptions.map((data, key) => {
                                return tmp_groups.push(String(data.value));
                            });
                        }
                    }
                }
                else {
                    if (Array.isArray(Groups)) {
                        tmp_groups = Groups;
                        const g_value = Number(value);
                        const findIndex_g = tmp_groups.findIndex(x => Number(x) === g_value);
                        if (findIndex_g < 0)
                            tmp_groups.push(g_value);
                        else
                            tmp_groups.splice(findIndex_g, 1);
                        tmp_groups.sort((a, b) => a - b);
                    }
                    else {
                        tmp_groups.push(Number(value));
                    }
                }
                Groups = tmp_groups;
                if (this.state.isDevMode)
                    console.log(ReportSettingPropertyNames.Groups + '\n' + JSON.stringify(tmp_groups));
                break;
            case ReportSettingPropertyNames.Classrooms:
                let tmp_classrooms = [];
                if (selectAll !== null) {
                    if (CheckBoolean(selectAll)) {
                        if (Array.isArray(ClassroomOptions)) {
                            ClassroomOptions.map((data, key) => {
                                return tmp_classrooms.push(String(data.value));
                            });
                        }
                    }
                }
                else {
                    if (Array.isArray(Classrooms)) {
                        tmp_classrooms = Classrooms;
                        const c_value = String(value);
                        const findIndex_c = tmp_classrooms.findIndex(x => String(x) === c_value);
                        if (findIndex_c < 0)
                            tmp_classrooms.push(c_value);
                        else
                            tmp_classrooms.splice(findIndex_c, 1);
                        tmp_classrooms.sort((a, b) => {
                            if (a < b) { return -1 };
                            if (a > b) { return 1 };
                            return 0;
                        });
                    }
                    else {
                        tmp_classrooms.push(String(value));
                    }
                }
                Classrooms = tmp_classrooms;
                if (this.state.isDevMode)
                    console.log(ReportSettingPropertyNames.Classrooms + '\n' + JSON.stringify(tmp_classrooms));
                break;
            case ReportSettingPropertyNames.Subjects:   //new.
                let tmp_subjects = [];
                if (selectAll !== null) {
                    if (CheckBoolean(selectAll)) {
                        if (Array.isArray(SubjectOptions)) {
                            SubjectOptions.map((data, key) => {
                                return tmp_subjects.push(String(data.value));
                            });
                        }
                    }
                }
                else {
                    if (Array.isArray(Subjects)) {
                        tmp_subjects = Subjects;
                        const s_value = Number(value);
                        const findIndex_s = tmp_subjects.findIndex(x => Number(x) === s_value);
                        if (findIndex_s < 0)
                            tmp_subjects.push(s_value);
                        else
                            tmp_subjects.splice(findIndex_s, 1);
                        tmp_subjects.sort((a, b) => a - b);
                    }
                    else {
                        tmp_subjects.push(Number(value));
                    }
                }
                Subjects = tmp_subjects;
                if (this.state.isDevMode)
                    console.log(ReportSettingPropertyNames.Subjects + '\n' + JSON.stringify(tmp_subjects));
                break;
            default: break;
        }
        this.setState({
            NewReportSettings: {
                ...this.state.NewReportSettings,
                Info: {
                    Title: Title,
                    Description: Description,
                    Remark: Remark,
                    DateRangeBegin: DateRangeBegin,
                    DateRangeEnd: DateRangeEnd,
                },
                Groups: Groups,
                Classrooms: Classrooms,
                Subjects: Subjects,
            },
        }, () => {
            this.Populate_NewReportComponents();
        });
    }
    ProcessGenerateNewReport_ViaApi = async () => {
        if (this.state.PA_Create === false)
            return null;

        this.setState({
            NewReportStage: ReportStages.Processing,
        }, () => {
            this.Populate_NewReportComponents();
        });

        const { authorId, organizerId, uid } = GetPropIds(useGlobal.getState().user);

        if (this.state.isDevMode === false) {
            // await setDoc(doc(firestore, 'ILE_Portal_Status_Logs', uid), { 'status': '', 'log': '', 'uid': uid });
            // await Delay(200);
            // this.ProcessingMessage_Subscribe(uid);
        }

        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Admin/Quiz/Room/Result/Report/Generate';

        const {
            GroupOptions,
            ClassroomOptions,
            SubjectOptions, //new.
            Info,
            Groups,
            Classrooms,
            Subjects,   //new.
        } = this.state.NewReportSettings;

        let selectedGroupOptions = [];
        if (Array.isArray(Groups) && Array.isArray(GroupOptions)) {
            GroupOptions.map((option, key) => {
                const findIndex = Groups.findIndex(x => Number(x) === Number(option.value));
                return selectedGroupOptions.push({
                    Id: option.value,
                    Name: option.label,
                    Selected: findIndex > -1,
                });
            });
        }
        let selectedClassroomOptions = [];
        if (Array.isArray(Classrooms) && Array.isArray(ClassroomOptions)) {
            ClassroomOptions.map((option, key) => {
                const findIndex = Classrooms.findIndex(x => String(x) === String(option.value));
                return selectedClassroomOptions.push({
                    Id: key + 1,
                    Name: option.value,
                    Selected: findIndex > -1,
                });
            });
        }
        let selectedSubjectOptions = [];    //new.
        if (Array.isArray(Subjects) && Array.isArray(SubjectOptions)) {     //new.
            SubjectOptions.map((option, key) => {
                const findIndex = Subjects.findIndex(x => Number(x) === Number(option.value));
                return selectedSubjectOptions.push({
                    Id: option.value,
                    Name: option.label,
                    Selected: findIndex > -1,
                });
            });
        }
        const jsonModal = JSON.stringify({
            OrganizerId: organizerId,
            AuthorId: authorId,
            // DateRangeBegin: Info.DateRangeBegin,
            // DateRangeEnd: Info.DateRangeEnd,
            Title: Info.Title,
            Description: Info.Description,
            Remark: Info.Remark,
            Settings: {
                // DateRangeBegin: Info.DateRangeBegin,
                // DateRangeEnd: Info.DateRangeEnd,
                CustomSelectedGroups: selectedGroupOptions,
                CustomSelectedClassrooms: selectedClassroomOptions,
                CustomSelectedSubjects: selectedSubjectOptions,     //new.
            }
        });

        ConsoleLog(`ProcessGenerateNewReport_ViaApi \n${url}\n${jsonModal}`);

        let success = false;
        let errorMessage = '';
        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: jsonModal,
            })
            .then(res => res.json())
            .then(data => {
                success = CheckObjectBoolean(data, 'success');
                if (success) { }
                else {
                    errorMessage = CheckObjectStringEmpty(data, 'message');
                    if (this.state.isDevMode)
                        console.log('Error', 'api - new report - generate (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.state.isDevMode)
                    console.log('Error', 'api - new report - generate (error)\n' + error.message);
            });

        // if (this.state.isDevMode === false)
        //     this.ProcessingMessage_Unsubscribe();

        this.setState({
            NewReportStage: success ? ReportStages.Success : ReportStages.Error,
        }, () => {
            switch (this.state.NewReportStage) {
                case ReportStages.Success:
                    useAppService.getState().setModal('Success', 'Report has been successfully generated.');
                    this.LoadList_ViaApi();
                    break;
                case ReportStages.Error:
                    useAppService.getState().setModal('Error', 'Failed to generate report.' + (errorMessage === '' ? '' : '<br /><br />' + errorMessage));
                    break;
                default: break;
            }
            this.ToggleNewReportUiModal(true);
        });
    }
    //#endregion === Report - New / Generate ===

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<div className="">
            <table className="table page-header-1" style={{ tableLayout: 'fixed', marginBottom: 0 }}>
                <tbody>
                    <tr>
                        <td className="left">
                            <h5>Rooms Result Report</h5>
                        </td>
                        <td className="center"></td>
                        <td className="right">
                            {
                                // this.state.PA_Search === false ? null :
                                //     <Button
                                //         variant='outline-primary'
                                //         onClick={() => this.ToggleSearchUiModal()}
                                //         disabled={this.state.SearchUiModal_Toggle}
                                //     >Search Report</Button>
                            }
                            {
                                this.state.PA_Create === false ? null :
                                    <Button
                                        variant='outline-primary'
                                        onClick={() => this.ToggleNewReportUiModal()}
                                        disabled={this.state.NewReportUiModal_Toggle}
                                    >Generate Report</Button>
                            }
                            <Button
                                variant='outline-primary'
                                onClick={() => this.setState({ redirectLink: getMenuLink(LayoutScreen.Dashboard), redirect: true, })}
                            >Back to Dashboard</Button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                <thead>
                    <tr>
                        <th width='50'>#</th>
                        <th width='175'>Created Date</th>
                        <th className="left">File Name</th>
                        {/* <th className="left">Title</th> */}
                        <th width='250'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.isLoading && !this.state.IsListLoaded ?
                            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                            : this.state.List.length > 0 ?
                                this.ListComponents()
                                : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                    }
                    {
                        this.state.List.length === 0 ? null :
                            PagingComponents(15, this.state.TotalRows, this.state.PageIndex, this.state.PageSize, this.CallbackFunctionForPagingComponents_PageSize, this.CallbackFunctionForPagingComponents_PageIndex)
                    }
                </tbody>
            </table>

            {/* Report - Edit / Update - Modal */}
            {/* <Modal show={this.state.Toggle_EditReportModal}
                onHide={() => this.ToggleEditReportModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>{this.state.ReportModal_Create ? 'New Report' : 'Edit :: '} {CheckObjectStringEmpty(this.state.ReportModal, 'name')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.ReportModalComponent()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger"
                        onClick={() => this.ToggleRemoveReportModal(this.state.ReportModal)}
                        style={{ position: "absolute", left: 0, marginLeft: 15 }}
                        disabled={this.state.isUpdating || this.state.PA_Delete === false}
                    >Remove</Button>
                    <Button variant="secondary" onClick={() => this.ToggleEditReportModal()} disabled={this.state.isUpdating}>Cancel</Button>
                    <Button variant="secondary" onClick={() => this.ResetSetting()} disabled={this.state.isUpdating}>Reset</Button>
                    {
                        this.state.ReportModal_Create ?
                            <Button variant="primary"
                                onClick={() => this.GenerateReport_ViaApi()}
                                disabled={this.state.isUpdating || this.state.PA_Create === false}
                            >Create</Button>
                            :
                            <Button variant="primary"
                                onClick={() => this.UpdateReport_ViaApi()}
                                disabled={this.state.isUpdating || this.state.PA_Update === false}
                            >Update</Button>
                    }
                </Modal.Footer>
            </Modal > */}

            {/* Report - Remove (Confirm) - Modal */}
            {/* <Modal show={this.state.Toggle_RemoveReportModal}
                onHide={() => this.ToggleRemoveReportModal()}
                centered
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>Remove :: {CheckObjectStringEmpty(this.state.ReportModal, 'name')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you sure you want to remove this report ?</p>
                    <p>If so, click confirm to continue.</p>
                    <p><i><b>Caution:</b> Once removed, report will not be above to restore.</i></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleRemoveReportModal()}>Cancel</Button>
                    <Button variant="primary"
                        onClick={() => this.RemoveReport_ViaApi()}
                        disabled={this.state.isUpdating}
                    >Confirm</Button>
                </Modal.Footer>
            </Modal > */}

            {/* Report - Delete - Modal */}
            <Modal show={this.state.DeleteReportUiModal_Toggle} onHide={() => this.ToggleDeleteReportUiModal()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Remove Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        Array.isArray(this.state.List) && this.state.ReportModal_Index > -1 ?
                            <span>
                                Title: <b>{CheckObjectStringEmpty(this.state.ReportModal, 'Title')}</b>
                                <br />Do you sure you want to <b>remove</b> this report ?
                                <br /><b><i>The removal is not reversible.</i></b>
                            </span>
                            :
                            <span>Invalid index, unable to remove this report.</span>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.ToggleDeleteReportUiModal()}>Cancel</Button>
                    <Button variant="primary"
                        onClick={() => this.ProcessDeleteReport_ViaApi()}
                        hidden={Array.isArray(this.state.List) && this.state.ReportModal_Index > -1 ? false : true}
                    >Confirm</Button>
                </Modal.Footer>
            </Modal>

            {/* Report - New / Generate - Modal */}
            <Modal show={this.state.NewReportUiModal_Toggle} onHide={() => this.ToggleNewReportUiModal(true)} centered>
                <Modal.Header closeButton><Modal.Title>New Report</Modal.Title></Modal.Header>
                <Modal.Body>{this.state.NewReport_Components}</Modal.Body>
                <Modal.Footer>{this.NewReportFooterComponents()}</Modal.Footer>
            </Modal>

            {/* Report - View - Modal */}
            <Modal size="xl" show={this.state.ViewReportUiModal_Toggle} onHide={() => this.ToggleViewReportUiModal()} centered>
                <Modal.Header closeButton><Modal.Title>Report - Rooms Result</Modal.Title></Modal.Header>
                <Modal.Body>{this.state.ViewReport_Components}</Modal.Body>
                <Modal.Footer>{this.ViewReportFooterComponents()}</Modal.Footer>
            </Modal>

        </div>);
    }
}