import React, { createRef } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Modal } from "react-bootstrap";

import { Access, GlobalSetting, LayoutScreen, SettingMenu } from "../utilities/GlobalSetting";
import { useGlobal } from "../utilities/GlobalVariables";
import { useAppService } from "../services/AppService";
import { AlertMode } from "./AlertComponent";
import { byteConverter, CapitalizeJsonKeys, CheckBoolean, CheckObjectBoolean, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, ConsoleLog, GetPropIds, TriggerDownloadFile } from "../utilities/GlobalFunctions";

import SettingEducationStage from "./Settings/SettingEducationStage";
import SettingCustomGroup from "./Settings/SettingCustomGroup";
import SettingCustomSubject from "./Settings/SettingCustomSubject";
// import ManageReportRoomsResult from "./Reports/ManageReportRoomsResult";

export default class ManageSettingScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.

        this.SettingCustomGroup_Ref = createRef();
        this.SettingEducationStage_Ref = createRef();
        // this.SettingCustomSubject_Ref = createRef();
    }

    getInitState = () => ({
        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,

        isSuperAdmin: false,
        isMasterAdmin: false,
        isAdmin: false,
        isTeacher: false,
        // isAuthor: false,
        screen: LayoutScreen.ManageSetting,
        Option: null,

        popupModal_Toggle: false,
        popupModal: { title: '', content: null },   //2024.08.13
    });

    componentWillUnmount = () => { }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.setState({
            screen: CheckStringEmpty(useGlobal.getState().screen, LayoutScreen.ManageSetting),
            isSuperAdmin: CheckBoolean(useGlobal.getState().isSuperAdmin),
            isMasterAdmin: CheckBoolean(useGlobal.getState().isMasterAdmin),
            isAdmin: CheckBoolean(useGlobal.getState().isAdmin),
            isTeacher: CheckBoolean(useGlobal.getState().isTeacher),
            // isAuthor: CheckBoolean(useGlobal.getState().isAuthor),
        });
    }

    SetMenuOption = (option = null) => {
        if (option === null)
            return null;
        this.setState({ Option: option, });
        const screen = CheckObjectStringEmpty(option, 'Screen');
        if (screen !== '') {
            useGlobal.getState().setScreen(screen);
            this.setState({ screen: screen, });
            ConsoleLog('SetSettingOption = ' + screen + ' / ' + useGlobal.getState().screen + '\n' + JSON.stringify(option));
            if (typeof useGlobal.getState().refreshListCallbackFn === 'function')
                useGlobal.getState().refreshListCallbackFn();
        }
    }

    //2024.08.09
    GenerateStudentPasswordsFile_ViaApi = async () => {
        useAppService.getState().setModal('', 'generating file...', null, AlertMode.Loading);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Student/Password/Generate/Excel/${organizerId}/${authorId}`;

        let response = null;
        let success = false;
        let msg = '';

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('GenerateStudentPasswordsFile_ViaApi =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                // response = CheckObjectNullValue(data, 'data');
                response = data.data;

                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', 'api - generate (error)\n' + error.message);
            });

        useAppService.getState().setModal();
        if (success) {
            if (response !== null) {
                response = CapitalizeJsonKeys(response);
                const fileName = CheckObjectStringEmpty(response, 'FileName');
                const fileExt = CheckObjectStringEmpty(response, 'FileExt');
                const fileSize = byteConverter(CheckObjectNumber(response, 'FileSize'), 2);
                const url = CheckObjectStringEmpty(response, 'Url');
                this.setState({
                    popupModal_Toggle: true,
                    popupModal: {
                        title: 'Generate Student Passwords File',
                        content: <table cellPadding={5} cellSpacing={5} width={'100%'}><tbody>
                            <tr>
                                <td style={{ width: 85, verticalAlign: 'top' }}>File Name</td>
                                <td style={{ width: 20, verticalAlign: 'top' }}>:</td>
                                <td>{fileName}</td></tr>
                            <tr><td>File Size</td><td>:</td><td>{fileSize}</td></tr>
                            {/* <tr><td colSpan="3" align="center">
                                <button type="button" className="btn btn-primary" onClick={() => TriggerDownloadFile(url, fileName, fileExt)} >Download File</button>
                            </td></tr> */}
                        </tbody></table>,
                        footer: <button type="button" className="btn btn-primary" onClick={() => TriggerDownloadFile(url, fileName, fileExt)} >Download File</button>,
                    },
                });
                // useAppService.getState().setModal('Generate Student Passwords File', <table cellPadding={5} cellSpacing={5} width={'100%'}><tbody>
                //     <tr>
                //         <td style={{ width: 85, verticalAlign: 'top' }}>File Name</td>
                //         <td style={{ width: 20, verticalAlign: 'top' }}>:</td>
                //         <td>{fileName}</td></tr>
                //     <tr><td>File Size</td><td>:</td><td>{fileSize}</td></tr>
                //     <tr><td colSpan="3" align="center">
                //         <button type="button" className="btn btn-primary" onClick={() => TriggerDownloadFile(url, fileName, fileExt)} >Download File</button>
                //     </td></tr>
                // </tbody></table>);
            }
            else {
                this.setState({
                    popupModal_Toggle: true,
                    popupModal: {
                        title: '(Failed) Generate Student Passwords File',
                        content: 'Invalid response.',
                        footer: null,
                    },
                });
                // useAppService.getState().setModal('(Failed) Generate Student Passwords File', 'Invalid response.');
            }
        }
        else {
            this.setState({
                popupModal_Toggle: true,
                popupModal: {
                    title: '(Failed) Generate Student Passwords File',
                    content: 'Failed to generate file.<br /><br />' + msg,
                    footer: null,
                },
            });
            // useAppService.getState().setModal('(Failed) Generate Student Passwords File', 'Failed to generate file.<br /><br />' + msg);
        }
    }

    //2024.08.13
    GenerateTeacherPasswordsFile_ViaApi = async () => {
        useAppService.getState().setModal('', 'generating file...', null, AlertMode.Loading);

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Teacher/Password/Generate/Excel/${organizerId}/${authorId}/${0}`;

        let response = null;
        let success = false;
        let msg = '';

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('GenerateTeacherPasswordsFile_ViaApi =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                // response = CheckObjectNullValue(data, 'data');
                response = data.data;

                if (!success)
                    msg = CheckObjectStringEmpty(data, 'message');
            })
            .catch(error => {
                msg = error.message;
                if (this.state.isDevMode)
                    console.log('Error', 'api - generate (error)\n' + error.message);
            });

        useAppService.getState().setModal();
        if (success) {
            if (response !== null) {
                response = CapitalizeJsonKeys(response);
                const fileName = CheckObjectStringEmpty(response, 'FileName');
                const fileExt = CheckObjectStringEmpty(response, 'FileExt');
                const fileSize = byteConverter(CheckObjectNumber(response, 'FileSize'), 2);
                const url = CheckObjectStringEmpty(response, 'Url');
                this.setState({
                    popupModal_Toggle: true,
                    popupModal: {
                        title: 'Generate Teacher Passwords File',
                        content: <table cellPadding={5} cellSpacing={5} width={'100%'}><tbody>
                            <tr>
                                <td style={{ width: 85, verticalAlign: 'top' }}>File Name</td>
                                <td style={{ width: 20, verticalAlign: 'top' }}>:</td>
                                <td>{fileName}</td></tr>
                            <tr><td>File Size</td><td>:</td><td>{fileSize}</td></tr>
                            {/* <tr><td colSpan="3" align="center">
                                <button type="button" className="btn btn-primary" onClick={() => TriggerDownloadFile(url, fileName, fileExt)} >Download File</button>
                            </td></tr> */}
                        </tbody></table>,
                        footer: <button type="button" className="btn btn-primary" onClick={() => TriggerDownloadFile(url, fileName, fileExt)} >Download File</button>,
                    },
                });
                // useAppService.getState().setModal('Generate Teacher Passwords File', <table cellPadding={5} cellSpacing={5} width={'100%'}><tbody>
                //     <tr>
                //         <td style={{ width: 85, verticalAlign: 'top' }}>File Name</td>
                //         <td style={{ width: 20, verticalAlign: 'top' }}>:</td>
                //         <td>{fileName}</td></tr>
                //     <tr><td>File Size</td><td>:</td><td>{fileSize}</td></tr>
                //     <tr><td colSpan="3" align="center">
                //         <button type="button" className="btn btn-primary" onClick={() => TriggerDownloadFile(url, fileName, fileExt)} >Download File</button>
                //     </td></tr>
                // </tbody></table>);
            }
            else {
                this.setState({
                    popupModal_Toggle: true,
                    popupModal: {
                        title: '(Failed) Generate Teacher Passwords File',
                        content: 'Invalid response.',
                        footer: null,
                    },
                });
                // useAppService.getState().setModal('(Failed) Generate Teacher Passwords File', 'Invalid response.');
            }
        }
        else {
            this.setState({
                popupModal_Toggle: true,
                popupModal: {
                    title: '(Failed) Generate Teacher Passwords File',
                    content: 'Failed to generate file.<br /><br />' + msg,
                    footer: null,
                },
            });
            // useAppService.getState().setModal('(Failed) Generate Teacher Passwords File', 'Failed to generate file.<br /><br />' + msg);
        }
    }

    //2024.08.13
    GenerateConfirmButton = (fn = null) => {
        return <Button variant="primary"
            onClick={() => this.setState({
                popupModal_Toggle: false,
                popupModal: { title: '', content: null, footer: null, },
            }, () => {
                if (typeof fn === 'function')
                    fn();
            })}
        >Confirm</Button>;
    }

    MenuButtonComponents = () => {
        let components = [];

        if (this.state.isSuperAdmin || this.state.isMasterAdmin || this.state.isAdmin) {
            //2024.08.09
            components.push(<Button key='ms-mp-btn-password-student' variant='warning'
                onClick={() => {
                    // const confirm = window.confirm('Confirm to generate file ?');
                    // if (confirm)
                    //     this.GenerateStudentPasswordsFile_ViaApi();
                    this.setState({
                        popupModal_Toggle: true,
                        popupModal: {
                            title: 'Generate Student Passwords File',
                            content: 'Confirm to generate file ?',
                            footer: this.GenerateConfirmButton(this.GenerateStudentPasswordsFile_ViaApi),
                        },
                    });
                }}
            >Generate<br />Passwords File<br />(Student)</Button>);

            //2024.08.13
            components.push(<Button key='ms-mp-btn-password-teacher' variant='warning'
                onClick={() => {
                    // const confirm = window.confirm('Confirm to generate file ?');
                    // if (confirm)
                    //     this.GenerateTeacherPasswordsFile_ViaApi();
                    this.setState({
                        popupModal_Toggle: true,
                        popupModal: {
                            title: 'Generate Teacher Passwords File',
                            content: 'Confirm to generate file ?',
                            footer: this.GenerateConfirmButton(this.GenerateTeacherPasswordsFile_ViaApi),
                        },
                    });
                }}
            >Generate<br />Passwords File<br />(Teacher)</Button>);
        }

        Object.keys(SettingMenu).map((name, key) => {
            const data = SettingMenu[name];
            // if (data.ShowInMenu)
            //     if (data.Show === Access.AdminOnly && this.state.isAdmin)
            if (data.ShowInMenu) {
                if (
                    data.Show === Access.All
                    || data.Show === Access.ByPermission
                    || (data.Show === Access.SuperAdminOnly && this.state.isSuperAdmin)
                    || (data.Show === Access.MasterAdminOnly && this.state.isMasterAdmin)
                    || (data.Show === Access.AdminOnly && this.state.isAdmin)
                ) {
                    // if (ShowOrHideMenu(data)) {
                    components.push(<Button
                        key={'ms-mp-btn-' + key}
                        variant={this.state.screen === LayoutScreen.ManageSetting || data.Screen === this.state.screen ? 'primary' : 'secondary'}
                        // onClick={() => data.Disabled || data.Screen === this.state.screen ? DoNothing() : this.SetSettingOption(data)}
                        onClick={() => this.SetMenuOption(data)}
                        style={data.Disabled ? { userSelect: 'none', cursor: 'not-allowed' } : {}}
                    >{data.Label}</Button>);
                }
            }
            return null;
        });
        return (components);
    }

    MainPanelComponent = () => {
        switch (CheckObjectStringEmpty(this.state.Option, 'Screen')) {

            //Manage Custom Group.
            case SettingMenu['customGroup'].Screen:
                return (<SettingCustomGroup ref={ref => ref = this.SettingCustomGroup_Ref} />);

            //Manage Education Stage.
            case SettingMenu['educationStage'].Screen:
                return (<SettingEducationStage ref={ref => ref = this.SettingEducationStage_Ref} />);

            //Manage Custom Subject.
            case SettingMenu['customSubject'].Screen:
                return (<SettingCustomSubject ref={ref => ref = this.SettingCustomSubject_Ref} />);

            //Manage Settings - buttons.
            default:
                return (<div className="manage-setting-buttons">
                    <h4>Manage Settings</h4>
                    {this.MenuButtonComponents()}
                </div>);
        }
    }

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<>
            <div className="manage-setting">
                {
                    this.state.isAdmin === false ?
                        <span>Invalid permission.</span>
                        :
                        this.state.screen === LayoutScreen.ManageSetting ?
                            this.MainPanelComponent()
                            :
                            <>
                                <div className="left-panel">{this.MenuButtonComponents()}</div>
                                <div className="main-panel">{this.MainPanelComponent()}</div>
                            </>
                }
            </div>

            {/* Popup - Modal */}
            <Modal show={this.state.popupModal_Toggle} onHide={() => this.setState({ popupModal_Toggle: false, popupModal: { title: '', content: null }, })} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.popupModal?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.popupModal?.content}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.setState({ popupModal_Toggle: false, popupModal: { title: '', content: null }, })}>Close</Button>
                    {this.state.popupModal?.footer}
                </Modal.Footer>
            </Modal>
        </>);
    }
}