import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Modal, ProgressBar } from "react-bootstrap";

import { InputType, LayoutScreen } from "../utilities/GlobalSetting";
import { useGlobal } from "../utilities/GlobalVariables";
import { useAppService } from "../services/AppService";
import { CheckBoolean, CheckNullValue, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, Delay, DoNothing, GetInputComponent, GetPropIds, RandomId, SetTempTarget } from "../utilities/GlobalFunctions";
import { CreateOrganizer_ViaAPI, GetAllOrganizers_ViaAPI, UpdateOrganizer_ViaAPI, UploadPictureFileForOrganizer_ViaAPI } from "../services/OrganizerService";
import { SearchGovernmentSchools_ViaAPI } from "../services/GovernmentSchoolService";
import LoadingIndicator from "./LoadingIndicator";
import { AlertMode } from "./AlertComponent";


export default class ManageOrganizerScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,

        screen: LayoutScreen.None,
        isSuperAdmin: false,
        isAuthor: false,

        IsLoading: false,
        IsListLoaded: false,
        List: [],

        SearchSchoolByParamsModal_Toggle: false,
        SearchSchoolByParams_Processing: false,
        SearchBySchoolCode: '',
        SearchBySchoolName: '',
        SearchByCountryState: '',
        SearchByDistrictArea: '',
        SearchSchoolResultModal_Toggle: false,
        SchoolList: [],
        SelectedSchool: null,

        EditOrganizerModal_Toggle: false,
        EditOrganizerIndex: -1,
        EditOrganizerObject: null,
        EditOrganizerCache: null,
        EditOrganizerNew: false,
        EditOrganizer_Processing: false,
        AttachedPictureFile: null,
    });

    componentWillUnmount = () => { }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        useGlobal.getState().setScreen(LayoutScreen.ManageOrganizer);
        this.setState({
            screen: CheckStringEmpty(useGlobal.getState().screen, LayoutScreen.Dashboard),
            isSuperAdmin: CheckBoolean(useGlobal.getState().isSuperAdmin),
            isAuthor: CheckBoolean(useGlobal.getState().isAuthor),
        });
        await Delay(200);
        if (this.state.isAuthor === false || this.state.isSuperAdmin === false)
            return null;
        await this.GetAllOrganizers();
    }

    //#region === List ===
    //get all organizers.
    GetAllOrganizers = async () => {
        this.setState({
            IsLoading: true,
            IsListLoaded: false,
            List: [],
        });
        const { authorId } = GetPropIds(useGlobal.getState().user);
        const { success, errorMessage, data } = await GetAllOrganizers_ViaAPI(authorId);
        if (!success) {
            useAppService.getState().setModal('', 'Failed to retrieve Organizer list.<br /><br />Error:' + errorMessage);
        }
        else {
            let _List = [];
            if (Array.isArray(data)) {
                data.forEach((item, key) => {
                    _List.push(this.PopulateOrganizerModal(item));
                });
            }
            _List.sort((a, b) => a.Id - b.Id);
            this.setState({
                List: _List,
                IsListLoaded: true,
            });
        }
        this.setState({
            IsLoading: false,
        });
    }
    ListComponents = () => {
        if (this.state.List.length > 0) {
            let listItems = [];
            this.state.List.map((data, key) => {
                return listItems.push(
                    <tr key={'list-item-' + key}>
                        <td align='center'>{key + 1}</td>
                        <td>
                            <b>{CheckObjectStringEmpty(data, 'DisplayName', '-')}</b><br />
                            <span style={{ fontSize: 12, color: 'gray' }}>({CheckObjectStringEmpty(data, 'Name', '-')})</span><br />
                            <span style={{ fontSize: 12, color: 'gray' }}>({CheckObjectStringEmpty(data, 'IdentityGuid', '-')})</span>
                        </td>
                        <td>
                            {CheckObjectStringEmpty(data, 'SchoolName', '-')}
                            {CheckObjectNullValue(data, 'SchoolCode') === null ? <></> :
                                <><br />School Code: <b>{CheckObjectStringEmpty(data, 'SchoolCode', '-')}</b></>}
                        </td>
                        <td>
                            <div dangerouslySetInnerHTML={{
                                __html: this.FormatSchoolCompleteName(data)
                            }}></div>
                        </td>
                        <td align="center" style={{ verticalAlign: 'top', padding: 0 }}>
                            <div className='row' style={{ padding: 5 }}>
                                <div className='col'>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => this.Toggle_EditOrganizerModal(key)}
                                        style={{ width: '100%' }}
                                        disabled={this.state.IsLoading}
                                    >Edit</button>
                                </div>
                            </div>
                        </td>
                    </tr>);
            });
            return listItems;
        }
        return this.state.IsListLoaded ?
            <tr><td colSpan='15' align='center'>list is empty</td></tr>
            : <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>;
    }
    FormatSchoolCompleteName = (data = null) => {
        if (data === null)
            return '';

        if (CheckObjectNullValue(data, 'SchoolCode') === null)
            return '-';

        const name = CheckObjectStringEmpty(data, 'SchoolCompleteName');
        if (name.includes(' - ')) {
            let array = name.split(' - ');
            if (Array.isArray(array) && array.length > 0) {
                array.shift();
                array[0] = '<span style="font-weight: 400;">' + array[0] + '</span>';
                return array.join('<br />');
            }
        }
        return '-';
    }
    //#endregion === List ===

    //#region === Organizer - Edit / New ===
    //create new organizer.
    CreateOrganizer = async () => {
        if (this.state.EditOrganizerObject === null) {
            useAppService.getState().setModal('Creation Failed', 'Invalid organizer object.');
            return null;
        }
        this.setState({
            EditOrganizer_Processing: true,
        });
        useAppService.getState().setModal('', 'creating...', null, AlertMode.Loading);
        const { authorId } = GetPropIds(useGlobal.getState().user);
        const { success, errorMessage, data } = await CreateOrganizer_ViaAPI(authorId, this.state.EditOrganizerObject);
        if (!success) {
            useAppService.getState().setModal('', 'Failed to create new Organizer.<br /><br />Error:' + errorMessage);
        }
        else {
            this.setState({
                List: data,
            });
            useAppService.getState().setModal('', 'Organizer has been created.');
            this.Toggle_EditOrganizerModal();
            this.GetAllOrganizers();
        }
        this.setState({
            EditOrganizer_Processing: false,
        });
    }
    //update or remove.
    UpdateOrganizer = async (remove = false) => {
        if (this.state.EditOrganizerObject === null) {
            useAppService.getState().setModal((remove ? 'Removal' : 'Update') + ' Failed', 'Invalid organizer object.');
            return null;
        }
        this.setState({
            EditOrganizer_Processing: true,
        });
        useAppService.getState().setModal('', 'updating...', null, AlertMode.Loading);
        const { authorId } = GetPropIds(useGlobal.getState().user);
        const { success, errorMessage, data } = await UpdateOrganizer_ViaAPI(authorId, remove, this.state.EditOrganizerObject);
        if (!success) {
            useAppService.getState().setModal('', 'Failed to update Organizer.<br /><br />Error:' + errorMessage);
        }
        else {
            this.setState({
                List: data,
            });
            useAppService.getState().setModal('', 'Organizer has been ' + (remove ? 'removed' : 'updated') + '.');
        }
        this.setState({
            EditOrganizer_Processing: false,
        });
    }
    PopulateOrganizerModal = (modal = null) => {
        return {
            Id: CheckObjectNumber(modal, 'Id'),
            Name: CheckObjectStringEmpty(modal, 'Name'),
            DisplayName: CheckObjectStringEmpty(modal, 'DisplayName'),
            IdentityGuid: CheckObjectStringEmpty(modal, 'IdentityGuid'),
            Active: CheckObjectBoolean(modal, 'Active'),
            SchoolCode: CheckObjectStringEmpty(modal, 'SchoolCode'),
            NationalState: CheckObjectStringEmpty(modal, 'NationalState'),
            DistrictArea: CheckObjectStringEmpty(modal, 'DistrictArea'),
            SchoolName: CheckObjectStringEmpty(modal, 'SchoolName'),
            SchoolCompleteName: CheckObjectStringEmpty(modal, 'SchoolCompleteName'),
            SchoolAddress: CheckObjectStringEmpty(modal, 'SchoolAddress'),
            LogoId: CheckObjectNumber(modal, 'LogoId'),
            LogoUrl: CheckObjectStringEmpty(modal, 'LogoUrl'),
        };
    }
    Toggle_EditOrganizerModal = (index = -1, create = false) => {
        this.setState({
            EditOrganizerModal_Toggle: !this.state.EditOrganizerModal_Toggle,
            EditOrganizerIndex: index,
            EditOrganizerNew: create,
            AttachedPictureFile: null,
        }, async () => {
            let obj = null;
            if (create)
                obj = this.PopulateOrganizerModal();
            else
                obj = !this.state.EditOrganizerModal_Toggle || index < 0 ? null : this.PopulateOrganizerModal(this.state.List[index]);

            await Delay(0);
            SetTempTarget(obj);
            await Delay(0);

            this.setState({
                EditOrganizerObject: obj,
                EditOrganizerCache: obj === null ? null : JSON.parse(JSON.stringify(obj)),
            });
        });
    }
    ResetEditOrganizerObject = () => {
        this.setState({
            EditOrganizerObject: JSON.parse(JSON.stringify(this.state.EditOrganizerCache)),
        });
    }
    SaveOrganizerObject = (obj = null) => {
        this.setState({
            EditOrganizerObject: obj,
        });
    }
    EditOrganizerComponents = () => {
        const organizer = this.state.EditOrganizerObject;
        if (organizer === null)
            return null;

        let components = [];

        //Name.
        components.push(<div className="form-group">
            <label>Name</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'Name', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //DisplayName.
        components.push(<div className="form-group">
            <label>Display Name</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'DisplayName', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //IdentityGuid.
        components.push(<div className="form-group">
            <label>GUID</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'IdentityGuid', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //NationalState.
        components.push(<div className="form-group">
            <label>Country State</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'NationalState', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //DistrictArea.
        components.push(<div className="form-group">
            <label>District Area</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'DistrictArea', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //SchoolCode.
        components.push(<div className="form-group">
            <label>School Code</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'SchoolCode', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //SchoolName.
        components.push(<div className="form-group">
            <label>School Name</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'SchoolName', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //SchoolCompleteName.
        components.push(<div className="form-group">
            <label>Complete School Name</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'SchoolCompleteName', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //SchoolAddress.
        components.push(<div className="form-group">
            <label>School Address</label>
            {
                GetInputComponent(InputType.Text, null,
                    organizer, 'SchoolAddress', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        //Active.
        components.push(<div className="form-group">
            <label>Active</label>
            {
                GetInputComponent(InputType.Checkbox, null,
                    organizer, 'Active', null, '', this.state.locale,
                    this.SaveOrganizerObject, null, this.state.EditOrganizer_Processing)
            }
        </div>);

        return (components);
    }
    onAttachPictureFile = (event) => {
        this.setState({
            AttachedPictureFile: event === null ? null : event.target.files[0],
        }, () => {
            this.UploadPictureFileForOrganizer();
        });
    }
    UploadPictureFileForOrganizer = async () => {
        const obj = this.state.EditOrganizerObject;
        if (obj === null) {
            useAppService.getState().setModal('Upload Failed', 'Invalid organizer object.');
            return null;
        }

        //Convert Picture to Base64.
        let _base64 = '';
        if (this.state.AttachedPictureFile !== null) {
            let reader = new FileReader();
            reader.onload = (evt) => {
                // _base64 = btoa(reader.result);
                _base64 = btoa(String.fromCharCode(...new Uint8Array(reader.result)));
            };
            reader.readAsArrayBuffer(this.state.AttachedPictureFile);
        }
        await Delay(300);
        if (CheckNullValue(_base64) === null) {
            useAppService.getState().setModal('Upload Failed', 'image file conversion error.');
            return null;
        }

        const _fileName = CheckStringEmpty(this.state.AttachedPictureFile.name).toLowerCase();
        let _fileType = '';
        let _fileExt = '';
        if (_fileName.includes('.jpg')) {
            _fileType = 'image/jpeg';
            _fileExt = '.jpg';
        }
        else if (_fileName.includes('.png')) {
            _fileType = 'image/png';
            _fileExt = '.png';
        }
        this.setState({
            EditOrganizer_Processing: true,
        });
        useAppService.getState().setModal('', 'uploading image file...', null, AlertMode.Loading);

        const fileName = this.GetGuid(CheckObjectStringEmpty(obj, 'SchoolName'))
            + '-' + CheckObjectStringEmpty(obj, 'SchoolCode') + '-' + RandomId();

        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const { success, message } = await UploadPictureFileForOrganizer_ViaAPI({
            AuthorId: authorId,
            OrganizerId: organizerId,
            FileExt: _fileExt,
            FileType: _fileType,
            FileName: fileName,
            Base64: _base64,
        });
        if (!success) {
            useAppService.getState().setModal('', 'Failed to upload image file.<br /><br />Error:' + message);
        }
        else {
            // const obj = this.PopulateOrganizerModal(CapitalizeJsonKeys(data));
            // this.setState({
            //     EditOrganizerObject: obj,
            //     EditOrganizerCache: JSON.parse(JSON.stringify(obj)),
            // });
            const index = this.state.EditOrganizerIndex;
            await this.GetAllOrganizers();
            await Delay(0);
            this.Toggle_EditOrganizerModal();
            await Delay(300);
            this.Toggle_EditOrganizerModal(index);
            await Delay(0);
            useAppService.getState().setModal('', "Organizer's logo has been updated.");
        }
        this.setState({
            EditOrganizer_Processing: false,
        });
    }
    //#endregion === Organizer - Edit / New ===

    //#region === Gov Schools ===
    Toggle_SearchSchoolByParams = () => {
        this.setState({
            SearchSchoolByParamsModal_Toggle: !this.state.SearchSchoolByParamsModal_Toggle,
            SearchSchoolByParams_Processing: false,
            SearchBySchoolCode: '',
            SearchBySchoolName: '',
            SearchByCountryState: '',
            SearchByDistrictArea: '',
        }, () => {
            if (this.state.SearchSchoolByParamsModal_Toggle) {
                this.setState({
                    SchoolList: [],
                    SelectedSchool: null,
                });
            }
        });
    }
    //get all schools.
    GetAllGovernmentSchools_ViaAPI = async () => {
        this.setState({
            SearchBySchoolCode: '',
            SearchBySchoolName: '',
            SearchByCountryState: '',
            SearchByDistrictArea: ''
        });
        await this.searchGovernmentSchools_ViaAPI();
    }
    //get schools by params.
    SearchGovernmentSchools_ViaAPI = async () => {
        this.setState({
            SearchSchoolByParams_Processing: true,
            SchoolList: [],
        });

        let tableList = [];
        const { success, message, list } = await SearchGovernmentSchools_ViaAPI(
            this.state.SearchBySchoolCode, this.state.SearchBySchoolName,
            this.state.SearchByCountryState, this.state.SearchByDistrictArea
        );
        if (success)
            tableList = list;
        else
            useAppService.getState().setModal('Search (Failed)', 'Invalid Operation.<br /><br />Error:<br />' + message);

        this.setState({
            SchoolList: tableList,
            SearchSchoolByParams_Processing: false,
        }, () => {
            this.Toggle_SeachSchoolResultModal();
        });
    }
    Toggle_SeachSchoolResultModal = () => {
        this.setState({
            SearchSchoolResultModal_Toggle: !this.state.SearchSchoolResultModal_Toggle,
        }, () => {
            if (!this.state.SearchSchoolByParamsModal_Toggle) {
                this.setState({
                    SchoolList: [],
                });
            }
        });
    }
    ConfirmSelectedSchoolResult = async () => {
        const selectedSchool = this.state.SelectedSchool;
        if (selectedSchool === null)
            return null;

        let modal = this.state.EditOrganizerObject;
        if (modal === null)
            modal = this.PopulateOrganizerModal();

        const schoolName = CheckObjectStringEmpty(selectedSchool, 'SchoolName');
        const guid = this.GetGuid(schoolName);

        modal['Id'] = CheckObjectNumber(modal, 'Id');
        modal['Name'] = guid.toUpperCase();
        modal['DisplayName'] = schoolName;
        modal['IdentityGuid'] = guid.toLowerCase();
        modal['Active'] = true;
        modal['SchoolCode'] = CheckObjectStringEmpty(selectedSchool, 'SchoolCode');
        modal['NationalState'] = CheckObjectStringEmpty(selectedSchool, 'CountryState');
        modal['DistrictArea'] = CheckObjectStringEmpty(selectedSchool, 'PPD');
        modal['SchoolName'] = schoolName;
        modal['SchoolCompleteName'] = CheckObjectStringEmpty(selectedSchool, 'CompleteSchoolName');
        modal['SchoolAddress'] = CheckObjectStringEmpty(selectedSchool, 'Address');

        // modal['LogoId'] = CheckObjectNumber(modal, 'LogoId');
        // modal['LogoUrl'] = CheckObjectStringEmpty(modal, 'LogoUrl');

        await Delay(0);
        SetTempTarget(modal);
        this.SaveOrganizerObject(modal);
        await Delay(0);
        this.Toggle_SeachSchoolResultModal();
        await Delay(0);
        this.Toggle_SearchSchoolByParams();
    }
    GetGuid = (schoolName = '') => {

        //#region   

        // let guid = '';

        // if (schoolName.includes('KEBANGSAAN') && schoolName.includes('MENENGAH') === false) {
        //     if (schoolName.includes('(CINA)')) {
        //         if (schoolName.includes('JENIS'))
        //             guid = 'SJKC' + schoolName.split('(CINA)')[1].replace(/\s/g, '');
        //         else
        //             guid = 'SKC' + schoolName.split('(CINA)')[1].replace(/\s/g, '');
        //     }
        //     else if (schoolName.includes('(FELDA)'))
        //         guid = 'SKFELDA' + schoolName.split('(FELDA)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(TAMIL)'))
        //         guid = 'SJKT' + schoolName.split('(TAMIL)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(TAMIL & TELUGU)'))
        //         guid = 'SJKTT' + schoolName.split('(TAMIL & TELUGU)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('SEKOLAH KEBANGSAAN (1)'))
        //         guid = 'SK1' + schoolName.split('SEKOLAH KEBANGSAAN (1)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('SEKOLAH KEBANGSAAN (2)'))
        //         guid = 'SK2' + schoolName.split('SEKOLAH KEBANGSAAN (2)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(A)'))
        //         guid = 'SKA' + schoolName.split('(A)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(AGAMA)'))
        //         guid = 'SKAG' + schoolName.split('(AGAMA)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(ASLI)'))
        //         guid = 'SKASLI' + schoolName.split('(ASLI)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(L)'))
        //         guid = 'SKL' + schoolName.split('(L)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(LKTP)'))
        //         guid = 'SKLKTP' + schoolName.split('(LKTP)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(P)'))
        //         guid = 'SKP' + schoolName.split('(P)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(RTBK)'))
        //         guid = 'SKRTBK' + schoolName.split('(RTBK)')[1].replace(/\s/g, '');

        //     else
        //         guid = 'SK' + schoolName.replace('SEKOLAH KEBANGSAAN', '').replace(/[\s.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
        // }

        // else if (schoolName.includes('MENENGAH') && schoolName.includes('KEBANGSAAN') === false) {
        //     if (schoolName.includes('(ARAB)'))
        //         guid = 'SMAA' + schoolName.split('(ARAB)')[1].replace(/\s/g, '');
        //     else if (schoolName.includes('(') === false && schoolName.includes('AGAMA'))
        //         guid = 'SMAG' + schoolName.split('AGAMA')[1].replace(/\s/g, '');
        //     else
        //         guid = 'SM' + schoolName.replace('SEKOLAH MENENGAH', '').replace(/[\s.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
        // }

        // else if (schoolName.includes('MENENGAH') && schoolName.includes('KEBANGSAAN')) {

        // }

        // else if (schoolName.includes('SEKOLAH KHAS') === false)
        //     guid = 'SKHAS' + schoolName.replace('SEKOLAH KHAS').replace(/\s/g, '');




        // if (schoolName.includes('SEKOLAH MENENGAH KEBANGSAAN')) {
        //     guid = 'SMK' + schoolName.replace('SEKOLAH MENENGAH KEBANGSAAN', '').replace(symbolRegex, '');
        // }
        // else if (schoolName.includes('SEKOLAH JENIS KEBANGSAAN')) {
        //     if (schoolName.includes('(CINA)'))
        //         guid = 'SJKC' + schoolName.replace('SEKOLAH JENIS KEBANGSAAN (CINA)', '').replace(symbolRegex, '');
        //     else if (schoolName.includes('(TAMIL)'))
        //         guid = 'SMK' + schoolName.replace('SEKOLAH JENIS KEBANGSAAN (TAMIL)', '').replace(symbolRegex, '');
        //     if (schoolName.includes('(CINA)'))
        //         guid = 'SMK' + schoolName.replace('SEKOLAH JENIS KEBANGSAAN (CINA)', '').replace(symbolRegex, '');
        //     else
        //         guid = 'SJK' + schoolName.replace('SEKOLAH JENIS KEBANGSAAN', '').replace(symbolRegex, '');
        // }

        //#endregion

        const symbolRegex = /[\s.,#!$%^&*;:{}=\-_`~()]/g;

        let guid = schoolName.replace(symbolRegex, '');
        guid = guid.replace('SEKOLAH', 'S').replace('JENIS', 'J').replace('KEBANGSAAN', 'K').replace('MENENGAH', 'M');
        guid = guid.replace('CINA', 'C').replace('TAMIL', 'T').replace('TAMIL & TELUGU', 'TT').replace('FELDA', 'F');
        // guid = guid.split(' ').map(word => { return word.charAt(0); }).join('');

        if (CheckNullValue(guid) === null)
            guid = schoolName.replace(symbolRegex, '');

        if (this.state.isDevMode)
            console.log('GetGuid = ' + guid);

        return guid;
    }
    //#endregion === Gov Schools ===

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<div className="">
            <table className="table page-header">
                <tbody>
                    <tr>
                        <td className="left"><h5>Organizer</h5></td>
                        <td className="center"></td>
                        <td className="right">
                            {/* <Button
                                variant='outline-primary'
                                onClick={() => this.Toggle_SearchSchoolByParams()}
                            >Search School</Button> */}
                            <Button
                                variant='outline-primary'
                                onClick={() => this.Toggle_EditOrganizerModal(-1, true)}
                            >New Organizer</Button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className='table table-bordered tb-row-hover' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                <thead>
                    <tr>
                        <th style={{ textAlign: 'center', width: 50, }}>#</th>
                        <th style={{ textAlign: 'left', width: 0, }}>Organizer</th>
                        <th style={{ textAlign: 'left', }}>School Name</th>
                        <th style={{ textAlign: 'left', width: 0, }}>Other Info</th>
                        <th style={{ textAlign: 'center', width: 105, }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.isLoading && !this.state.IsListLoaded ?
                            <tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>
                            : this.state.List.length > 0 ?
                                this.ListComponents()
                                : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                    }
                </tbody>
            </table>

            {/* Organizer - Edit / Create - Modal */}
            <Modal show={this.state.EditOrganizerModal_Toggle}
                onHide={() => {
                    if (this.state.EditOrganizer_Processing)
                        DoNothing()
                    else
                        this.Toggle_EditOrganizerModal()
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.EditOrganizerNew ? 'New' : 'Edit'} Organizer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body-buttons">
                        <Button
                            variant='primary'
                            onClick={() => this.Toggle_SearchSchoolByParams()}
                        >Search for School</Button>
                    </div>
                    {this.state.EditOrganizerNew ? null : <span>Id: {CheckObjectStringEmpty(this.state.EditOrganizerObject, 'Id')}</span>}
                    {this.EditOrganizerComponents()}
                    {this.state.EditOrganizerNew || this.state.SelectedSchool !== null ? null : <label>Logo image file
                        <input
                            type="file"
                            onChange={this.onAttachPictureFile}
                            style={{ width: '100%' }}
                            name='imageLogo'
                            accept='image/png,image/jpeg'
                        />
                    </label>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.Toggle_EditOrganizerModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    {
                        this.state.EditOrganizerNew ?
                            <Button variant="primary" onClick={() => this.CreateOrganizer()} disabled={this.state.EditOrganizer_Processing}>Create</Button>
                            :
                            <Button variant="primary" onClick={() => this.UpdateOrganizer()} disabled={this.state.EditOrganizer_Processing}>Update</Button>
                    }
                </Modal.Footer>
            </Modal>

            {/* Gov School - Search School(s) by Params - Modal */}
            <Modal
                show={this.state.SearchSchoolByParamsModal_Toggle}
                onHide={() => {
                    if (this.state.SearchSchoolByParams_Processing)
                        DoNothing();
                    else
                        this.Toggle_SearchSchoolByParams()
                }}
                centered
            >
                <Modal.Header closeButton={this.state.SearchSchoolByParams_Processing === false}>
                    <Modal.Title>{
                        this.state.SearchSchoolByParams_Processing ? 'Searching...' : 'Search School(s)'
                    }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.SearchSchoolByParams_Processing ?
                            <ProgressBar animated now={100} className='progressbar1' />
                            :
                            <table className="table tb-no-border" cellPadding={5} cellSpacing={0} width='100%'>
                                <thead>
                                    <tr><th colSpan={2}><h5>Search By:</h5></th></tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td width="120px">School Code</td>
                                        <td>
                                            <input className='form-control' type="text" style={{ width: '100%' }}
                                                placeholder='(enter school code here)'
                                                onChange={(e) => this.setState({ SearchBySchoolCode: CheckStringEmpty(e.target.value) })}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>School Name</td>
                                        <td>
                                            <input className='form-control' type="text" style={{ width: '100%' }}
                                                placeholder='(enter school name here)'
                                                onChange={(e) => this.setState({ SearchBySchoolName: CheckStringEmpty(e.target.value) })}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Country State</td>
                                        <td>
                                            <input className='form-control' type="text" style={{ width: '100%' }}
                                                placeholder='(enter country state here)'
                                                onChange={(e) => this.setState({ SearchByCountryState: CheckStringEmpty(e.target.value) })}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>District Area</td>
                                        <td>
                                            <input className='form-control' type="text" style={{ width: '100%' }}
                                                placeholder='(enter district area here)'
                                                onChange={(e) => this.setState({ SearchByDistrictArea: CheckStringEmpty(e.target.value) })}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                    }
                </Modal.Body>
                {
                    !this.state.SearchSchoolByParams_Processing ?
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.Toggle_SearchSchoolByParams()}>Cancel</Button>
                            &nbsp;&nbsp;
                            <Button variant="primary" onClick={() => this.SearchGovernmentSchools_ViaAPI()}>Search</Button>
                        </Modal.Footer>
                        : null
                }
            </Modal>

            {/* Gov School - Search School(s) Result - Modal */}
            <Modal show={this.state.SearchSchoolResultModal_Toggle}
                onHide={() => this.Toggle_SeachSchoolResultModal()}
                centered
                size='xl'
            >
                <Modal.Header closeButton>
                    <Modal.Title>School(s)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table cellPadding={10} cellSpacing={10} width='100%'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Country State</th>
                                <th>District Area</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.SchoolList.length === 0 ?
                                    <td colSpan={15} style={{ textAlign: 'center' }}>- no school is found - </td>
                                    :
                                    this.state.SchoolList.map((data, key) => {
                                        return (<tr>
                                            <td>{key + 1}</td>
                                            <td>{CheckObjectStringEmpty(data, 'SchoolCode')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'SchoolName')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'CountryState')}</td>
                                            <td>{CheckObjectStringEmpty(data, 'DistrictArea')}</td>
                                            <td>
                                                {
                                                    this.state.SelectedSchool !== null && this.state.SelectedSchool === data ?
                                                        <Button
                                                            variant='secondary'
                                                            onClick={() => this.setState({
                                                                SelectedSchool: null,
                                                            })}
                                                        >Cancel</Button>
                                                        :
                                                        <Button
                                                            variant='primary'
                                                            onClick={() => this.setState({
                                                                SelectedSchool: data,
                                                            })}
                                                        >Select</Button>
                                                }
                                            </td>
                                        </tr>);
                                    })
                            }
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.Toggle_SeachSchoolResultModal()}>Cancel</Button>
                    &nbsp;&nbsp;
                    <Button variant="primary" onClick={() => this.ConfirmSelectedSchoolResult()}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        </div>);
    }
}