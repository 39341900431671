import { CapitalizeJsonKeys, CheckObjectNullValue, DelayUntil } from '../utilities/GlobalFunctions';
import { GlobalSetting, SecretKey } from '../utilities/GlobalSetting';

//Jay created 2024.01.08

const isDevMode = window.location.href.includes('localhost');

export async function GetAllOrganizers_ViaAPI(authorId) {
    let done = false;
    let success = false;
    let errorMessage = '';
    let _List = [];

    await fetch(GlobalSetting.ApiUrl
        + 'Api/LearningCentre/Organizer/List?secret=' + SecretKey.Admin + '&authorId=' + authorId,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then(data => {
            if (isDevMode)
                console.log(JSON.stringify(data));
            success = data.success;
            if (success) {
                if (CheckObjectNullValue(data, 'data') !== null)
                    if (Array.isArray(data.data) && Array(data.data).length > 0) {
                        _List = CapitalizeJsonKeys(JSON.parse(JSON.stringify(data.data)));
                    }
                    else {
                        if (isDevMode)
                            console.log('Organizer list is empty.');
                    }
            }
            else {
                errorMessage = data.message;
                if (isDevMode)
                    console.log('Error', 'api-organizer-load-list (failed)\n' + JSON.stringify(data));
            }
            done = true;
        })
        .catch(error => {
            if (isDevMode)
                console.log('Error', 'api-organizer-load-list (error)\n' + error.message);
            errorMessage = error.message;
            done = true;
        });
    await DelayUntil(() => done === true);

    return { success, errorMessage, data: _List };
}

export async function UpdateOrganizer_ViaAPI(authorId, remove = false, modal = null) {
    let done = false;
    let success = false;
    let errorMessage = '';
    let _List = [];

    if (modal === null || typeof (modal) !== 'object') {
        errorMessage = 'Invalid object.';
    }
    else {
        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Organizer/Update',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Secret: SecretKey.Admin,
                    AuthorId: authorId,
                    Remove: remove,
                    Modal: modal,
                }),
            })
            .then(res => res.json())
            .then(data => {
                if (isDevMode)
                    console.log(JSON.stringify(data));
                success = data.success;
                if (success) {
                    if (CheckObjectNullValue(data, 'data') !== null)
                        if (Array.isArray(data.data) && Array(data.data).length > 0) {
                            _List = CapitalizeJsonKeys(JSON.parse(JSON.stringify(data.data)));
                        }
                        else {
                            if (isDevMode)
                                console.log('Organizer list is empty.');
                        }
                }
                else {
                    errorMessage = data.message;
                    if (isDevMode)
                        console.log('Error', 'api-organizer-update (failed)\n' + JSON.stringify(data));
                }
                done = true;
            })
            .catch(error => {
                if (isDevMode)
                    console.log('Error', 'api-organizer-update (error)\n' + error.message);
                errorMessage = error.message;
                done = true;
            });
        await DelayUntil(() => done === true);
    }
    return { success, errorMessage, data: _List };
}

export async function CreateOrganizer_ViaAPI(authorId, modal = null) {
    let done = false;
    let success = false;
    let errorMessage = '';
    let _data = [];

    if (modal === null || typeof (modal) !== 'object') {
        errorMessage = 'Invalid object.';
    }
    else {
        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/New',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Secret: SecretKey.Admin,
                    AuthorId: authorId,
                    Modal: modal,
                }),
            })
            .then(res => res.json())
            .then(data => {
                if (isDevMode)
                    console.log(JSON.stringify(data));
                success = data.success;
                if (success) {
                    if (CheckObjectNullValue(data, 'data') !== null)
                        _data = CapitalizeJsonKeys(JSON.parse(JSON.stringify(data.data)));
                }
                else {
                    errorMessage = data.message;
                    if (isDevMode)
                        console.log('Error', 'api-organizer-create (failed)\n' + JSON.stringify(data));
                }
                done = true;
            })
            .catch(error => {
                if (isDevMode)
                    console.log('Error', 'api-organizer-create (error)\n' + error.message);
                errorMessage = error.message;
                done = true;
            });
        await DelayUntil(() => done === true);
    }
    return { success, errorMessage, data: _data };
}

export async function UploadPictureFileForOrganizer_ViaAPI(modal = null) {
    let done = false;
    let success = false;
    let message = '';
    let responseData = null;

    if (modal === null || typeof (modal) !== 'object') {
        message = 'Invalid object.';
    }
    else {
        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/Logo/Upload',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(CapitalizeJsonKeys(modal)),
            })
            .then(res => res.json())
            .then(data => {
                if (isDevMode)
                    console.log(JSON.stringify(data));
                success = data.success;
                if (success) {
                    responseData = data.data;
                }
                else {
                    message = data.message;
                    if (isDevMode)
                        console.log('Error', 'api-organizer-logo-upload (failed)\n' + JSON.stringify(data));
                }
                done = true;
            })
            .catch(error => {
                if (isDevMode)
                    console.log('Error', 'api-organizer-logo-upload (error)\n' + error.message);
                message = error.message;
                done = true;
            });
        await DelayUntil(() => done === true);
    }
    return { success, message, data: responseData };
}