import { CapitalizeJsonKeys, CheckObjectNullValue, CheckObjectStringEmpty } from '../utilities/GlobalFunctions';
import { GlobalSetting } from '../utilities/GlobalSetting';

//Jay created 2024.04.09

const isDevMode = window.location.href.includes('localhost');

export async function SearchGovernmentSchools_ViaAPI(schoolCode = '', schoolName = '', countryState = '', districtArea = '') {

    let success = false;
    let message = '';
    let _List = [];

    await fetch(GlobalSetting.ApiUrl
        + 'Api/LiveQuiz/GovernmentSchool/SearchByParams',
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(CapitalizeJsonKeys({
                schoolCode,
                schoolName,
                countryState,
                stateArea: districtArea,
            })),
        })
        .then(res => res.json())
        .then(data => {
            if (isDevMode)
                console.log(JSON.stringify(data));
            success = data.success;
            if (success) {
                if (CheckObjectNullValue(data, 'data') !== null)
                    if (Array.isArray(data.data)) {
                        _List = CapitalizeJsonKeys(JSON.parse(JSON.stringify(data.data)));
                    }
                    else {
                        if (isDevMode)
                            console.log('list is empty.');
                    }
            }
            else {
                message = CheckObjectStringEmpty(data, 'message');
                if (isDevMode)
                    console.log('Error', 'api-gov-school-list (failed)\n' + JSON.stringify(data));
            }
        })
        .catch(error => {
            if (isDevMode)
                console.log('Error', 'api-gov-school-list (error)\n' + error.message);
            message = error.message;
        });

    return { success, message, list: _List };
}